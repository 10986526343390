import scss from "./ProductGridItem.module.scss";

const Stickers = ({ stickers }) => {
  return (
    <div className={scss.stickers}>
      {stickers.map((sticker) => {
        return (
          <a className={scss.sticker} href={sticker.url} target={sticker.target} key={sticker.slug}>
            {sticker.image ? <img src={sticker.image} alt={sticker.image} /> : sticker.name}
          </a>
        );
      })}
    </div>
  );
};

export default Stickers;
