import scss from "./SidebarList.module.scss"

/**
 * Show a list in the sidebar.
 *
 * @param {JSX.Element} children The children to render in the list.
 *
 * @return {JSX.Element}
 * @constructor
 */
const SidebarList = ({ children }) => (
    <div className={scss.wrapper}>{children}</div>
)

export default SidebarList
