import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import LoadingWrapper from "components/Loading/LoadingWrapper";
import __ from "config/translations/serbian";
import useAPI from "hooks/useAPI";
import Box from "@mui/material/Box";
import PrintIcon from '@mui/icons-material/Print';

import Tooltip from "@mui/material/Tooltip";

import scss from "./ReclamationsDetails.module.scss";
import DeleteDialog from "components/Dialogs/DeleteDielog";
import ProfilePage from "pages/Profile/ProfilePage";
import Button from "components/Buttons/Button/Button";
import { toast } from "react-toastify";
import Table from "../../../../components/Table/Table";
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import PaginationUI from "components/UI/PaginationUI";
// import { useNavigate } from "react-router-dom";

const ReclamationsDetails = () => {
  const api = useAPI();
  const { id } = useParams();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);

  // Load all products in the cart
  const { data: reclamationsItems, isLoading } = useQuery(["reclamationsItems"], () => api.list(`/reclamations/items/${id}`));
  const { data: reclamationsItemsComments, isLoading: isLoadingComments, refetch } = useQuery(["reclamationsItemsComments"], () => api.list(`/reclamations/items/comments/${id}`));
  const [showDialog, setShowDialog] = useState(false);

  let products = (reclamationsItems?.items ?? []).map((item) => ({
    code: item?.product_sku,
    name: item?.product_name,
    id_reclamations: item?.id_reclamations,
    fiscal_clip: item?.fiscal_clip,
    fiscal_clip_date: item?.fiscal_clip_date,
    product_name: item?.product_name,
    id: item?.id,
  }));

  // console.log("reclamationsItems:::", reclamationsItems)

  const handleRowClick = (id) => {
    setSelectedRowId(id);
  };

  const selectedRowComments = reclamationsItemsComments?.items.filter((comment) => comment.id_reclamations === selectedRowId);

  useEffect(() => setCurrentPage(reclamationsItems?.pagination.selected_page ?? 1), [reclamationsItems, page, reclamationsItems?.pagination.selected_page]);


  return (
    <ProfilePage>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <h4 className="h-underline">
          {__.Reclamations.Reclamation}: {id}
        </h4>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button label="Nazad" to="/reklamacije" styleLinkButton={{ margin: "0 0 0 2rem" }} />
        </Box>
      </Box>

      <LoadingWrapper isLoading={isLoading}>
        <div className={scss.wrapper}>
          <div className={scss.itemsList}>
            <h6 className={`${scss.title} underline`}>Pregled reklamiranih proizvoda</h6>
            <div style={{ maxHeight: "100%", overflow: "auto" }}>
              <Table>
                <thead>
                  <tr>
                    <th>Naziv</th>
                    <th>Šifra</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Link
                            to={`/katalog/artikal/${item.id}`}
                            style={{ background: "inherit", color: "inherit", padding: "0", display: "flex", justifyContent: "center", margin: "0", width: "100%" }}>
                            {item.name}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/katalog/artikal/${item.id}`}
                            style={{ background: "inherit", color: "inherit", padding: "0", display: "flex", justifyContent: "center", margin: "0", width: "100%" }}>
                            {item.code}
                          </Link>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <InsertCommentIcon onClick={() => { handleRowClick(item.id); setShowDialog(true) }} />
                        </td>
                      </tr>
                    );
                  })}
                  {products?.length === 0 && (
                    <tr>
                      <td colSpan={3} style={{ paddingTop: "2rem" }}>Trenutno ne postoje podaci za prikaz.</td>
                    </tr>
                  )}

                </tbody>
              </Table>

            </div>
            <PaginationUI
              activePage={currentPage}
              setPage={setPage}
              numberOfDisplayed={reclamationsItems?.pagination?.items_per_page ?? 12}
              numberOfProducts={reclamationsItems?.pagination.total_items ?? 0}
              changePage={() => {
                api.list(`/reclamations/items/${id}`, { limit: limit, page: page }).then((data) => { });
              }}
              page1={page}
            />
          </div>
        </div>
      </LoadingWrapper>

      <DeleteDialog
        openDeleteDialog={{ show: showDialog }}
        setOpenDeleteDialog={() => setShowDialog(false)}
        handleConfirm={() => {
          setShowDialog(false);
        }}
        title={"Lista komentara"}
        description={""}
        nameOfButtonCancel={"Zatvori"}
        showDeleteButton={false}
        deafultIcon={false}
        children={
          <>
            {selectedRowComments?.length === 0 ? (
              <p>Trenutno ne postoji komentar za reklamirani proizvod.</p>
            ) : (
              selectedRowComments?.map((comment, index) => {
                return (
                  <Box key={index}>
                    <Box sx={{ margin: "0.2rem 0", display: "flex", flexDirection: "column", alignItems: "end" }}>
                      <span className={scss.createdAt}>{comment.user_first_name + " " + comment.user_last_name} / {comment.created_at}</span>
                    </Box>
                    <Box className={scss.chatBox}>
                      {comment.comment}
                    </Box>
                  </Box>
                )

              })
            )}

          </>}
      />
    </ProfilePage >
  );
};

export default ReclamationsDetails;
