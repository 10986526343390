import ReactLoading from "react-loading";
import scss from "./Loading.module.scss";
import theme from "../../assets/scss/custom.scss";

/**
 * Unified Loading.
 *
 * @param {"blank"|"balls"|"bars"|"bubbles"|"cubes"|"cylon"|"spin"|"spinningBubbles"|"spokes"} type
 * @param {uint} size The width and height of the loading.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Loading = ({ type = "spin", size = 40, color = theme.primaryColor, className = scss.wrapper }) => (
    <div className={className}>
        <ReactLoading type={type} color={color} height={size} width={size} />
    </div>
);

export default Loading;
