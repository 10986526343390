import { Button } from "@mui/material";
import Buttons from "components/Buttons/Buttons/Buttons";
import { useState } from "react";
import HeaderSearch from "../Header/HeaderSearch/HeaderSearch";
import MainCard from "../MainCard/MainCard";
import SidebarHeader from "./SidebarHeader/SidebarHeader";
import scss from "./WithFixedSidebar.module.scss";

/**
 * Shows content that has fixed left sidebar.
 *
 * @param {JSX.Element} children The main content.
 * @param {?JSX.Element|string} header The header for the sidebar or a string to use standard style.
 * @param {JSX.Element} sidebar The sidebar to render.
 *
 * @return {JSX.Element}
 * @constructor
 */
const WithFixedSidebar = ({ children, header, sidebar, icon }) => {
    // Auto header
    if (typeof header === "string") {
        header = <SidebarHeader title={header} icon={icon} />;
    }

    const [open, setOpen] = useState(false);

    return (
        <MainCard>
            <div className={scss.wrapper}>
                {/* Sidebar */}
                <div
                    className={scss.sidebar}
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    {header}
                    {open && <div className={`${scss.scrollable} desktop-hidden`}>{sidebar}</div>}
                    <div className={`${scss.scrollable} mobile-hidden`}>{sidebar}</div>
                </div>

                {/* Main content */}
                <div className={scss.content}>{children}</div>
            </div>
        </MainCard>
    );
};

export default WithFixedSidebar;
