import styles from "./HeaderSearch.module.scss";
import searchImg from "../../../../assets/images/icons/search-icon.png";
import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import useAPI from "../../../../hooks/useAPI";
import Loading from "react-loading";
import LoadingWrapper from "../../../Loading/LoadingWrapper";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useDebounce from "../../../../hooks/useDebounce";

const HeaderSearch = ({ search = "", setSearch = () => {}, onSubmit }) => {
    const navigate = useNavigate();
    const api = useAPI();
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearch = useDebounce(searchTerm, 700);
    const [searchTag, setSearchTag] = useState("");
    // const focusIndexRef = useRef(-1);
    const [keyDownState, setKeyDownState] = useState({ cursor: 0 });
    const {
        data: searchResults,
        isSuccess,
        ...basic
    } = useQuery(["CataloguePage.SearchResults", debouncedSearch], async () => {
        if (debouncedSearch?.length >= 3) {
            return await api.list(`/products/search/list`, { search: debouncedSearch, limit: 6, search_tag: searchTag }).then((res) => {
                setSearchTag("");
                return res;
            });
        }
    });
    useEffect(() => {
        if (debouncedSearch && debouncedSearch?.length >= 3) {
            // Perform the query again with the updated searchTerm
            basic.refetch();
        }
    }, [debouncedSearch]);

    const searchRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchTerm("");
                setSearch("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchRef]);

    const handleKeyDown = (e) => {
        const { cursor } = keyDownState;
        if (e.key === "ArrowDown" && cursor < searchResults.items.length - 1) {
            e.preventDefault();
            setKeyDownState((prevState) => ({
                cursor: prevState.cursor + 1,
            }));
        } else if (e.key === "ArrowUp" && cursor > 0) {
            e.preventDefault();
            setKeyDownState((prevState) => ({
                cursor: prevState.cursor - 1,
            }));
        } else if (e.key === "Enter" && searchResults.items.length > 0) {
            e.preventDefault();
            const selectedProduct = searchResults.items[cursor];
            navigate(`/katalog/artikal/${selectedProduct?.slug}`);
            setSearchTerm("");
            setSearch("");
            searchResults.items = [];
        }
    };

    useEffect(() => {
        setKeyDownState({ cursor: 0 });
    }, [debouncedSearch]);

    return (
        <form
            className={styles.form}
            onSubmit={(e) => {
                if (searchTerm?.length >= 3) {
                    e.preventDefault();
                    // searchTag sluzi za cuvanje termina po kojima se vrsila pretraga od strane korisnika, zbog problema prosledjivanja search_taga-a na listi okidamo ovde da bi se sacuvala vrednost kada korisnik klikne enter, NIJE DOBRO jer se search lista dva puta okida
                    searchTag?.length > 0 &&
                        api.list(`/products/search/list`, { search: searchTerm, limit: 6, search_tag: searchTag }).then((res) => {
                            setSearchTag("");
                        });
                    navigate(`/katalog/pretraga/` + encodeURIComponent(searchTerm));
                    setSearchTerm("");
                    setSearchTag("");
                    searchResults.items = [];
                } else {
                    e.preventDefault();
                    toast.error("Uneti najmanje 3 karaktera", {
                        position: "top-center",
                        autoClose: 3000,
                    });
                }
            }}
            ref={searchRef}
        >
            <input
                type="text"
                placeholder="Pronađite proizvod..."
                value={searchTerm}
                onChange={(e) => setSearch(e.target.value)}
                onInput={(e) => {
                    setSearchTerm(e.target.value);
                    setSearchTag(e.target.value);
                }}
                onKeyDown={handleKeyDown}
            />
            {searchTerm?.length >= 1 && searchTerm?.length < 3 && (
                <div style={{
                    position: "absolute",
                    top: "6px",
                    right: "80px",
                    color: "red",
                    fontSize: "13px"
                }}>
                    <p>Uneti najmanje 3 karaktera</p>
                </div>
            )}
            <button
                type="submit"
                className={styles.button}
                onClick={() => {
                    searchResults.items = [];
                }}
            >
                <img src={searchImg} alt="search"/>
            </button>
            {searchTerm?.length >= 3 && (
                <div className={styles.results}>
                    <LoadingWrapper isLoading={basic.isLoading}>
                        {searchResults?.items?.map((item, index) => (
                            <div key={item.id} className={`${styles.result} ${keyDownState.cursor === index ? styles.focused : null}`}>
                                <img src={item.images[0]} width={40} height={40} alt={item.name} />
                                <div className={`${styles.info}`}>
                                    <Link
                                        onClick={() => {
                                            setSearchTerm("");
                                            setSearch("");
                                            searchResults.items = [];
                                        }}
                                        className={`text-decoration-none text-black`}
                                        to={`/katalog/artikal/${item?.slug}`}
                                    >
                                        {item?.basic_data?.name}
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {searchResults?.pagination?.total_items > 6 && (
                            <div className={styles.moreresults}>
                                <Link
                                    onClick={() => {
                                        setSearchTerm("");
                                        setSearch("");
                                        searchResults.items = [];
                                    }}
                                    to={`/katalog/pretraga/${searchTerm}`}
                                    className={`text-decoration-none text-white`}
                                >
                                    Prikaži sve rezultate (još {searchResults?.pagination?.total_items - searchResults?.items?.length})
                                </Link>
                            </div>
                        )}
                    </LoadingWrapper>
                </div>
            )}
        </form>
    );
};

export default HeaderSearch;
