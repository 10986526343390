import { useEffect } from "react";
import { useQueryClient } from "react-query";

/**
 * Lists all global query that should be refreshed from time to time.
 */
export const GlobalQueries = {
    Notifications: "GlobalQueries.Notifications",
    Wishlist: "GlobalQueries.Wishlist",
    Orders: "GlobalQueries.Orders",
    Cart: "GlobalQueries.Cart",
    Checkout: "GlobalQueries.Checkout",

    Profile: {
        MyProfile: "GlobalQueries.Profile.MyProfile",
        CompanyProfile: "GlobalQueries.Profile.CompanyProfile",
        CompanyAddress: "GlobalQueries.Profile.CompanyAddress",
        Addresses: "GlobalQueries.Profile.Addresses",
        Address: "GlobalQueries.Profile.Address",
        Reclamations: "GlobalQueries.Profile.Reclamations",
    },
};

/**
 * Automatically refresh a global query when a dependency changes.
 *
 * @param {string} queryKey The query key to refresh, always use one from the GlobalQueries above.
 * @param {*} dependency The dependency that will trigger the refresh.
 */
const useRefreshQuery = (queryKey, dependency) => {
    const queryClient = useQueryClient();
    useEffect(() => {
        if (dependency != null) {
            queryClient.invalidateQueries(queryKey).catch(console.error);
        }
    }, [dependency]);
};

export default useRefreshQuery;
