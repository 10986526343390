import scss from "../AnalitycCard.module.scss";

const AnanlyticBlock = ({ title = "", value = "", icon, background }) => {
  return (
    <div className={`${scss.ananlyticBlock} ${scss[background]}`}>
      <div className={scss.ananlyticBlockIcon}>
        <img src={icon} alt="" />
      </div>
      <div className={scss.ananlyticBlockData}>
        <h6 className={scss.ananlyticBlockTitle}>{title}</h6>
        <p className={scss.ananlyticBlockValue}>{value}</p>
      </div>
    </div>
  );
};

export default AnanlyticBlock;
