import __ from "../../config/translations/serbian"
import scss from "./Empty.module.scss"

/**
 * Show that some data is emty.
 *
 * @param {?string} message The message to show, or null to show the default one.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Empty = ({message = null, search, orders}) => (
    <div className={scss.wrapper}>
        {search?.length !== 0 && orders?.length === 0 ? <>Za traženi kriterijum {search} nema rezultata.</> : (<>
            {message ?? __.Messages.ThereAreNoDataToShow}</>)}

    </div>
)

export default Empty
