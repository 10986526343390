import { useState } from "react"

/**
 * Handle a state for an array, by adding a random value to enforce state update.
 *
 * @param {*[]} initialState The initial state.
 *
 * @return {[{array: *[], tag: null},(function(*): void)]}
 */
const useArray = (initialState: [] = []) => {
    const [state, setState] = useState({ tag: null, array: initialState })

    // The setter
    const set = value => setState(active => {

        // Handle both update direct value and by function
        const array = typeof value === "function"
            ? value(active.array)
            : value

        // Try to do nothing if there are not changes
        return JSON.stringify(active) !== JSON.stringify(array)
            ? { tag: Math.random(), array }
            : active
    })

    return [state, set]
}

export default useArray
