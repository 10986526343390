import { Link } from "react-router-dom";
import scss from "./Subcategories.module.scss";

/**
 * Module that displays categories and subcategories of B2B.
 */

const Subcategories = ({ selectedCategory, onActivateCategory, activateCategory }) => (
  <div
    className={scss.wrapper}
    onClick={(event) => {
      if (event.target === event.currentTarget) {
        activateCategory(null);
      }
    }}
  >
    <div className={scss.popup}>
      <Link className={`text-decoration-none`} to={`/katalog/kategorija/${selectedCategory?.slug}`}>{selectedCategory.name}</Link>

      {/* Second level */}
      <div className={scss.level2}>
        {selectedCategory.children.map((level2, index) => (
          <div key={level2.slug} className={`d-flex flex-column align-items-start`}>
            <Link className={`text-decoration-none ${scss.firstbold}`} to={`/katalog/kategorija/${level2?.slug}`}>
              {level2.name}
            </Link>

            {(level2.children ?? []).map((level3, index) => (
              <Link className={`text-decoration-none `} to={`/katalog/kategorija/${level3?.slug}`} key={level3.slug}>
                {level3.name}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Subcategories;
