import CartItem from "./CartItem";

const MobileCart = ({ items, cost, showImage = true }) => {
  return (
    <div>
      {items.map((item) => (
        <CartItem key={item.product.id} item={item} showImage={showImage} />
      ))}
    </div>
  );
};

export default MobileCart;
