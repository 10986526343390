const Sitemap = {
    any: "*",
    startPage: "/",
    home: "/pocetna",
    auth: {
        login: "/prijava",
        register: "/b2b-zahtev",
        passwordReset: "/reset-lozinke/:token",
    },
    catalogue: {
        _: "/katalog",
        category: "/katalog/kategorija",
        search: "/katalog/pretraga",
        productDetails: "/katalog/artikal",
    },
    orders: {
        _: "/kupovine",
        details: "/kupovine/:id",
    },
    contact: {
        _: "/kontakt",
        chat: "/kontakt/chat",
    },
    profile: {
        _: "/nalog",
        company_profile: "/nalog/kompanija",
        addresses: "/nalog/adrese",
        address: "/nalog/adrese/:id",
        debt: "/nalog/debt",
        reclamations: "/reklamacije",
        reclamationsDetails: "/reklamacije/:id",
        reclamationsForm: "/reklamacije/new",
        staticPage: "/stranica",
    },
    notifications: {
        _: "/notifikacije",
    },
    wishlist: {
        _: "/lista-zelja",
        empty: "/nema-zelja",
    },
    cart: {
        _: "/korpa",
        checkout: "/korpa/potvrda",
        success: "/korpa/uspesno",
    },
    static: {
        manual: "/uputstvo",
        analyticCard: "/analiticka-kartica",
        reportProblem: "/prijavite-problem",
        agreement: "/ugovor-o-saradnji",
        pages: "/strane",
    },
    new: {
        list: "/novo",
        search: "/novo/pretraga",
        productDetails: "/novo/artikal",
    },
    action: {
        list: "/akcija",
        search: "/akcija/pretraga",
        productDetails: "/akcija/artikal",
    },
    promo: {
        _: "/promo",
        details: "/promo/:id",
    },
};

export default Sitemap;
