import { Box } from "@mui/system";
import { useState } from "react";
import NotificationBody from "./NotificationBody";

import styles from "./NotificationsModal.module.scss";

const NotificationHolder = ({ notification: { id, title, seen_date, created_at } }) => {
    const [open, setOpen] = useState(false);

    return (
        <Box className={styles.notification}>
            <Box className={`${styles.notificationTitle} ${seen_date == null && styles.notificationTitleNew}`} onClick={() => setOpen(!open)}>
                {title} {seen_date == null && <Box className={styles.new}>New</Box>}
            </Box>
            {open && <NotificationBody id={id} />}
        </Box>
    );
};

export default NotificationHolder;
