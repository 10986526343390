import Loading from "../Loading/Loading";
import theme from "../../assets/scss/custom.scss";

/**
 * Show loading animation until somehting is completed.
 *
 * @param {bool|{isLoading: bool}} isLoading True to show loading, false otherwise.
 * @param {"blank"|"balls"|"bars"|"bubbles"|"cubes"|"cylon"|"spin"|"spinningBubbles"|"spokes"} type The loading animation type.
 * @param {uint} size The width and height of the loading.
 * @param {JSX.Element} children The children to render when loading is completed.

 * @return {JSX.Element|*}
 * @constructor
 */
const LoadingWrapper = ({ isLoading, type, size, children, color = theme.primaryColor, className }) => {
    return isLoading ? <Loading type={type} size={size} color={color} className={className} /> : children;
};

export default LoadingWrapper;
