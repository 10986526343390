import { useState } from "react";
import { Link } from "react-router-dom";

import PlusMinusInput from "components/Form/PlusMinusInput/PlusMinusInput";
import { currencyFormat } from "helpers/functions";
import useAPI from "hooks/useAPI";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import scss from "./MobileCart.module.scss";

const CartItem = ({ item, showImage = true }) => {
  const api = useAPI();
  const [openTooltip, setOpenTooltip] = useState(false);

  // Track the quantity to add to the cart
  const setQuantity = (quantity) => {
    api.globalAddToCart(item?.product?.id, quantity);
  };

  const rabateCalc = (item) => {
    let rabate = [];
    item?.product?.price?.per_item?.rebate?.active && item?.product?.price?.per_item?.rebate?.rebates?.map((rebate) => {
      rabate.push(rebate?.calc?.calc_name);
      return null
    });
    return rabate.join("; ");
  };

  return (
    <div className={scss.product}>
      {/*{showImage && <div className={scss.productImage}>{item.product.image ? <img src={item.product.image} alt="" /> : "-"}</div>}*/}
      <div className={scss.productData}>
        <div className={`d-flex justify-content-between ${scss.productSku}`}>
          <Link to={`/katalog/artikal/${item?.product?.id}`} className={scss.productName}>
            {item?.product?.basic_data?.name}
            {item?.product?.basic_data?.attributes_text && (
              <>
                <br />
                <span className={scss.productSku}>{item?.product?.basic_data?.attributes_text}</span>
              </>
            )}
          </Link>
          <div className={scss.productRemove}>
            <i className="fa fa-trash" onClick={() => api.globalAddToCart(item?.product?.id, 0)} />
          </div>
        </div>
        <div className={`d-flex mt-4 justify-content-between ${scss.productSku}`}>
          <span className={``}>Šifra:</span>
          <span className={``}>{item?.product?.basic_data?.sku}</span>
        </div>
        <div className={`d-flex justify-content-between ${scss.productSku}`}>
          <span className={``}>Osnovna cena:</span>
          <span className={``}>{currencyFormat(item?.product?.price?.per_item?.price_with_out_vat, item?.product?.price?.currency)}</span>
        </div>
        <div className={`d-flex justify-content-between ${scss.productSku}`}>
          <span className={``}>Rabat:</span>
          <span>
            {item?.product?.price?.per_item?.rebate?.active ? rabateCalc(item) : "/"}
          </span>
        </div>
        <div className={`d-flex justify-content-between ${scss.productSku}`}>
          <span className={``}>Iznos popusta:</span>
          <span>
            {item?.product?.price?.per_item?.discount?.active ? '-' + currencyFormat(item?.product?.price?.per_item?.discount?.amount, item?.product?.price?.currency) : "/"}
          </span>
        </div>
        <div className={`d-flex justify-content-between ${scss.productSku}`}>
          <span className={``}>Cena bez PDV-a:</span>
          <Tooltip
            onClick={() => setOpenTooltip(true)}
            open={openTooltip}
            onClose={() => setOpenTooltip(false)}
            placement={"top"}
            arrow={true}
            title={
              <Box>
                <Box>
                  Regularna cena: <span>{currencyFormat(item?.product?.price?.per_item?.price_with_out_vat)}</span>
                </Box>
                {item?.product?.price?.per_item?.rebate?.active &&
                  item?.product?.price?.per_item?.rebate?.rebates?.map((rebate) => {
                    return (
                      <Box key={item?.product?.id}>
                        Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                      </Box>
                    );
                  })}
                {item?.product?.price?.per_item?.discount?.active &&
                  item?.product?.price?.per_item?.discount?.campaigns?.map((campaign) => {
                    return (
                      <Box key={item?.product?.id}>
                        Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                      </Box>
                    );
                  })}
              </Box>
            }
          >
            <span className={`${scss.tcPrice} ${item?.product?.price?.per_item?.discount?.active ? scss.discounted : ''} ${item?.product?.price?.per_item?.rebate?.active ? scss.rebateStyle : ''}  ${item?.product?.price?.per_item?.discount?.active && item?.product?.price?.per_item?.rebate?.active ? scss.discountRabate : ''}`}>
              {currencyFormat(item?.product?.price?.per_item?.price_subtotal, item?.product?.price?.currency)}
            </span>
          </Tooltip>
        </div>
        <div className={scss.productDataPrice}>
          <div className={`d-flex w-100 justify-content-between ${scss.productSku}`}>
            <span>Količina:</span>
            <div className={`w-50`}>
              <PlusMinusInput quantity={item?.cart?.quantity} setQuantity={setQuantity} debounceId={item?.product?.id} className={scss.productQuantity} />
            </div>
          </div>
          <div className={`d-flex mt-2 w-100 justify-content-between ${scss.productSku}`}>
            <p className={`bold`}>Ukupno bez PDV-a:</p> <p className={`bold`}>{currencyFormat(item?.product?.price?.cost?.subtotal, item?.product?.price?.currency)}</p>
          </div>
          <span className={scss.productPrice}></span>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
