import scss from "./GrayBox.module.scss"

const GrayBox = ({ children }) => {
    return (
        <div className={scss.wrapper}>
            {children}
        </div>
    )
}

export default GrayBox
