import { useLightbox } from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { useEffect } from "react";

const LightBox = ({ openId, gallery, closedLightbox }) => {
    const { openLightbox } = useLightbox();

    const options = {
        caption: {
            showCaption: false,
        },
        thumbnails: {
            thumbnailsAlignment: "center",
        },
    };

    useEffect(() => {
        if (openId !== null && openId >= 0) {
            openLightbox(openId);
        }
    }, [openId]);

    const lightboxCallbacks = {
        onLightboxClosed: (object) => closedLightbox(undefined),
    };

    return (
        <div style={{ display: "none" }}>
            <SRLWrapper options={options} callbacks={lightboxCallbacks}>
                {gallery &&
                    gallery.map(function ({ image }, index) {
                        return (
                            <a href={image} key={index}>
                                <img src={image} alt="lightbox" />
                            </a>
                        );
                    })}
            </SRLWrapper>
        </div>
    );
};

export default LightBox;
