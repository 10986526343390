import { join } from "../../../helpers/functions";
import scss from "./MainCard.module.scss";

/**
 * Container for content to sit around left aside bar and the header.
 *
 * @param {?string} className The additional class name(s) to append.
 * @param {JSX.Element} children The children to render within.
 *
 * @return {JSX.Element}
 * @constructor
 */
const MainCard = ({ className, children }) => {
    return <div className={join([scss.wrapper, className])}>{children}</div>;
};

export default MainCard;
