import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import Button from "../../../components/Buttons/Button/Button";
import { join } from "../../../helpers/functions";
import useAPI from "../../../hooks/useAPI";
import scss from "./Banners.module.scss";

/**
 * Banner components, two horizontally placed banners
 */
const Banners = () => {
  const api = useAPI();

  // Load primary banners
  const { data: primary } = useQuery(["Banners.primary"], () => api.list(`/banners/primary`));
  const primaryBanners = primary?.items ?? [];

  // Load secondary banners
  const { data: secondary } = useQuery(["Banners.secondary"], () => api.list(`/banners/secondary`));
  const secondaryBanners = secondary?.items ?? [];

  // Load secondary banners
  const { data: mobile } = useQuery(["Banners.mobile"], () => api.list(`/banners/mobile_slider`));
  const mobileBanners = mobile?.items ?? [];

  // Holds which banners are currently displayed
  const [primaryDisplayed, setPrimaryDisplayed] = useState(0);
  const [secondaryDisplayed, setSecondaryDisplayed] = useState(0);
  const [mobileDisplayed, setMobileDisplayed] = useState(0);

  // The default duration of a banner
  const defaultPrimaryDuration = 6000;
  const defaultSecondaryDuration = 6000;

  // Cycle primary banners
  useEffect(() => {
    // Skip if there are no more than one banner
    if (primaryBanners.length < 2) {
      return;
    }

    // Get the next banner, then loop around
    const next = (primaryDisplayed + 1) % primaryBanners.length;

    // Trigger the next change
    const duration = primaryBanners[next].duration > 0 ? primaryBanners[next].duration * 1000 : defaultPrimaryDuration;
    const timeout = setTimeout(() => setPrimaryDisplayed(next), duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [primaryBanners, primaryDisplayed]);

  // Cycle secondary banners
  useEffect(() => {
    // Skip if there are no more than one banner
    if (secondaryBanners.length < 2) {
      return;
    }

    // Get the next banner, then loop around
    const next = (secondaryDisplayed + 1) % secondaryBanners.length;

    // Trigger the next change
    const duration = secondaryBanners[next].duration > 0 ? secondaryBanners[next].duration * 1000 : defaultSecondaryDuration;
    const timeout = setTimeout(() => setSecondaryDisplayed(next), duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [secondaryBanners, secondaryDisplayed]);

  // Cycle secondary banners
  useEffect(() => {
    // Skip if there are no more than one banner
    if (mobileBanners.length < 2) {
      return;
    }

    // Get the next banner, then loop around
    const next = (mobileDisplayed + 1) % mobileBanners.length;

    // Trigger the next change
    const duration = mobileBanners[next].duration > 0 ? mobileBanners[next].duration * 1000 : defaultSecondaryDuration;
    const timeout = setTimeout(() => setMobileDisplayed(next), duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [mobileBanners, mobileDisplayed]);

  // There are no banners to show
  if (primaryBanners?.length + secondaryBanners?.length === 0) {
    return null;
  }

  // Get the class
  const className = (banner, index) => join([scss[index === primaryDisplayed ? "banner-active" : "banner"], banner.url && "clickable"]);

  // Handle clicks
  const onClick = (banner) => {
    if (banner.url) {
      // Open in new tab
      if (banner.target === "blank") {
        window.open(banner.url, "_blank");
        return;
      }

      // Han
      window.open(banner.url, "_self");
    }
  };

  return (
    <>
      <div className={`${scss.wrapper} mobile-hidden`}>
        {/* Primary */}
        <div className={scss.primary}>
          {primaryBanners.map((banner, index) => (
            <div key={banner.id} className={className(banner, index)} onClick={() => onClick(banner)}>
              {/* Render the image */}
              <img className={scss.image} src={banner.image} alt="" />

              {/* Texts and actions */}
              {(banner.title || banner.subtitle || banner.text || banner.button) && (
                <div className={scss.texts}>
                  {/* Title and subtitle */}
                  {banner.title && <h3>{banner.title}</h3>}
                  {banner.subtitle && <h4>{banner.subtitle}</h4>}

                  {/* Text */}
                  {banner.text && <p>{banner.text}</p>}

                  {/* Optional button */}
                  {banner.button && <Button label={banner.button} className="mt-3" onClick={() => onClick(banner)} />}
                </div>
              )}
            </div>
          ))}
          {primaryBanners.length > 1 && (
            <div className={scss.dots}>
              {primaryBanners.map((banner, index) => {
                return (
                  <span
                    key={banner.id}
                    className={`${scss.dot} ${index === primaryDisplayed && scss.dotActive} clickable`}
                    onClick={() => {
                      setPrimaryDisplayed(index);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>

        {/* Secondary */}
        <div className={scss.secondary}>
          {secondaryBanners.map((banner, index) => (
            <div key={banner.id} className={`${scss[index === secondaryDisplayed ? "banner-active" : "banner"]} ${banner.url && "clickable"}`} onClick={() => onClick(banner)}>
              {/* Render the image */}
              <img className={scss.image} src={banner.image} alt="" />

              {/* Texts and actions */}
              {(banner.title || banner.subtitle || banner.text || banner.button) && (
                <div className={scss.texts}>
                  {/* Title and subtitle */}
                  {banner.title && <h3>{banner.title}</h3>}
                  {banner.subtitle && <h4>{banner.subtitle}</h4>}

                  {/* Text */}
                  {banner.text && <p>{banner.text}</p>}

                  {/* Optional button */}
                  {banner.button && (
                    <a className={scss.button} target={banner.target} href={banner.url}>
                      {banner.button}
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
          {secondaryBanners.length > 1 && (
            <div className={scss.dots}>
              {secondaryBanners.map((banner, index) => {
                return (
                  <span
                    key={banner.id}
                    className={`${scss.dot} ${index === secondaryDisplayed && scss.dotActive} clickable`}
                    onClick={() => {
                      setSecondaryDisplayed(index);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className={`${scss.mobileWrapper} desktop-hidden`}>
        <div className={scss.mobile}>
          {mobileBanners.map((banner, index) => (
            <div key={banner.id} className={scss[index === mobileDisplayed ? "banner-active" : "banner"]}>
              {/* Render the image */}
              <img className={scss.image} src={banner.image} alt="" />
            </div>
          ))}
          {mobileBanners.length > 1 && (
            <div className={scss.dots}>
              {mobileBanners.map((banner, index) => {
                return (
                  <span
                    key={banner.id}
                    className={`${scss.dot} ${index === mobileDisplayed && scss.dotActive} clickable`}
                    onClick={() => {
                      setMobileDisplayed(index);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Banners;
