import { addToCartService, addToWishListService, getCustomerDataService } from "helpers/services"
import { initStore } from "./store"

const customerStore = () => {
    const actions = {
        CART: async (curState, request) => {
            // const data = await getCustomerDataService(request)
            const data = null
            if (data) {
                data.addedInCart = false
            }
            return { customer: data }
        },
        CART_ADD: async (curState, requestData) => {
            // const data = await addToCartService(requestData.requestParam, requestData.request)
            const data = null
            let updatedCustomer = { ...curState.customer }
            updatedCustomer.shopping_cart_data = data
            updatedCustomer.addedInCart = true
            return { customer: updatedCustomer }
        },
        WISH_ADD: async (curState, requestData) => {
            // const data = await addToWishListService(requestData.requestParam, requestData.request)
            const data = null
            let updatedCustomer = { ...curState.customer }
            updatedCustomer.wish_list_data = data
            updatedCustomer.addedInCart = false
            return { customer: updatedCustomer }
        }
    }

    initStore(actions, {
        customer: {}
    })
};

export default customerStore;
