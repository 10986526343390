const __ = {
    Login: {
        UsernameLabel: "Korisničko ime",
        PasswordLabel: "Lozinka",
        LoginButton: "Prijavite se",
        Logout: "Odjava sa B2B sistema",
        Errors: {
            Email: "Molimo unesite email",
            Password: "Molimo unesite lozinku",
            Form: "Molimo proverive unos",
            TryAgain: "Podaci nisu ispravni, molimo pokušajte ponovo",
        },
    },
    Catalogue: {
        MenuTitle: "Katalog",
        Search: "Pretraga",
        Controls: {
            ByPriceAscending: "Po ceni, rastuće",
            ByPriceDescending: "Po ceni, opadajuće",
            Visible: "Vidljivo",
        },
        Category: {
            Category: "Kategorija",
            Other: "Ostalo",
            ProductCategories: "Kategorije proizvoda",
        },
        ThereAreNoProductsToShow: "Nema proizvoda za prikaz",
    },
    Company: {
        MenuTitle: "Vaš profil",
        Finances: {
            Debt: "Potraživanje",
            DebtInside: "Potraživanje u valuti",
            DebtOutside: "Potraživanje van valute",
        },
    },
    // FIXME Using hardcoded values
    Services: {
        CallOurRepresentatives: "Pozovite naše prodajne savetnike",
        FreeDeliveryForOver: "Besplatna dostava za iznos preko 5000 dinara",
        PostponedPayment: "Odloženo plaćanje na 24 rate bez kamate",
    },
    Product: {
        Column: {
            VAT: "PDV",
            SKU: "SKU",
            Code: "Šifra",
            Barcode: "Barkod",
            Status: "Status",
            Image: "Slika",
            Name: "Naziv",
            Price: "Cena",
            Lager: "Lager",
        },
        Available: {
            Yes: "Dostupno",
            No: "Nema na lageru",
        },
        Sections: {
            Description: {
                Title: "Opis",
                Empty: "Za ovaj proizvod nije dostupan detaljniji opis.",
            },
            Specification: {
                Title: "Specifikacija",
                Empty: "Za ovaj proizvod nije dostupna specifikacija.",
            },
        },
    },
    Cart: {
        MenuTitle: "Vaša korpa",
        Cart: "Korpa",
        AddToCart: "Dodaj u korpu",
        FinalizeThePurchase: "Završi kupovinu",
        Close: "Zatvori",
        Continue: "Nastavi kupovinu",
        Column: {
            Quantity: "Količina",
            Total: "Ukupno",
        },
        Messages: {
            PleaseAddValidItemCount: "Molimo odaberite količinu",
            ProductHasBeenUpdatedInCart: "Nova količina za artikal je zapamćena",
            ProductHasBeenAddedToCart: "Artikal je dodat u korpu",
            ProductHasBeenDeletedFromCart: "Artikal je uklonjen iz korpe",
        },
    },
    Checkout: {
        Checkout: "Potvdra kupovine",
        Actions: {
            BackToCart: "Nazad na korpu",
            ConfirmThePurchase: "Završi kupovinu",
        },
        Sections: {
            Cart: {
                Title: "Pregled poručenih proizvoda",
            },
            PriceBreakdown: {
                BaseAmount: "Ukupan iznos",
                RebateAmount: "Iznos rabata",
                DiscountAmount: "Iznos popusta",
                SubTotal: "Ukupna osnovica",
                VATAmount: "Iznos PDV-a",
                ShippingAmount: "Iznos dostave",
                Total: "Ukupno za uplatu",
                Title: "Ukupno za uplatu",
                Amount: "Iznos bez obračunatog rabata",
                Rebate: "Rabat",
                Discount: "Popust",
            },
            CustomerInfo: {
                Title: "Podaci o naručiocu robe",
                InfoTakenFromProfile: "Podaci za fakturu su preuzeti sa profila firme",
                Edit: {
                    ToModifyYourInfoEditYour1: " U slučaju da želite izmenu podataka, podatke ažurirajte na svom",
                    ToModifyYourInfoEditYour2: "korisničkom profilu",
                },
            },
            Shipment: {
                Title: "Adresa za dostavu poručenih artikala",
                InfoTakenFromProfile: "Podaci za fakturu preuzeti sa profila firme",
                ChosenAddress: "Adresa dostave poručenih proizvoda",
                Options: {
                    SameAsInvoiceAddress: "Prepiši podatke o dostavi (adresa za dostavu je ista kao i adresa firme koja poručuje robu)",
                    AnotherAddress: "Želim drugu adresu za dostavu poručenih proizvoda",
                },
            },
            Note: {
                Title: "Napomena",
                Placeholder: "",
            },
        },
        Messages: {
            CheckoutCompletedSuccessfully: "Vaša porudžbina je poslata",
            PleaseSelectBillingAddress: "Molimo odaberite adresu na koju ćemo poslati fakturu",
            PleaseSelectShippingAddress: "Molimo odaberite adresu za dostavu",
            AcceptTerms: "Morate prihvatiti uslove kupovine kako bi završili porudžbinu",
        },
    },
    Orders: {
        MenuTitle: "Vaše prethodne kupovine",
        Cart: "Korpa",
        AddToCart: "Dodaj u korpu",
        FinalizeThePurchase: "Završi kupovinu",
        Order: "Porudžbina broj",
        Column: {
            OrderNumber: "Broj",
            Created: "Vreme kreiranja",
            ArticleCount: "Ukupno proizvoda",
            TotalValue: "Ukupna vrednost",
            Status: "Status",
        },
        Messages: {
            ThereAreNoOrdersToShow: "Do sada niste obavili nijednu kupovinu",
        },
    },
    Reclamations: {
        MenuTitle: "Vaše reklamacije",
        Reclamation: "Reklamacija broj",
        Messages: {
            ThereAreNoReclamationToShow: "Do sada niste podneli nijednu reklamaciju",
        },
    },
    Wishlist: {
        MenuTitle: "Vaša lista želja",
        Wishlist: "Lista želja",
        AddToWishlist: "Dodaj u listu želja",
        Column: {
            Quantity: "Količina",
            Total: "Ukupno",
        },
        Messages: {
            ProductHasBeenAddedToWishlist: "Artikal je dodat u listu želja",
            ProductHasBeenRemovedFromWishlist: "Artikal je uklonjen iz liste želja",
        },
    },
    Notifications: {
        MenuTitle: "Vaše poruke",
        Notification: "Poruka",
        Notifications: "Notifikacije",
        Messages: {
            ThereAreNoMessages: "Nema poruka za prikaz",
            ThisIsANewMessage: "Ovo je nova poruka",
            MarkAllMessagesAsRead: "Označi sve poruke kao pročitane",
            AllMessagesMarkedAsRead: "Sve poruke su označene kao pročitane",
            ClickOnANotificationOnTheLeftToShowFullMessage: "Odaberite notifikaciju sa leve strane kako bi pročitali celu poruku",
        },
    },
    Contact: {
        MenuTitle: "Kontakt",
        Mail: "Pošaljite email",
        Chat: "Chat sa komercijalistom",
        Messages: {
            PleaseInertTitleAndMessage: "Molimo unesite naslov i poruku",
            MessageHasBeenSent: "Vaša poruka je uspešno poslata",
        },
    },
    Profile: {
        MenuTitle: "Moj nalog",
        SubPages: {
            MyProfile: {
                Title: "Moj profil",
                FirstName: "Ime",
                LastName: "Prezime",
                Email: "Email",
                Phone: "Telefon",
                UpdateMyProfile: "Sačuvaj izmene profila",
            },
            CompanyProfile: {
                Title: "Profil firme",
                Name: "Naziv kompanije",
                PIB: "PIB",
                MB: "Matični broj",
                Address: "Adresa",
                ZipCode: "ZIP kod",
                Town: "Grad",
                Country: "Država",
                UpdateCompany: "Sačuvaj izmene firme",
            },
            Addresses: {
                Title: "Dostavne adrese",
                Name: "Naziv adrese",
                Address: "Adresa",
                ZipCode: "ZIP kod",
                Town: "Grad",
                Country: "Država",
                UpdateAddress: "Sačuvaj adresu",
                Messages: {
                    AddressSavedSuccessfully: "Adresa je uspešno sačuvana",
                },
            },
            Debt: {
                Title: "Podaci o valuti i limitu",
            },
        },
        Messages: {
            ThereAreNoMessages: "Nema poruka za prikaz",
            ThisIsANewMessage: "Ovo je nova poruka",
            MarkAllMessagesAsRead: "Označi sve poruke kao pročitane",
            AllMessagesMarkedAsRead: "Sve poruke su označene kao pročitane",
            ClickOnANotificationOnTheLeftToShowFullMessage: "Odaberite notifikaciju sa leve strane kako bi pročitali celu poruku",
        },
    },
    EmptyPage: {
        DefaultMessage: "Tražena strana više nije dostupna",
        Templates: {
            Cart: "Trenutno ne postoji sadržaj u Vašoj korpi.",
            Wishlist: "Trenutno ne postoji sadržaj u Vašoj listi želja.",
        },
        PromotedProducts: {
            Title: "Možda će Vas zanimati i sledeći proizvodi",
        },
        Assistance: {
            Title: "Pomoć pri kupovini",
            Line1: "Ukoliko Vam je potrebna pomoć u svakom trenutku nas možete kontaktirati pozivom na broj call centra 0700 / 330 - 330.",
            Line2: {
                TakeALookAt: "Pogledajte",
                Manual: "uputstvo za kupovinu",
            },
        },
    },
    Messages: {
        UnknownError: "Došlo je do nepoznate greške",
        ThereAreNoDataToShow: "Nema podataka za prikaz",
        GoBackToHome: "Vrati se na početnu stranu",
    },
    AnalyticCard: {
        Title: "Finansijska kartica",
    },
    Pages: {
        Title: "Strane",
    },
};

export default __;
