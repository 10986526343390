import { DefaultProductsControls } from "../pages/Catalogue/Products/Controls/Controls"

class UserPreferences {
    KEY: "UserPreferences::KEY"

    /** @return {{}} The default values. */
    static __default(): {} {
        return {
            presentation: DefaultProductsControls
        }
    }

    /** @return {string[]} The path divided into an array of steps, in reversed order. */
    static __split(path: string): string[] {
        return path.replace(/^\.+|\.\.+|\.+$/g, "").split(".").reverse()
    }

    /** @return {{}} The most recent data from the local storage. */
    static __read(): {} {
        const storage = JSON.parse(window.localStorage.getItem("UserPreferences::KEY")) ?? {}
        return {
            presentation: {
                ...UserPreferences.__default().presentation,
                ...(storage?.presentation ?? {})
            }
        }
    }

    /**
     * Get a value from the local user preferences.
     * @param {string}  path The path from which to get the current value.
     * @return {*} The found data.
     */
    static get(path: string): * {
        const steps = UserPreferences.__split(path)
        let value = { ...UserPreferences.__read() }

        // Extract from the path
        while (steps.length > 0) {
            const step = steps.pop()
            value = value?.hasOwnProperty(step)
                ? value[step]
                : null
        }

        return value
    }

    /**
     * Get a value from the local user preferences.
     * @param {string} path The path to write the new value
     * @param {*} value The value to store.
     */
    static set(path: string, value: *): void {
        const storage = { ...UserPreferences.__read() }
        const steps = UserPreferences.__split(path)

        // Find the place to write
        let pointer = storage
        while (steps.length > 1) {
            const step = steps.pop()
            pointer = pointer && pointer[step]
        }

        // Write
        if (pointer) {
            pointer[steps[0]] = value
        }

        window.localStorage.setItem("UserPreferences::KEY", JSON.stringify(storage))
        return value;
    }
}

export default UserPreferences
