import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import croonusLogo from "../../assets/images/logo/croonus-logo-menu-white.png";
import useAPI from "../../hooks/useAPI";
import CreateForm from "components/Form/CreateForm/CreateForm";
import Loading from "components/Loading/Loading";

import formFields from "./formFields.json";
import scss from "./ForgotPasswordPage.module.scss";

/**
 * The login page for the B2B.
 *
 * @return {JSX.Element}
 * @constructor
 */

const ForgotPasswordPage = () => {
  const api = useAPI();

  const { token } = useParams();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const [passwordChanged, setPasswordChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  /** Handle form submitting. */
  const submitHandler = () => {
    setIsLoading(true);
    api.post("customer/forgot-password-reset", { ...data, token: token })
      .then((response) => {
        setIsLoading(false);
        setPasswordChanged(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn(error);
      });
  };

  useEffect(() => {
    if (data.password !== data.repeat_password) {
      setError("Lozinke se ne poklapaju.");
    } else {
      setError(null);
    }
  }, [data]);

  // The title, subtitle and the description
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    api.get("/configuration/presentation/login/title/value", false).then((response) => setTitle(response));
    api.get("/configuration/presentation/login/subtitle/value", false).then((response) => setSubtitle(response));
    api.get("/configuration/presentation/login/description/value", false).then((response) => setDescription(response));
  }, [api]);

  return (
    <>
      <div className={scss["login-container"]}>
        <div className={scss["login-card"]}>
          <div className={scss["background-wrapper"]}>
            <img src={api.buildPath("/configuration/images/presentation/login/image")} alt="" />
            <div />
          </div>
          <div className={scss["login-card-gray-overlay"]}>
            {/* Background image */}
            <img src={api.buildPath("/configuration/images/presentation/login/logo")} className={scss["logo"]} alt="logo" />

            {/* Title, subtitle and description */}
            <h5 className={scss["login-sub-title"]}>{subtitle}</h5>
            <h1 className={scss["company-title"]}>
              {title}
              {/*<span className={scss["big-dot"]}>.</span>*/}
            </h1>
            <h5 className={scss["login-sub-title"]}>{description}</h5>
            <div className={" col-xxl-4 col-lg-6 login-form-style"}>
              {!isLoading ? (
                <>
                  {!passwordChanged ? (
                    <CreateForm
                      data={data}
                      setData={setData}
                      formButtons={true}
                      formFields={formFields}
                      error={error}
                      className={scss["form"]}
                      submit={{ action: submitHandler, label: "Promeni lozinku" }}
                    />
                  ) : (
                    <div className={scss.message}>
                      <p>
                        Lozinka je uspešno promenjena. <a href="/prijava">Ulogujte se</a>
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <Loading size={50} />
              )}
            </div>

            {/* Copyright */}
            <div className={scss["powered"] + " col-xxl-4 col-lg-6"}>
              <p className={scss["paragraph"]}>Powered by: </p>
              <a href="https://croonus.com/" target="_blank" rel="noopener noreferrer">
                <img src={croonusLogo} alt="Croonus logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
