import { useEffect, useRef, useState } from "react";
import { debounce } from "../../../helpers/functions";
import { preventDefault } from "../FormWrapper";
import scss from "./PlusMinusInput.module.scss";
import { toast } from "react-toastify";

/**
 * Number input with styles plus and minus buttons.
 */
const PlusMinusInput = ({ quantity, max, setQuantity, debounceId = null, className, setIsPlusMinusInputEmpty = () => {}, noMargin }) => {
    const intQuantity = Math.floor(quantity);
    const [localValue, setLocalValue] = useState(intQuantity);
    const handleBackspace = (event) => {
        if (event.key === "Backspace") {
            setLocalValue("");
            setIsPlusMinusInputEmpty(true);
        }
    };

    const [config, setConfig] = useState();
    useEffect(() => {
        if (typeof document !== "undefined") {
            const inventory_config = localStorage.getItem("configuration_inventories");
            if (inventory_config) {
                let temp = JSON.parse(inventory_config);
                temp = temp?.filter((item) => item?.slug === "check_requested_inventory_product_quantity");
                if (temp?.length > 0) {
                    setConfig(Boolean(Number(temp[0]?.value)));
                }
            }
        }
    }, []);
    useEffect(() => {
        if (quantity > max && config) {
            setQuantity(max);
            setLocalValue(max);
            toast.error("Nema dovoljno proizvoda na stanju!");
        } else {
            setLocalValue(intQuantity);
        }
    }, [quantity]);
    // If the input is not allowed to debounce always synchronize the values
    useEffect(() => {
        !debounceId && setLocalValue(intQuantity);
    }, [intQuantity]);

    // Input is considered dirty if the local value is not in sync with the given one
    const isDirty = debounceId && localValue !== intQuantity;

    // Update on typing
    const onInputChange = (event) => {
        setValue(+event.target.value, debounceId);
    };

    // Capture enter to send the quantity to parent
    const onKeyPress = (event) => {
        if (["Enter", "NumpadEnter"].includes(event.code)) {
            if (localValue === "" || +localValue < 0) {
                event.preventDefault();
                toast.warn("Molimo unesite količinu!");
                return;
            }
            setValue(localValue);
        }
    };

    function setPlusMinusQuantity(keyCode, shiftPress, ctrlPress) {
        if ((shiftPress && keyCode === 38) || (shiftPress && keyCode === 39)) {
            setValue(localValue + 100, debounceId);
            return;
        }

        if ((shiftPress && keyCode === 37) || (shiftPress && keyCode === 40)) {
            setValue(localValue - 100, debounceId);
            return;
        }
        if ((ctrlPress && keyCode === 38) || (ctrlPress && keyCode === 39)) {
            setValue(localValue + 10, debounceId);
            return;
        }
        if ((ctrlPress && keyCode === 37) || (ctrlPress && keyCode === 40)) {
            setValue(localValue - 10, debounceId);
            return;
        }
        if (keyCode === 38 || keyCode === 39) {
            setValue(localValue + 1, debounceId);
            return;
        }
        if (keyCode === 37 || keyCode === 40) {
            setValue(localValue - 1, debounceId);
            return;
        }
    }

    const onKeyPressHandler = (event) => {
        const keyCode = event.keyCode;
        const shiftPress = event.shiftKey ? event.shiftKey : keyCode === 16 ? true : false;
        const ctrlPress = event.ctrlKey ? event.ctrlKey : keyCode === 17 ? true : false;
        setPlusMinusQuantity(keyCode, shiftPress, ctrlPress);
    };

    /**
     * Set the current value.
     *
     * @param {int} quantity The quantity to set.
     * @param {?string} debounceId Unique id used to uniquely identify debounced callback, or null to execute immediately.
     */
    const setValue = (quantity, debounceId = null) => {
        // Capture illegal values, including NaN
        if (!(+quantity > 0)) {
            quantity = 1;
        }

        // Update local value
        setLocalValue(quantity);
        setIsPlusMinusInputEmpty(false);

        // If debounce is defined apply it
        if (debounceId) {
            debounce(`PlusMinus:${debounceId}`, 800, () => {
                setQuantity(quantity);
            });

            // Update parent immediately
        } else {
            setQuantity(quantity);
        }
    };

    // If the values are not yet synchronized mark the input as dirty
    const wrapperClass = [scss.wrapper, className];
    if (isDirty) {
        wrapperClass.push(scss.dirty);
    }

    return (
        <div className={wrapperClass.join(" ")}>
            <span className={scss.button} onClick={preventDefault(() => setValue(localValue - 1, debounceId))}>
                -
            </span>

            <input
                maxLength="100"
                value={localValue >= 0 ? localValue : ""}
                // onKeyPress={onKeyPress}
                // onKeyDown={onKeyPressHandler}
                onKeyPress={(e) => {
                    onKeyPress(e);
                    handleBackspace(e);
                }}
                onKeyDown={(e) => {
                    onKeyPressHandler(e);
                    handleBackspace(e);
                }}
                onChange={onInputChange}
                onClick={(e) => {
                    e.preventDefault();
                    e.target.select();
                    // preventDefault(() => null);
                }}
                // tabIndex=
            />

            <span className={scss.button} onClick={preventDefault(() => setValue(localValue + 1, debounceId))}>
                +
            </span>

            {/* Indicator that the value is being synchronized */}
            <i className="fa fa-circle-o-notch fa-spin" />
        </div>
    );
};

export default PlusMinusInput;
