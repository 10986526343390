/**
 * Check if the passed boolean, string, object or array is empty.
 * All other types are considered never to be empty.
 *
 * @param {any|null} value The value to test if it is empty.
 *
 * @return {boolean} True if the supplied value is considered an empty value.
 */
export const isEmpty = (value) => {
    // Undefined or null
    if (value == null) {
        return true;
    }

    // Boolean
    if (typeof value === "boolean") {
        return !value;
    }

    // String
    if (typeof value === "string") {
        return value.trim() === "";
    }

    // Array
    if (Array.isArray(value)) {
        return value.length === 0;
    }

    // Object: if at least one key is found it is not empty
    if (typeof value === "object") {
        return Object.getPrototypeOf(value) === Object.prototype && Object.getOwnPropertySymbols(value).length === 0 && Object.getOwnPropertyNames(value).length === 0;
    }

    return false;
};

/**
 * Check if the passed boolean, string, object or array is not empty.
 * All other types are considered never to be empty.
 *
 * @param {any|null} value The value to test if it is not empty.
 *
 * @return {boolean} True if the supplied value is considered a non-empty value.
 */
export const isNotEmpty = (value) => !isEmpty(value);

/**
 * Chech if arrays are equal.
 *
 * @param {*[]} a The first array.
 * @param {*[]} b The second array.
 * @return {false|this is *[]}
 */
export const arraysAreEqual = (a: [], b: []): boolean => {
    // Not arrays
    if (!Array.isArray(a)) {
        return a === b;
    }

    // Much easier if both arrays are sorted
    a = [...a].sort();
    b = [...b].sort();

    // Compare each element
    return a.length === b.length && a.every((value, index) => arraysAreEqual(value, b[index]));
};

export const blobToData = (file: Blob) =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
    });
