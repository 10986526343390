import { Box } from "@mui/material";
import Button from "components/Buttons/Button/Button";
import Filters from "pages/Catalogue/Aside/Filters/Filters";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import styles from "./FiltersModal.module.scss";

const FiltersModal = ({
    openModal,
    setOpenModal,
    filters,
    setActiveFilters,
    changeFilters,
    setChangeFilters,
    lastSelectedFilterKey,
    setLastSelectedFilterKey,
    newQuery,
    setNewQuery,
    isFetched,
    setCurrentPage,
    categoryid,
    availableFilters,
    selectedFilters,
    setSelectedFilters,
}) => {
    return (
        <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Odaberite filtere">
            <Box className={styles.container}>
                <Filters
                    changeFilters={changeFilters}
                    setLastSelectedFilterKey={setLastSelectedFilterKey}
                    lastSelectedFilterKey={lastSelectedFilterKey}
                    setChangeFilters={setChangeFilters}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    availableFilters={availableFilters}
                    isLoaded={isFetched}
                    setNewQuery={setNewQuery}
                    newQuery={newQuery}
                    categoryid={categoryid}
                    setCurrentPage={setCurrentPage}
                />
                <Button label="Prikaži rezultate" onClick={() => setOpenModal(false)} className={styles.button} />
            </Box>
        </ModalWrapper>
    );
};

export default FiltersModal;
