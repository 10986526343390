import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import icons from "helpers/icons";
import Empty from "../../components/Empty/Empty";
import SidebarHeader from "../../components/layout/WithFixedSidebar/SidebarHeader/SidebarHeader";
import WithFixedSidebar from "../../components/layout/WithFixedSidebar/WithFixedSidebar";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import __ from "../../config/translations/serbian";
import useAPI from "../../hooks/useAPI";
import { GlobalQueries } from "../../hooks/useRefreshQuery";
import NotificationDetails from "./NotificationDetails/NotificationDetails";
import NotificationsList from "./NotificationsList/NotificationsList";

// import scss from "./NotificationsPage.module.scss";

/**
 * Show the notifications for the user and/or his parent company.
 *
 * @return {JSX.Element}
 * @constructor
 */
const NotificationsPage = () => {
  const queryClient = useQueryClient();
  const api = useAPI();

  // Load all notifications
  const { data: notifications, ...notificationsQuery } = useQuery([GlobalQueries.Notifications], () => api.list(`/notifications`));

  // Are the any unread notifications
  const unreadNotifications = notifications?.items.filter((notification) => !notification.seen_date).length;

  // Active notification to show details for
  const [selectedNotification, setSelectedNotification] = useState();

  // Header for the sidebar
  const sidebarHeader = (
    <SidebarHeader
      title={__.Notifications.Notifications}
      action={
        unreadNotifications > 0 && [
          "check",
          __.Notifications.Messages.MarkAllMessagesAsRead,
          () =>
            api.delete(`/notifications`).then(() => {
              toast.success(__.Notifications.Messages.AllMessagesMarkedAsRead);
              queryClient.invalidateQueries(GlobalQueries.Notifications).catch();
            }),
        ]
      }
      icon={icons.notifications}
    />
  );

  // List of notifications in the sidebar
  const sidebarList = <NotificationsList selected={selectedNotification} notifications={notifications?.items} isLoading={notificationsQuery.isLoading} onSelected={setSelectedNotification} />;

  return (
    <WithFixedSidebar header={sidebarHeader} sidebar={sidebarList}>
      <LoadingWrapper isLoading={notificationsQuery.isLoading}>
        {/* Show the notification details */}
        {selectedNotification && <NotificationDetails notificationId={selectedNotification?.id} />}

        {/* No notification has been selected */}
        {!selectedNotification && <Empty message={__.Notifications.Messages.ClickOnANotificationOnTheLeftToShowFullMessage} />}
      </LoadingWrapper>
    </WithFixedSidebar>
  );
};

export default NotificationsPage;
