import { useQuery } from "react-query";
import { useEffect, useState } from "react";

import Empty from "../../components/Empty/Empty";
import MainCard from "../../components/layout/MainCard/MainCard";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import __ from "../../config/translations/serbian";
import { currencyFormat } from "../../helpers/functions";
import useAPI from "../../hooks/useAPI";
import { GlobalQueries } from "../../hooks/useRefreshQuery";
import DataTable from "components/DataTable/DataTable";
import SearchBar from "components/UI/SearchBar";
import PaginationUI from "../../components/UI/PaginationUI";

import tblFields from "./tblFields.json";

/**
 * Show the list of previous orders.
 */
const OrdersPage = () => {
  const [search, setSearch] = useState("");

  const api = useAPI();
  const apiPath = "/previous-orders";
  const apiPath1 = "/previous-orders";
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  // Load all products in the cart
  const { data: orders, isLoading, refetch } = useQuery([GlobalQueries.Orders, search, page, limit], () => api.list(apiPath, { search, page, limit }));

  useEffect(() => {
    refetch();
  }, [page]);

  // Format the items
  const convertDate = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const dateObj = new Date(date);
    const formattedDate = dateObj
      .toLocaleString("sr-RS", options)
      .replace(/,/g, "")
      .replace(/(\d+)\D+(\d+)\D+(\d+)\D+(\d+):(\d+)/, "$1.$2.$3. $4:$5");
    return formattedDate;
  };
  const items = (orders?.items ?? []).map((item) => ({
    ...item,
    total: currencyFormat(item.total, item.currency),
    created_at: convertDate(item.created_at),
  }));
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => setCurrentPage(orders?.pagination.selected_page ?? 1), [orders, page, orders?.pagination.selected_page]);

  return (
    <MainCard>
      <div className="page-container">
        <div className={`p page-content`}>
          <h4 className="h-underline">{__.Orders.MenuTitle}</h4>

          <SearchBar onSubmit={(e) => e.preventDefault()} search={search} setSearch={setSearch} page={page} setPage={setPage} />

          <LoadingWrapper isLoading={isLoading}>
            {orders && <DataTable columns={tblFields} data={items} apiUrl={apiPath1} />}

            {items.length === 0 && <Empty search={search} orders={items} message={__.Orders.Messages.ThereAreNoOrdersToShow} />}
          </LoadingWrapper>

          <PaginationUI
            activePage={currentPage}
            setPage={setPage}
            numberOfDisplayed={orders?.pagination?.items_per_page ?? 12}
            numberOfProducts={orders?.pagination.total_items ?? 0}
            changePage={() => {
              api.list(apiPath, { search: search, limit: limit, page: page }).then((data) => { });
            }}
            page1={page}
          />
        </div>
      </div>
    </MainCard>
  );
};

export default OrdersPage;
