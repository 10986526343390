import scss from "./Table.module.scss"

const Table = ({ children }) => {
    return (
        <table className={scss.table}>
            {children}
        </table>
    )
}

export default Table
