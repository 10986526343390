import * as ReactDOMClient from "react-dom/client";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import "./assets/scss/custom.scss";
import App from "./App";
import SimpleReactLightbox from "simple-react-lightbox";
import { AuthContextProvider } from "store/auth-context";
import customerStore from "store/customer-store";
import filterStore from "store/category-filter-store";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

customerStore();
filterStore();

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// Initialize React Query
const queryClient = new QueryClient();

// Assert sure base URL for the API set
if (process.env.REACT_APP_URL) {
    localStorage.setItem("api", process.env.REACT_APP_URL);

    // Initial render: Render an element to the root.
    root.render(
        <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
                <SimpleReactLightbox>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SimpleReactLightbox>
            </AuthContextProvider>
        </QueryClientProvider>
    );
} else {
    root.render(<h1>Required env variable is not set: REACT_APP_URL</h1>, document.getElementById("root"));
}
