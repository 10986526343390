/**
 * Const for API.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {string}
*/
export const api = 'http://127.0.0.1:8000/api/v1/';

/**
 * How many ms before the expiration refresh token.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {number}
*/
export const msToExpire = 300000;

/**
 * How much ms is left not to refresh token.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {number}
*/
export const msToNotRefresh = 10000;

/**
 * Left ms to not refresh token.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {number}
*/
export const msToNotRefreshToken = 5000;

/**
 * How much ms to try again refresh when token is about to expire.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {number}
*/
export const msToTryAgainRefresh = 60000;

/**
 * In last how much ms is completed request.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {number}
*/
export const msLastRequest = 3000000;

/**
 * Const for email validation.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {string}
*/
export const regax = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
