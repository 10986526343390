import { Fragment } from "react";

import GrayBox from "../../../components/GrayBox/GrayBox";
import scss from "../OrdersDetailsPage.module.scss";
/**
 * List information.
 *
 * @param {string} title The title for the list.
 * @param {[]} list The list info.
 * @param {"right"|null} align The optional alignment.
 *
 * @return {JSX.Element}
 * @constructor
 */
const ListBlock = ({ title, list, align, additionalClass, children }) => (
    <div className={`${scss.block} ${additionalClass}`}>
        <h6 className={scss.title}>{title}</h6>
        <GrayBox>
            {list && (
                <>
                    <dl className={align}>
                        {list
                            .filter((item) => Array.isArray(item))
                            .map((item, index) => (
                                <Fragment key={index}>
                                    {item[0] && <dt>{item[0]}</dt>}
                                    <dd>{item[1]}</dd>
                                </Fragment>
                            ))}
                    </dl>

                    <div style={{ clear: "both" }} />
                </>
            )}
            {children}
        </GrayBox>
    </div>
);

export default ListBlock;
