import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SpecificationTab from "./Tabs/SpecificationTab";
import DescriptionTab from "./Tabs/DescriptionTab";
import DocumentsTab from "./Tabs/DocumentsTab";
import styles from "./DescriptionTabs.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Icon from "@mui/material/Icon";

const CustomTabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            // style={{ fontFamily: "Montserrat !important" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const DescriptionTabs = ({ product }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const overrides = {
        fontFamily: "Montserrat",
        color: " var(--croonus-black)",
        border: "none",
        backgroundColor: "var(--croonus-lightGray)",
        borderRadius: "7px",
        margin: "var(--gap)",
        minHeight: "0",
        "&.Mui-selected": { backgroundColor: "var(--croonus-darkGray)", color: "var(--croonus-white)" },
    };

    const mobileOverrides = {
        padding: 0,
        boxShadow: "none",
        border: "none",
        "&.Mui-expanded": {},
    };
    const isDecleration = Object.values(product?.declaration).some((dec) => dec != null && dec !== "");
    const isDescription = Object.values(product?.description).some((des) => des != null && des !== "");

    const tabsToShow = [
        { label: "Specifikacija", condition: product?.specification.length > 0 },
        { label: "Dodatne informacije", condition: isDecleration || isDescription },
        { label: "Dokumentacija", condition: product?.technicalDoc.length > 0 || product?.instructionDoc.length > 0 || product?.certificateDoc.length > 0 },
    ];

    useEffect(() => {
        const index = tabsToShow.findIndex((value) => value.value);
        if (index !== -1 && value !== index) {
            setValue(index);
        }
    }, []);

    return (
        <Box sx={{ width: "100%" }}>
            <Box className="mobile-hidden">
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { display: "none" } }}
                        sx={{ ".MuiTabs-flexContainer": { justifyContent: "center" } }}
                    >
                        {tabsToShow.map((tab, index) => {
                            if (tab.condition) {
                                return <Tab key={index} label={tab.label} {...a11yProps(index)} className={styles.tab} sx={overrides} />;
                            } else {
                                return <Tab key={index} label={""} {...a11yProps(index)} className={styles.hideTab} sx={overrides} />;
                            }
                            return null;
                        })}
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <SpecificationTab specification={product.specification} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <DescriptionTab description={product.description} declaration={product.declaration} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <DocumentsTab technicalDoc={product.technicalDoc} instructionDoc={product.instructionDoc} certificateDoc={product.certificateDoc} />
                </CustomTabPanel>
            </Box>

            <Box className="desktop-hidden">
                {product?.specification.length > 0 && (
                    <Accordion sx={mobileOverrides}>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<Icon>expand_more</Icon>}>
                            <h6 className={styles.mobileTitle}>Specifikacija</h6>
                        </AccordionSummary>
                        <AccordionDetails sx={mobileOverrides}>
                            <SpecificationTab specification={product.specification} />
                        </AccordionDetails>
                    </Accordion>
                )}
                {isDecleration ||
                    (isDescription && (
                        <Accordion sx={mobileOverrides}>
                            <AccordionSummary aria-controls="panel1b-content" id="panel1b-header" expandIcon={<Icon>expand_more</Icon>}>
                                <h6 className={styles.mobileTitle}>Dodatne informacije</h6>
                            </AccordionSummary>
                            <AccordionDetails sx={mobileOverrides}>
                                <DescriptionTab description={product.description} declaration={product.declaration} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                {(product?.technicalDoc.length > 0 || product?.instructionDoc.length > 0 || product?.certificateDoc.length > 0) && (
                    <Accordion sx={mobileOverrides}>
                        <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" expandIcon={<Icon>expand_more</Icon>}>
                            <h6 className={styles.mobileTitle}>Dokumentacija</h6>
                        </AccordionSummary>
                        <AccordionDetails sx={mobileOverrides}>
                            <DocumentsTab technicalDoc={product.technicalDoc} instructionDoc={product.instructionDoc} certificateDoc={product.certificateDoc} />
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
        </Box>
    );
};

export default DescriptionTabs;
