import scss from "./FormWrapper.module.scss";

/**
 * A simple form that will automatically prevent default actions on submit.
 *
 * @param {function()} onSubmit Handler for the submit event.
 * @param {JSX.Element[]} children Children to render in the form.
 * @param {string} className The name(s) of the class to use.
 * @param {{}} props Any additional properties for the form.
 *
 * @return {JSX.Element}
 * @constructor
 */
const FormWrapper = ({ onSubmit, children, className, ...props }) => (
    <form {...props} onSubmit={preventDefault(onSubmit)} className={`${className ?? "row"} ${scss.wrapper} `} autoComplete="off">
        {children}
    </form>
);

export default FormWrapper;

/**
 * Prevent the default event and handle the event manually.
 *
 * @param {?function()} handler The handler to handle the event.
 *
 * @return {(function(*): void)|*}
 */
export const preventDefault = (handler) => (event) => {
    event.preventDefault && event.preventDefault();
    handler && handler(event);
};
