import { Icon } from "@mui/material";
import DeleteDialog from "components/Dialogs/DeleteDielog";
import useAPI from "hooks/useAPI";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { join } from "../../helpers/functions";
import Table from "../Table/Table";
import scss from "./DataTable.module.scss";

export const ActionField = ({ value, actionHandlers }) => {
  let actions = value.split("_");
  const getLabel = (action) => {
    switch (action) {
      case "edit":
        return "edit";
      case "preview":
        return "visibility";
      case "delete":
        return "delete";
      case "list":
        return "list_alt";
      default:
        return "";
    }
  };
  return (
    <div className={scss.actionField}>
      {actions.map((action) => {
        return (
          <Icon key={action} onClick={actionHandlers[action]} className={scss.actionIcon}>
            {getLabel(action)}
          </Icon>
        );
      })}
    </div>
  );
};

const DataTable = ({ columns, data, apiUrl, maxHeight, actions }) => {
  const navigate = useNavigate();
  const api = useAPI();
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ show: false, id: null, mutate: null });
  const handleDeleteConfirm = async () => {
    api.delete(`${apiUrl}/${openDeleteDialog.id}`)
      .then(() => toast.success("Zapis je uspešno obrisan"))
      .catch(() => toast.warning("Došlo je do greške prilikom brisanja"));

    setOpenDeleteDialog({ show: false, id: null, mutate: 1 });
  };

  // Get the proper class name for the cell
  const getCellClassName = (column) => join([column.align, !column.showOnMobile && "mobile-hidden"]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            {(columns ?? []).map((column, index) => (
              <th key={index} className={getCellClassName(column)}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ maxHeight: maxHeight }}>
          {(data ?? []).map((item, index) => {
            const actionHandlers = {
              edit: () => {
                navigate(`${item.id}`);
              },
              preview: () => {
                let actionUrl = actions?.previewUrl ?? ""
                navigate(`${actionUrl}${item.id}`);
              },
              delete: () => {
                setOpenDeleteDialog({ show: true, id: item.id, mutate: null });
              },
              modal: () => {
                setOpenDeleteDialog({ show: true, id: item.id, mutate: null });
              },
              navigateToDetailPage: () => {

              }
            };

            return (
              <tr key={index} onClick={actionHandlers.preview}>
                {(columns ?? []).map((column, index) => {
                  return (
                    <td key={index} className={getCellClassName(column)}>
                      {column.field !== "action" ? item[column.field] : <ActionField value={column.type} actionHandlers={actionHandlers} />}
                    </td>
                  )
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <DeleteDialog handleConfirm={handleDeleteConfirm} openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} />
    </>
  );
};

export default DataTable;
