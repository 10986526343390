import { useQuery } from "react-query"
import useAPI from "./useAPI"
import { GlobalQueries } from "./useRefreshQuery"

/**
 * Allows access to external flags regarding the product, such as cart and wishlist status.
 *
 * @return {{wishlist: {count: (any|number), includes: (function(int): boolean)}, cart: {quantity: (function(int)), count: (any|number)}}}
 */
const useProductFlags = () => {
    const api = useAPI()

    // Load all cart items
    const { data: cart } = useQuery(
        [GlobalQueries.Cart],
        () => api.list(`/cart`)
    )

    // Load all wishlist items
    const { data: wishlist } = useQuery(
        [GlobalQueries.Wishlist],
        () => api.list(`/wishlist`)
    )

    return {
        cart: {
            count: cart?.items.length ?? 0,
            quantity: (product: int | {}): int => {
                return cart?.items.find(item => item.product.id === (product?.id || product))?.quantity ?? 0
            }
        },
        wishlist: {
            count: wishlist?.items.length ?? 0,
            includes: (product: int | {}): boolean => {
                return !!wishlist?.items.find(item => item.id === (product?.id || product))
            }
        }
    }
}

export default useProductFlags
