import icons from "helpers/icons";
import __ from "../../../config/translations/serbian";
import scss from "./AddToCardButton.module.scss";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../../Loading/LoadingWrapper";

const AddToCartButton = ({ onClick, type = "button", short = true, className, product, productVariant, location, loading }) => {
    const navigate = useNavigate();

    return (
        <>
            <button disabled={loading ? true : false} className={`btn-basic relative btn-basic-secondary ${scss.button} ${className}`} type={type} onClick={onClick}>
                <LoadingWrapper isLoading={loading} type="spin" size={20} color={"#fff"} className={loading ? `${scss.loading}` : `${scss.wrapper} ${scss.wrappernopadding}`}></LoadingWrapper>
                <div className={loading ? scss.loading2 : ``}>
                    <span>
                        {productVariant ? (
                            productVariant?.inventory?.inventory_defined && productVariant?.price?.price_defined ? (
                                short ? (
                                    __.Cart.Cart
                                ) : (
                                    __.Cart.AddToCart
                                )
                            ) : (
                                <span
                                // onClick={() => {
                                //     productVariant ? navigate(`/kontakt?slug=${productVariant?.basic_data?.slug}`) : navigate(`/kontakt?slug=${product?.id}`);
                                // }}
                                >
                                    Pošalji upit
                                </span>
                            )
                        ) : product?.inventory?.inventory_defined && product?.price?.price_defined ? (
                            short ? (
                                __.Cart.Cart
                            ) : (
                                __.Cart.AddToCart
                            )
                        ) : (
                            <span
                            // onClick={() => {
                            //     navigate(`/kontakt?slug=${product?.id}`);
                            // }}
                            >
                                Pošalji upit
                            </span>
                        )}
                    </span>
                    <img src={icons.cart} alt="" />
                </div>
            </button>
        </>
    );
};

export default AddToCartButton;
