import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import __ from "../../../../config/translations/serbian";
import Sitemap from "../../../../routes/sitemap";
import { currencyFormat } from "../../../../helpers/functions";
import AuthContext from "../../../../store/auth-context";

import scss from "./FinanceInfo.module.scss";

const FinanceInfo = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState(user);

  useEffect(() => {
    setData(user);
  }, [user]);

  return (
    <div>
      <div className={scss.wrapper}>
        {/* Company name */}
        <Link to={Sitemap.profile._}>
          <span className={scss.companyName}>{data?.company?.name}</span>
        </Link>

        {/* Debt within */}
        <p>
          {`${__.Company.Finances.DebtInside}: `}
          <span className={scss.greenText}>{currencyFormat(data?.company?.debt.in, null, false)}</span>
        </p>

        {/* Debt outside */}
        <p>
          {`${__.Company.Finances.DebtOutside}: `}
          <span className={scss.redText}>{currencyFormat(data?.company?.debt.out, null, false)}</span>
        </p>
      </div>
    </div>
  );
};

export default FinanceInfo;
