import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import AuthContext from "store/auth-context";
import Header from "./components/layout/Header/Header";
import LeftSideBar from "./components/layout/LeftSideBar/LeftSideBar";
import useHttp from "hooks/use-http";
import { useStore } from "store/store";
import { getTitleSlug } from "helpers/services";
import { authorizedRoutes, unauthorizedRoutes } from "./routes/routes";
import Sitemap from "./routes/sitemap";
import PageWrapper from "components/layout/PageWrapper/PageWrapper";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import useAPI from "./hooks/useAPI";
import "../src/components/Filter/rslider.css";
import { QueryClient, QueryClientProvider } from "react-query";

const client = new QueryClient();

function App() {
    const api = useAPI();

    // Authorization store
    const authCtx = useContext(AuthContext);

    // Use http instance for http handling
    const { sendRequest: request } = useHttp();

    const [openModal, setOpenModal] = useState("none");

    const dispatch = useStore()[1];

    // Load configuration values
    useEffect(() => {
        // Title
        getTitleSlug().then((response) => (document.getElementById("TitleDOM").innerHTML = response.data));
    }, []);

    useEffect(() => {
        if (authCtx.isLoggedIn) {
            dispatch("CART", request).then();
        }
    }, [authCtx.isLoggedIn, request]);

    useEffect(() => {
        const getUserData = async () => {
            if (authCtx.isLoggedIn) {
                await api.get("/profile/user-data").then((response) => {
                    //TODO: napraviti da proverava da li je korisnik ulogovan ili ne

                    if (response) {
                        authCtx.refreshUser(response);
                    }
                });
            }
        };

        const interval = setInterval(() => {
            getUserData();
        }, 10 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const lastTimeUserWasRefreshed = localStorage.getItem("lastTimeUserWasRefreshed");
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        const todayDate = new Date().getTime(); // Convert today's date to timestamp

        if (lastTimeUserWasRefreshed) {
            const lastRefreshedTimestamp = new Date(lastTimeUserWasRefreshed).getTime(); // Convert stored date to timestamp

            if (lastRefreshedTimestamp < todayDate) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("lastTimeUserWasRefreshed");
                authCtx.logout();
            } else {
                if (token && user) {
                    authCtx.refreshUser(JSON.parse(user));
                }
            }
        }
    }, []);

    return (
        <QueryClientProvider client={client}>
            <div>
                {authCtx.isLoggedIn && <Header openModal={openModal} setOpenModal={setOpenModal} />}

                <PageWrapper isLoggedIn={authCtx.isLoggedIn}>
                    {authCtx.isLoggedIn && <LeftSideBar openModal={openModal} setOpenModal={setOpenModal} />}
                    <Routes>
                        {authCtx.isLoggedIn && authorizedRoutes.map(([path, element]) => <Route path={path} element={element} key={path} />)}
                        {!authCtx.isLoggedIn && unauthorizedRoutes.map(([path, element]) => <Route path={path} element={element} key={path} />)}
                        <Route path={Sitemap.any} element={<PageNotFound />} />
                    </Routes>
                </PageWrapper>

                <ToastContainer position="top-center" theme="colored" transition={Flip} autoClose={800} newestOnTop={false} draggable={false} closeOnClick hideProgressBar pauseOnHover />
            </div>
        </QueryClientProvider>
    );
}

export default App;
