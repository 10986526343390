import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useAPI from "../../../hooks/useAPI";
import { GlobalQueries } from "../../../hooks/useRefreshQuery";
import ProfilePage from "../ProfilePage";
import CreateForm from "components/Form/CreateForm/CreateForm";
import Buttons from "components/Buttons/Buttons/Buttons";
import Button from "components/Buttons/Button/Button";

import formFields from "./formFields.json";

const AddressPage = () => {
  const api = useAPI();
  const apiPath = "profile/company/shipping-address";
  const { id } = useParams();

  // Load all notifications
  const { data, isLoading } = useQuery([GlobalQueries.Profile.Address], () => api.get(`${apiPath}/${id}`));

  // Local state for the addresses
  const [address, setAddress] = useState({});
  useEffect(() => {
    setAddress(data ?? {});
  }, [data, setAddress]);

  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    api.post(apiPath, address)
      .then((response) => {
        toast.success("Uspešno izmenjeni podaci!");
        setAddress(response);
        setLoading(false);
      })
      .catch((error) => toast.warn("Greška"));
  };

  return (
    <ProfilePage>
      <h4 className="h-underline">{address?.id != null ? address?.name : "Unos nove adrese"}</h4>
      <Buttons align="right">
        <Button label="Nazad" to={"/nalog/adrese"} />
      </Buttons>
      <CreateForm loading={loading} data={address} setData={setAddress} formFields={formFields} submit={{ label: "Sačuvaj", action: submitHandler }} />
    </ProfilePage>
  );
};

export default AddressPage;
