import creditImg from "../../../assets/images/icons/credit.png"
import deliverImg from "../../../assets/images/icons/delivery.png"
import serviceImg from "../../../assets/images/icons/service.png"
import __ from "../../../config/translations/serbian"
import classes from "./Services.module.scss"
import useAPI from "../../../hooks/useAPI";
import {useQuery} from "react-query";
import { useEffect, useState } from "react";
import {GlobalQueries} from "../../../hooks/useRefreshQuery";

const Services = () => {
    const api = useAPI();
    const [productBenefits, setProductBenefits] = useState([]);

    const { data: benefits, isLoading: isFetching } = useQuery(["productItems.Benefits"], () => api.list(`/banners/product_benefits`));

    return (
            <ul className={classes.wrapper}>
                {benefits?.items.map((item) => (
                    <li key={item.id}>
                        <span>
                            <img src={item.image} alt="{item.name}" />
                        </span>
                        <div>{item.button}</div>
                    </li>
                ))}
            </ul>
        )

}

export default Services
