import { Box, Icon, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./ModalWrapper.module.scss";

const ModalWrapper = ({ openModal, setOpenModal, children, title, back = false, fullScreen = true }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        setOpenModal(false);
        if (back) {
            navigate(-1);
        }
    };

    const sx = fullScreen
        ? {
              top: "60px",
              height: "calc(var(--vh, 1vh) * 100 - 120px);",
              display: "block",
              "@media (min-width:768px)": {
                  display: "none",
              },
          }
        : {
              top: "25vh",
              height: "50vh",
              width: "80%",
              left: "10%",
              border: "2px solid var(--croonus-lightGray)",
              display: "block",
              "@media (min-width:768px)": {
                  display: "none",
              },
          };

    return (
        <Modal open={openModal} onClose={handleClose} hideBackdrop sx={sx}>
            <Box className={styles.modal}>
                <Box className={styles.modalTitleContainer}>
                    <h5 className={styles.modalTitle}>{title}</h5>
                    <div onClick={handleClose}>
                        <Icon>close</Icon>
                    </div>
                </Box>
                <Box className={styles.modalContent}>{children}</Box>
            </Box>
        </Modal>
    );
};

export default ModalWrapper;
