import Button from "components/Buttons/Button/Button";
import styles from "./Stickers.module.scss";

const Sticker = ({ sticker }) => {
  const onClick = (sticker) => {
    if (sticker.url) {
      // Open in new tab
      if (sticker.target === "blank" || sticker.target === "_blank") {
        window.open(sticker.url, "_blank");
        return;
      }

      // Han
      window.open(sticker.url, "_self");
    }
  };
  return (
    <div className={styles.sticker}>
      {sticker.image ? <img src={sticker.image} alt="" /> : <p className={styles.stickerbg}>{sticker.name}</p>}
      <div className={styles.stickerHover}>
        {sticker.image ? <img src={sticker.image} alt="" /> : <p>{sticker.name}</p>}
        {sticker.title && <h6>{sticker.title}</h6>}
        {sticker.description && <p>{sticker.description}</p>}
        {sticker.button && <Button label={sticker.button} onClick={() => onClick(sticker)} />}
      </div>
    </div>
  );
};

export default Sticker;
