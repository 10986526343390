import { Link } from "react-router-dom";
import styles from "./HeaderLink.module.scss";

/**
 * Basic header link component
 * @param {image} img image to show in link
 * @param {string} to link to navigate to when clicked
 * @param {string} alert data to show in alert circle
 *
 *@return {JSX.Element}
 * @constructor
 */

const HeaderLink = ({ img, to, onClick, alert }) => {
    return to ? (
        <Link to={to} onClick={onClick} className={styles.link}>
            {alert && <div className={styles.alert}>{alert}</div>}
            <img alt={img} src={img} />
        </Link>
    ) : (
        <button onClick={onClick} className={styles.link}>
            {alert && <div className={styles.alert}>{alert}</div>}
            <img alt={img} src={img} />
        </button>
    );
};

export default HeaderLink;
