import axios, { AxiosResponse, AxiosError } from 'axios'
/**
 * Path to beckend.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @returns {string}
 */
export const api = () => {
  return (
    process.env.REACT_APP_URL || "https://api.staging.croonus.com/api/v1/b2b/"
  ); // FIXME
};

/**
 * Login request.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {loginData} Object
 * @param {request} Promise
 * @returns {Object}
 */
export const loginService = async (loginData, request) => {
  let data;

  const response = (userData) => {
    data = userData;
  };

  await request(
    {
      url: api() + "login",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: loginData,
    },
    response
  );
  return data;
};

/**
 * Refresh token.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const refreshTokenService = async (request) => {
  let data;

  const response = (userData) => {
    data = userData;
  };

  await request(
    {
      url: api() + "refresh",
      method: "PUT",
    },
    response
  );
  return data;
};

/**
 * Get list of categories.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Array}
 */
export const categoryListService = async (request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "" +
          "categories/list",
      method: "PUT",
    },
    response
  );
  return data;
};

/**
 * Get list of products.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Array}
 */
export const productListService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "products/list?page=" + getData?.activePage ?? 1,
      method: "PUT",
      body: getData.requestData,
    },
    response
  );
  return data;
};

/**
 * Get list of products by category.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Array}
 */
export const getProductsByCategoryService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "products/category?page=" + getData?.activePage ?? 1,
      method: "PUT",
      body: getData.requestData,
    },
    response
  );
  return data;
};

/**
 * Get product.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const getProductService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "products/get",
      method: "PUT",
      body: getData,
    },
    response
  );
  return data;
};

/**
 * Get customer data for logged in user.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const getCustomerDataService = async (request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "customer-b2b-data",
      method: "PUT",
    },
    response
  );
  return data;
};

/**
 * Add item in cart.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const addToCartService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "orders/add-to-cart",
      method: "PUT",
      body: getData,
    },
    response
  );
  return data;
};

/**
 * Save cart.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const saveCartService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "orders/save",
      method: "PUT",
      body: getData,
    },
    response
  );
  return data;
};

/**
 * Get customer data for logged in user.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const getOrderListService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "orders/list?page=" + getData?.activePage ?? 1,
      method: "PUT",
      body: getData.requestData,
    },
    response
  );
  return data;
};

/**
 * Get order.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const getOrderService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "orders/get",
      method: "PUT",
      body: getData,
    },
    response
  );
  return data;
};

/**
 * Add product in wish list.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const addToWishListService = async (getData, request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "wish-list/add",
      method: "PUT",
      body: getData,
    },
    response
  );
  return data;
};

/**
 * Get company data for logged in user.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {request} Promise
 * @returns {Object}
 */
export const getCompanyDataService = async (request) => {
  let data;

  const response = (responseData) => {
    data = responseData;
  };

  await request(
    {
      url: api() + "company-profile/get",
      method: "PUT",
    },
    response
  );
  return data;
};

/**
 * Fetch title for index.html
 * @returns {String}
 */
export const getTitleSlug = async () => {
  return await axios.get(
    `${api()}configuration/presentation/basic/portal_name/value`
    // {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // }
  );
};

/**
 * the only task of this function is to check if URL is valid or not
 * it is used for images URL-s but it could be used also for other URL-s also
 * @param {String} URL
 * @returns {Boolean}
 */
export const checkUrl = (imageUrl) => {
  return axios
    .get(imageUrl)
    .then((response) => {
      return response.status === 200;
    })
    .catch(() => false);
};

/**
 * Fetch data from path
 * @returns {Object}
 */

export const getDataFromPath = async (path) => {
  return await axios.get(`${api()}${path}`);
};

export const getExternalCss = async () => {
  return await axios.get(`${api()}configuration/presentation/css/css/value`);
  // return await axios.get(`${api()}/assets/style.css`);
};
