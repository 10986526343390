import { useQuery } from "react-query";

import __ from "config/translations/serbian";
import MainCard from "components/layout/MainCard/MainCard";
import AnanlyticBlock from "./AnalyticBlock/AnalyticBlock";
import LoadingWrapper from "components/Loading/LoadingWrapper";
import icons from "helpers/icons";
import useAPI from "hooks/useAPI";
import { currencyFormat } from "helpers/functions";

import scss from "./AnalitycCard.module.scss";

const AnalyticCardPage = () => {
  const api = useAPI();
  const apiPath = "/analytics-card";

  const { data, isLoading } = useQuery([], () => api.get(apiPath));

  return (
    <MainCard>
      <div className="page-container">
        <div className={`p page-content`}>
          <h4 className="h-underline">{__.AnalyticCard.Title}</h4>
          <LoadingWrapper isLoading={isLoading}>
            <div className={scss.analyticsContainer}>
              <AnanlyticBlock title="Saldo" value={currencyFormat(data?.saldo, data?.currency)} icon={icons.analytics} background="blue" />
              <AnanlyticBlock title="U valuti" value={currencyFormat(data?.debt_in_currency, data?.currency)} icon={icons.analytics} background="green" />
              <AnanlyticBlock title="Van valute" value={currencyFormat(data?.debt_out_currency, data?.currency)} icon={icons.analytics} background="red" />
              <AnanlyticBlock title="Ododbreno dana" value={data?.debt_days} icon={icons.analytics} background="yellow" />
            </div>
          </LoadingWrapper>
        </div>
      </div>
    </MainCard>
  );
};

export default AnalyticCardPage;
