import Box from "@mui/material/Box";
import { useEffect, useRef } from "react";
import styles from "../DescriptionTabs.module.scss";

const DescriptionTab = ({ description, declaration }) => {
  const descText = useRef("");

  useEffect(() => {
    if (description?.description) {
      descText.current.innerHTML = description.description;
    }
  }, []);

  return (
    <>
      <Box className={styles.tabContent} sx={{ fontFamily: "Montserrat !important" }}>
        {description?.description?.length > 0 && (
          <>
            <h6 className={styles.tabTitle}>Opis</h6>

            <Box className={styles.tabSection}>
              <>
                {description?.description && (
                  <p className={styles.specificationRow}>
                    <span ref={descText}></span>
                  </p>
                )}
                {description?.manufacture_name && (
                  <p className={styles.specificationRow}>
                    <span className={styles.specificationRowTitle}>Proizvodjač:</span>
                    <span className={styles.specificationRowValue}>{description?.manufacture_name}</span>
                  </p>
                )}
                {description?.brand_name && (
                  <p className={styles.specificationRow}>
                    <span className={styles.specificationRowTitle}>Brend:</span>
                    <span className={styles.specificationRowValue}>{description?.brand_name}</span>
                  </p>
                )}
              </>
              {!(description?.description || description?.manufacture_name || description?.brand_name) && <p>N/A</p>}
            </Box>
          </>
        )}
        {declaration && (
          <>
            {declaration.name || declaration.year || declaration.importer_name || declaration.country_name || declaration.note ? (
              <>
                <h6 className={styles.tabTitle}>Deklaracija</h6>
                <Box className={styles.tabSection}>
                  {declaration.name && (
                    <p className={styles.specificationRow}>
                      <span className={styles.specificationRowTitle}>Naziv:</span>
                      <span className={styles.specificationRowValue}>{declaration.name}</span>
                    </p>
                  )}

                  {declaration.year && (
                    <p className={styles.specificationRow}>
                      <span className={styles.specificationRowTitle}>Godina proizvodnje:</span>
                      <span className={styles.specificationRowValue}>{declaration.year}</span>
                    </p>
                  )}

                  {declaration.importer_name && (
                    <p className={styles.specificationRow}>
                      <span className={styles.specificationRowTitle}>Uvoznik:</span>
                      <span className={styles.specificationRowValue}>{declaration.importer_name}</span>
                    </p>
                  )}

                  {declaration.country_name && (
                    <p className={styles.specificationRow}>
                      <span className={styles.specificationRowTitle}>Zemlja uvoza:</span>
                      <span className={styles.specificationRowValue}>{declaration.country_name}</span>
                    </p>
                  )}

                  {declaration.note && (
                    <p className={styles.specificationRow}>
                      <span className={styles.specificationRowTitle}>Napomena:</span>
                      <span className={styles.specificationRowValue}>{declaration.note}</span>
                    </p>
                  )}
                </Box>
              </>
            ) : null}
          </>
        )}
      </Box>
    </>
  );
};

export default DescriptionTab;
