import {Link} from "react-router-dom"
import scss from "./Breadcrumbs.module.scss"

/**
 * TODO
 * Show the breadcrumbs in the layout.
 *
 * @typedef {{}} Breadcrumb
 *      @property {string} url
 *      @property {string} name
 *
 * @param {Breadcrumb[]} crumbs The breadcrumbs to show.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Breadcrumbs = ({crumbs = []}) => (
    <section className={scss.wrapper}>
        {crumbs.map((crumb, index) =>
            index < crumbs.length - 1 ? (
                <Link key={index} className={scss.crumb} to={`${crumb.url}`}>
                    {crumb.name}
                </Link>
            ) : (
                <span key={index} className={scss.crumbProduct}>
                    {crumb.name}
                </span>
            )
        )}
    </section>
)

export default Breadcrumbs
