import { useParams } from "react-router-dom";

import Button from "components/Buttons/Button/Button";
import MainCard from "components/layout/MainCard/MainCard";
import Sitemap from "routes/sitemap";
import StaticPage from "../../components/StaticPage/StaticPage";
import background from "../../assets/images/backgrounds/checkmark-transparent.png";

import scss from "./CheckoutSuccess.module.scss";

const CheckoutSuccess = () => {
    const { orderId } = useParams();
    return (
        <MainCard>
            <div className="page-container">
                <div className={`p page-content ${scss.container}`} style={{ backgroundImage: `url(${background})` }}>
                    <h2>Uspešno kreirana porudžbenica</h2>
                    <p>Poštovani,</p>
                    <p>
                        Hvala Vam na ukazanom poverenju.
                        <br />
                        Vaša porudžbenica je evidentirana pod brojem #{orderId}
                    </p>
                    <StaticPage slug={"cart-success-page"} />
                    {/* <p>
                        U svakom trenutku možete otkazati porudžbenicu <Link to="">klikom na sledeći link</Link>
                    </p> */}
                    <Button to={Sitemap.contact._} label="Pošalji poruku" />
                </div>
            </div>
        </MainCard>
    );
};

export default CheckoutSuccess;
