import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import Button from "components/Buttons/Button/Button";
import Buttons from "components/Buttons/Buttons/Buttons";
import { currencyFormat } from "helpers/functions";
import Sitemap from "routes/sitemap";
import LoadingWrapper from "../../../components/Loading/LoadingWrapper";
import useAPI from "../../../hooks/useAPI";
import { GlobalQueries } from "../../../hooks/useRefreshQuery";
import ProfilePage from "../ProfilePage";

import scss from "./DebtPage.module.scss";

const DebtPage = () => {
  const api = useAPI();
  const apiPath = "/profile/company/debt";

  // Load all data
  const { data, isLoading } = useQuery([GlobalQueries.Profile.MyProfile], () => api.get(apiPath));

  // Local state for the debt
  const [debt, setDebt] = useState();
  useEffect(() => {
    setDebt(data);
  }, [data, setDebt]);

  return (
    <ProfilePage>
      <h4 className="h-underline">Valuta i limit</h4>
      <LoadingWrapper isLoading={isLoading}>
        {debt && (
          <div>
            <h5 className={scss.subtitle}>Trenutni paramteri vašeg profila:</h5>
            <div className={scss.debt}>
              <div className={scss.debtSection}>
                <p className={scss.debtTitle}>{debt.debt_days ?? 0}</p>
                <p className={scss.debtSubtitle}>dana</p>
                <p className={scss.debtDescription}>Valuta (odobreno dana)</p>
              </div>
              <span className={scss.divider} />
              <div className={scss.debtSection}>
                <p className={scss.debtTitle}>{currencyFormat(debt.credit_limit, "", false) ?? 0}</p>
                <p className={scss.debtSubtitle}>RSD</p>
                <p className={scss.debtDescription}>Kredit limit</p>
              </div>
            </div>
            <p className={`${scss.question} ${scss.questionTitle}`}>Planirate nabavku koja nije predviđena odobrenim komercijalnim uslovima?</p>
            <p className={scss.question}>Kontaktirajte nas u cilju kompromisnog rešenja.</p>
            <Buttons>
              <Button label="Pošalji poruku" to={Sitemap.contact._} />
            </Buttons>
          </div>
        )}
      </LoadingWrapper>
    </ProfilePage>
  );
};

export default DebtPage;
