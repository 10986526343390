import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import CreateForm from "components/Form/CreateForm/CreateForm";
import __ from "../../../config/translations/serbian";
import useAPI from "../../../hooks/useAPI";
import { GlobalQueries } from "../../../hooks/useRefreshQuery";
import ProfilePage from "../ProfilePage";
import AuthContext from "../../../store/auth-context";

import formFields from "./formFields.json";

const MyProfilePage = () => {
  const api = useAPI();
  const apiPath = "/profile/user";

  // Load all data
  const { data, isLoading } = useQuery([GlobalQueries.Profile.MyProfile], () => api.get(apiPath));

  // Local state for the user
  const [user, setUser] = useState();
  useEffect(() => {
    setUser(data);
  }, [data, setUser]);
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const handleUser = async () => {
    await api.get("/profile/user-data").then((response) => {
      if (response) {
        authCtx.refreshUser(response);
      }
    });
  };
  const submitHandler = () => {
    setLoading(true);
    api.post(apiPath, user)
      .then((response) => {
        toast.success("Uspešno izmenjeni podaci!");
        setUser(response);
        handleUser();
        setLoading(false);
      })
      .catch((error) => toast.warn("Greška"));
  };

  return (
      <ProfilePage>
          <h4 className="h-underline">{__.Profile.SubPages.MyProfile.Title}</h4>
          {user && <CreateForm loading={loading} data={user} setData={setUser} formFields={formFields} submit={{ label: "Sačuvaj", action: submitHandler }} />}
      </ProfilePage>
  );
};

export default MyProfilePage;
