import Button from "components/Buttons/Button/Button";
import Buttons from "components/Buttons/Buttons/Buttons";
import MainCard from "components/layout/MainCard/MainCard";
import Sitemap from "routes/sitemap";

import scss from "./PageNotFound.module.scss";

const PageNotFound = () => {
  return (
    <MainCard>
      <div className="page-container">
        <div className={`p page-content ${scss.container}`}>
          <h3 className={scss.title}>404</h3>
          <h4>Stranica nije pronadjena</h4>
          <p>Stranica koju tražite ne postoji.</p>
          <Buttons align="center">
            <Button to={Sitemap.home} label="Vrati se na početnu stranu" />
          </Buttons>
        </div>
      </div>
    </MainCard>
  );
};

export default PageNotFound;
