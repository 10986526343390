import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import Button from "components/Buttons/Button/Button";
import Buttons from "components/Buttons/Buttons/Buttons";
import DataTable from "components/DataTable/DataTable";
import LoadingWrapper from "../../../components/Loading/LoadingWrapper";
import __ from "../../../config/translations/serbian";
import useAPI from "../../../hooks/useAPI";
import ProfilePage from "../ProfilePage";

import tblFields from "./tblFields.json";

const AddressesPage = () => {
  const api = useAPI();
  const apiPath = "profile/company/shipping-address";

  // Load all notifications
  const { data, isLoading } = useQuery([], () => api.list(apiPath));
  // Local state for the addresses
  const [addresses, setAddresses] = useState();

  useEffect(() => {
    setAddresses(data?.items);
  }, [data, setAddresses]);

  return (
    <ProfilePage>
      <h4 className="h-underline">{__.Profile.SubPages.Addresses.Title}</h4>
      <LoadingWrapper isLoading={isLoading}>
        <Buttons align="right">
          <Button label="Novo" to="new" />
        </Buttons>
        <DataTable columns={tblFields} data={addresses} apiUrl={apiPath} />
      </LoadingWrapper>
    </ProfilePage>
  );
};

export default AddressesPage;
