import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";

import __ from "../../../config/translations/serbian";
import Sitemap from "routes/sitemap";
import { join } from "../../../helpers/functions";
import useAPI from "../../../hooks/useAPI";
import Filters from "./Filters/Filters";
import FinanceInfo from "./FinanceInfo/FinanceInfo";
import Subcategories from "./Children/Subcategories";
import Loading from "components/Loading/Loading";

import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import CelebrationIcon from "@mui/icons-material/Celebration";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import Divider from "@mui/material/Divider";

import "../../../assets/scss/variables.scss";
import scss from "./CatalogueAside.module.scss";

/**
 * Show category tree.
 *
 * @param {{}} activeCategory The currently active category.
 * @param {function({})} onActivateCategory Invoked when the active category is changed.
 * @param {[]} availableFilters The list of available filters.
 * @param {function([])} setActiveFilters Notifies parent on which filters have been selected.
 *
 * @return {JSX.Element}
 * @constructor
 */
const CatalogueAside = ({
    activeCategory,
    setActiveCategory = () => null,
    onActivateCategory,
    availableFilters,
    setActiveFilters,
    isFetched,
    setSelectedFilters,
    selectedFilters,
    changeFilters,
    setChangeFilters,
    setNewQuery = () => null,
    newQuery,
    categoryid,
    setCurrentPage,
    lastSelectedFilterKey,
    setLastSelectedFilterKey = () => {},
}) => {
    const api = useAPI();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    // Selected and currently active category
    const [selectedCategory, setSelectedCategory] = useState(null);

    // Load all categories
    const { data } = useQuery(["CatalogueAside.CategoryTree"], () => api.get("/categories/product/tree"));

    // Set the path for each category
    const setPath = (category, parent = null, depth = 0) => {
        category = {
            ...category,
            depth: depth,
            path: (parent?.path ?? "") + "/" + category.slug,
        };

        // Recurse over children
        category.children = (category.children ?? []).map((child) => setPath(child, category, depth + 1));
        return category;
    };
    const categories = (data ?? []).map((category) => setPath(category));

    useEffect(() => {
        let slugs = pathname.split("/");
        let cat = data ?? [];
        for (const slug of slugs) {
            let tmp = cat.filter((item) => item.slug === slug)[0]?.children;
            if (tmp) {
                cat = tmp;
            }
        }
        let active = cat.filter((item) => item.slug === slugs[slugs.length - 1])[0];

        if (active != null && pathname !== Sitemap.catalogue._) {
            setActiveCategory(active);
        }
        if (pathname === Sitemap.home) {
            setActiveCategory(null);
        }
        setSelectedCategory(null);
    }, [pathname]);

    // Notify parents about activated category
    const activateCategory = (category) => {
        setSelectedCategory(null);
        onActivateCategory(category);
    };
    const location = useLocation();

    const { data: landingPages } = useQuery(["PromoPage"], () => api.list("/landing-pages/list"));

    const { data: newPages } = useQuery(["NewPage"], () => api.list("/products/new-in/list"));

    // const { data: actionPages } = useQuery(["ActionPage"], () => api.list("/landing-pages/list"));

    const hasNoPages = newPages?.items?.length === 0 && landingPages?.items?.length === 0;

    return (
        <div className={scss.wrapper}>
            {/* Catalogue home */}
            {!activeCategory && (
                <>
                    {/* Basic company finance info */}
                    <FinanceInfo />
                    {/* Top categories */}
                    <div className={scss.label}>
                        {location?.pathname?.includes("/katalog/kategorija") ||
                        location?.pathname?.includes("/novo") ||
                        location?.pathname?.includes("/promo") ||
                        location?.pathname?.includes("/lista-zelja") ||
                        location?.pathname?.includes("/akcija") ? (
                            <>
                                <Link className={`text-decoration-none text-white flex align-items-center`} to={`/pocetna`}>
                                    <Icon>arrow_back</Icon>
                                    <div>Kategorije proizvoda</div>
                                </Link>
                            </>
                        ) : location?.pathname?.includes("/katalog/pretraga") ? (
                            <>Pretraga</>
                        ) : (
                            <>{__.Catalogue.Category.ProductCategories}</>
                        )}
                    </div>
                    <Box sx={{ padding: "0.25rem 0 0.25rem 0" }}>
                        {newPages?.items?.length > 0 && (
                            <Link className={`d-flex ${scss.promo} gap-1  text-decoration-none text-white flex align-items-center`} to={`/novo`}>
                                {/*<img src="/images/discount.png" alt="" width={30} height={30} />*/}
                                <NewReleasesIcon sx={{ fontSize: "20px", color: "var(--primary-color)" }} />
                                <span>Novo u ponudi</span>
                            </Link>
                        )}
                        {landingPages?.items?.length > 0 && (
                            <Link className={`d-flex ${scss.promo} gap-1  text-decoration-none text-white flex align-items-center`} to={`/promo`}>
                                {/*<img src="/images/discount.png" alt="" width={30} height={30} />*/}
                                <CelebrationIcon sx={{ fontSize: "20px", color: "var(--primary-color)" }} />
                                <span>Promocije</span>
                            </Link>
                        )}
                        {/* {actionPages?.items?.length > 0 && ( */}
                        {/* <Link className={`d-flex ${scss.promo} gap-1  text-decoration-none text-white flex align-items-center`} to={`/akcija`}> */}
                        {/*<img src="/images/discount.png" alt="" width={30} height={30} />*/}
                        {/* <FormatColorTextIcon sx={{ fontSize: "20px", color: "var(--primary-color)" }} /> */}
                        {/* <span>Akcije</span> */}
                        {/* </Link> */}
                        {/* )} */}
                    </Box>

                    {!hasNoPages && <Divider sx={{ backgroundColor: "var(--primary-color)", borderColor: "transparent", opacity: "0.5" }} />}

                    <div
                        div
                        className={
                            location?.pathname?.includes("/katalog/kategorija") || location?.pathname?.includes("/katalog/pretraga") || location?.pathname?.includes("/novo") ? scss.label : null
                        }
                    >
                        {(location?.pathname?.includes("/katalog/kategorija/") || location?.pathname?.includes("/katalog/pretraga/") || location?.pathname?.includes("/novo")) && <>Filteri:</>}
                    </div>
                    {!location?.pathname?.includes("/katalog/kategorija") && !location?.pathname?.includes("/katalog/pretraga") && !location?.pathname?.includes("/novo") ? (
                        <ul className={`no-padding ${scss.items}`}>
                            {(categories ?? []).map((item) => (
                                <li key={item.id} className={join([scss.list, selectedCategory?.id === item.id && scss.active])}>
                                    {item?.children?.length > 0 ? (
                                        <button className={scss.superLink} onClick={() => setSelectedCategory(item)}>
                                            {item.icon && <img className={scss.icon} src={item.icon} alt="" />}
                                            {item.name}
                                        </button>
                                    ) : (
                                        <Link to={`/katalog/kategorija/${item?.slug}`} className={` ${scss.superLink} text-decoration-none`}>
                                            {item?.icon && <img className={scss.icon} src={item.icon} alt="" />}
                                            {item?.name}
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    ) : isFetched ? (
                        <Filters
                            changeFilters={changeFilters}
                            setLastSelectedFilterKey={setLastSelectedFilterKey}
                            lastSelectedFilterKey={lastSelectedFilterKey}
                            setChangeFilters={setChangeFilters}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            availableFilters={availableFilters}
                            isLoaded={isFetched}
                            setNewQuery={setNewQuery}
                            newQuery={newQuery}
                            categoryid={categoryid}
                            setCurrentPage={setCurrentPage}
                        />
                    ) : (
                        <Loading />
                    )}

                    {/* Show subcategories of the selected category */}
                    {selectedCategory && <Subcategories selectedCategory={selectedCategory} activateCategory={activateCategory} onActivateCategory={activateCategory} />}
                </>
            )}

            {/*{activeCategory && (*/}
            {/*    <>*/}
            {/*        /!* Current category *!/*/}
            {/*        <div className={scss.activeCategory}>*/}
            {/*            <Link to={`/pocetna`} className={`text-decoration-none text-white`}>*/}
            {/*                <Icon>arrow_back</Icon>*/}
            {/*                <div>*/}
            {/*                    {__.Catalogue.Category.Category}:<br />*/}
            {/*                    <strong>{activeCategory.name}</strong>*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*        </div>*/}

            {/*        /!* Filter products *!/*/}
            {/*    </>*/}
            {/*)}*/}
        </div>
    );
};

export default CatalogueAside;
