import useAPI from "hooks/useAPI";
import { GlobalQueries } from "hooks/useRefreshQuery";
import { useQuery } from "react-query";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import NotificationHolder from "./NotificationHolder";

const NotificationsModal = ({ openModal, setOpenModal }) => {
    const api = useAPI();
    // Load all notifications
    const { data: notifications } = useQuery([GlobalQueries.Notifications], () => api.list(`/notifications`));

    return (
        <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Notifikacije" back={true}>
            {notifications?.items.map((notification) => (
                <NotificationHolder notification={notification} key={notification.id} />
            ))}
        </ModalWrapper>
    );
};

export default NotificationsModal;
