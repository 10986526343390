import Button from "components/Buttons/Button/Button";
import FiltersModal from "components/layout/MobileDialogs/FiltersModal/FiltersModal";
import { useEffect, useState } from "react";
import boxesImg from "../../../../assets/images/icons/boxes.png";
import listImg from "../../../../assets/images/icons/list2.png";
import __ from "../../../../config/translations/serbian";
import UserPreferences from "../../../../helpers/UserPreferences";
import scss from "./Controls.module.scss";

/**
 * Show controls for the presentation of the products.
 *
 * @param {?string} title The optional title to show, or null to hide the controls.
 * @param {function({ limit: int, view: string, order: string })} onParamChange Invoked when any of the parameters change.
 *
 * @return {JSX.Element}
 * @constructor
 */

const Controls = ({
    showFilters = true,
    title,
    onParamChange,
    filters,
    setActiveFilters,
    numberOfProducts,
    setSort,
    categoryid,
    sort,
    setLimit,
    limit,
    setCurrentPage,
    changeFilters,
    isFetched,
    lastSelectedFilterKey,
    setChangeFilters,
    setLastSelectedFilterKey,
    newQuery,
    setNewQuery,
    selectedFilters,
    availableFilters,
    setSelectedFilters,
}) => {
    const [openFilters, setOpenFilters] = useState(false);

    // Send updated values to the parent
    const updateParent = () => onParamChange && onParamChange({ ready: true, ...UserPreferences.get("presentation") });
    useEffect(updateParent, []);
    // Local state: The number of products to show
    // const [limit, setLimit] = useState(UserPreferences.get("presentation.limit"));
    // useEffect(() => UserPreferences.set("presentation.limit", limit) && updateParent(), [limit]);

    // Local state: The order of the products
    const [order, setOrder] = useState(UserPreferences.get("presentation.order"));
    useEffect(() => UserPreferences.set("presentation.order", order) && updateParent(), [order]);

    const [view, setView] = useState(UserPreferences.get("presentation.view"));
    useEffect(() => UserPreferences.set("presentation.view", view) && updateParent(), [view]);

    const optionsArray = [
        { value: { field: "price", direction: "asc" }, label: __.Catalogue.Controls.ByPriceAscending },
        { value: { field: "price", direction: "desc" }, label: __.Catalogue.Controls.ByPriceDescending },
        { value: { field: "new", direction: "desc" }, label: "Novo" },
        { value: { field: "new", direction: "asc" }, label: "Staro" },
        { value: { field: "name", direction: "asc" }, label: "Po nazivu, rastuće" },
        { value: { field: "name", direction: "desc" }, label: "Po nazivu, opadajuće" },
        { value: { field: "inventory", direction: "asc" }, label: "Na stanju rastuće" },
        { value: { field: "inventory", direction: "desc" }, label: "Na stanju opadajuće" },
    ];

    const limitArray = [
        { value: 4, label: 4 },
        { value: 8, label: 8 },
        { value: 12, label: 12 },
        { value: 18, label: 18 },
        { value: 24, label: 24 },
    ];
    return (
        <>
            <div className={scss.wrapper + " col-12"}>
                <span className={scss.title}>{title}</span>
                <span className={`${scss.number} desktop-hidden`}>{numberOfProducts && `Ukupno proizvoda: ${numberOfProducts}`}</span>
                <div className={scss.presentationControl}>
                    {showFilters && (
                        <div className={scss.filters}>
                            <Button className={scss.filtersButton} onClick={() => setOpenFilters(!openFilters)} label="Filteri" />
                        </div>
                    )}
                    {/* Order */}
                    <div
                        className={scss.control}
                        style={{
                            gridColumn: showFilters ? "span 1" : "span 2",
                        }}
                    >
                        <select
                            style={{
                                height: showFilters ? "100%" : "200%",
                            }}
                            className={scss.select}
                            onChange={(e) => {
                                setSort([
                                    {
                                        field: "has_image",
                                        direction: "desc",
                                    },
                                    {
                                        field: e.target.value.split(",")[0],
                                        direction: e.target.value.split(",")[1],
                                    },
                                ]);
                                setCurrentPage(1);
                            }}
                            value={`${sort[1]?.field},${sort[1]?.direction}`}
                        >
                            {optionsArray.map((option, index) => (
                                <option key={index} value={`${option.value.field},${option.value.direction}`}>
                                    {option.label}
                                </option>
                            ))}
                        </select>

                        {/* <img src={icons.sort} alt={icons.sort} height="20rem" /> */}
                    </div>

                    {/* Limit */}
                    <div className={`${scss.control} mobile-hidden`}>
                        <select
                            defaultValue={limit}
                            value={limit}
                            className={scss.select}
                            onChange={(e) => {
                                setLimit(e.target.value);
                                setCurrentPage(1);
                            }}
                        >
                            {limitArray.map((option, index) => (
                                <option key={index} value={option.value}>
                                    Vidljivo: {option.label}
                                </option>
                            ))}
                        </select>

                        {/* <img src={icons.view} alt={icons.view} height="20rem" /> */}
                    </div>

                    {/* View */}
                    <div className={scss.control}>
                        <div className={view === ProductsView.Grid ? scss.activeIcon : scss.inactiveIcon}>
                            <button className={scss.iconButton} onClick={() => setView(ProductsView.Grid)}>
                                <img src={boxesImg} alt={boxesImg} />
                            </button>
                        </div>

                        <div className={view === ProductsView.List ? scss.activeIcon : scss.inactiveIcon}>
                            <button className={scss.iconButton} onClick={() => setView(ProductsView.List)}>
                                <img src={listImg} alt={listImg} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <FiltersModal
                openModal={openFilters}
                setOpenModal={setOpenFilters}
                filters={filters}
                setActiveFilters={setActiveFilters}
                changeFilters={changeFilters}
                isFetched={isFetched}
                lastSelectedFilterKey={lastSelectedFilterKey}
                setChangeFilters={setChangeFilters}
                setLastSelectedFilterKey={setLastSelectedFilterKey}
                newQuery={newQuery}
                selectedFilters={selectedFilters}
                availableFilters={availableFilters}
                setSelectedFilters={setSelectedFilters}
                setCurrentPage={setCurrentPage}
                categoryid={categoryid}
                setNewQuery={setNewQuery}
            />
        </>
    );
};

/** The list of possible views for the list of products. */
export const ProductsView = {
    Grid: "grid",
    List: "list",
};

/** The list of possible number of products per page. */
export const ProductsLimit = {
    _4: 4,
    _8: 8,
    _12: 12,
    _18: 18,
    _24: 24,
};

/** The list of possible order variations. */
export const ProductsOrder = {
    ASC: "asc",
    DESC: "desc",
};

/**
 * The default controls for the presentation of products.
 *
 * @typedef PresentationControls
 *      @property {int} limit
 *      @property {string} view
 *      @property {string} order
 */
export const DefaultProductsControls = {
    page: 1,
    view: ProductsView.Grid,
    limit: ProductsLimit._8,
    order: ProductsOrder.DESC,
};

export default Controls;
