import { Link } from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from "@mui/material/CardActionArea";

const CardBlock = ({ title = "", value = "", icon, background }) => {

  return (
    <Link to={'/pocetna'}>
      <Card sx={{ boxShadow: "none", backgroundColor: "var(--croonus-lightGray)" }}>
        <CardActionArea>
          <CardContent sx={{
            position: "relative",
            backgroundImage: `url("https://image.freepik.com/free-photo/river-foggy-mountains-landscape_1204-511.jpg")`,
            backgroundSize: '10rem',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '15rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
            <Typography gutterBottom variant="h5">
              Naslov
            </Typography>
            <Typography variant="body2">
              Naziv stranice
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default CardBlock;
