import SidebarList from "../../../components/layout/WithFixedSidebar/SidebarList/SidebarList";
import __ from "../../../config/translations/serbian";

import scss from "./NotificationsList.module.scss";
import scssSide from "../../../components/layout/WithFixedSidebar/SidebarList/SidebarList.module.scss";

/**
 * Show the list of all notifications.
 *
 * @param {PayloadListNotifications[]} notifications The notifications to show.
 * @param {PayloadListNotifications} selected The currently selected notification.
 * @param {boolean} isLoading True to suggest that the notifications are still loading.
 * @param {function(id: int)} onSelectedId Invoked when an item is selected.
 *
 * @return {JSX.Element}
 * @constructor
 */
const NotificationsList = ({ notifications, selected, isLoading, onSelected }) => {

  // Get the class names for the item
  const getClassNames = (notification: PayloadListNotifications): string => {
    const classNames = []

    // Unread notification
    if (!notification.seen_date) {
      classNames.push(scss.unread)
    }

    // Active
    if (notification.id === selected?.id) {
      classNames.push(scssSide.active)
    }

    return classNames.join(" ")
  }

  // Show unread icon
  const unreadIcon = (notification: PayloadListNotifications) => !notification.seen_date
    ? <em className={scss.unreadIcon} title={__.Notifications.Messages.ThisIsANewMessage}>*</em>
    : null

  return !isLoading && (
    <SidebarList>
      {(notifications ?? []).map(notification => (
        <div key={notification.id} className={getClassNames(notification)} onClick={() => onSelected(notification)}>
          {notification.title}
          {unreadIcon(notification)}
        </div>
      ))}
    </SidebarList>
  )
}

export default NotificationsList
