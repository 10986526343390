import { useEffect, useRef, useState } from "react";
import scss from "../FormWrapper.module.scss";
import styles from "./FormInput.module.scss";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import useAPI from "hooks/useAPI";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ButtonBase from "@mui/material/ButtonBase";
import Input from "@mui/material/Input";
import { toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { blobToData } from "helpers/data";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

export const InputWrapper = ({ children = null, label, required, disabled, margin = "dense", error = null, fullWidth = true, styleFormControl, columns }) => {
    return (
        <FormControl fullWidth={fullWidth} margin={margin} error={error !== null} sx={styleFormControl} className={`col-sm-${columns ? (columns === true ? 6 : columns) : 12}`}>
            <FormLabel required={required} disabled={disabled} sx={{ fontFamily: "Montserrat", color: "var(--text-color)" }}>
                {label}
            </FormLabel>
            {children}
        </FormControl>
    );
};

export const FormWrapper = ({ label, field, required, columns, children, className }) => (
    <div key={field} className={`${className} ${scss.input} col-sm-${columns ? (columns === true ? 6 : columns) : 12}`}>
        {/* Optional label */}
        {label != null && (
            <label htmlFor={field} className={required ? scss.required : ""}>
                {label}:
            </label>
        )}

        {children}
    </div>
);

export const FormInput = ({ label, data, setData, field, placeholder, type = "text", rows, required, disabled, columns, className, helperText }) => (
    <FormWrapper label={label} required={required} columns={columns} className={className}>
        {type !== "textarea" ? (
            <input
                id={field}
                type={type}
                style={{
                    fontSize: "16px",
                }}
                value={data[field] ?? ""}
                placeholder={placeholder}
                onChange={(event) => setData((data) => ({ ...data, [field]: type !== "checkbox" ? event.target.value : event.target.checked }))}
                disabled={disabled}
            />
        ) : (
            <textarea
                id={field}
                style={{
                    fontSize: "16px",
                }}
                rows={rows}
                value={data[field] ?? ""}
                placeholder={placeholder}
                onChange={(event) => setData((data) => ({ ...data, [field]: event.target.value }))}
                disabled={disabled}
            />
        )}
        {helperText ? <p style={{ margin: "0", fontSize: "0.75rem" }}>{helperText}</p> : null}
    </FormWrapper>
);

export const FormSelect = ({ className,label, data, setData, field, options = [], required, disabled, columns, updateCountryId, setUpdateCountryId }) => (
    <FormWrapper label={label} required={required} columns={columns}>
        <select
            className={styles.select}
            style={className}
            id={field}
            value={data[field] ?? ""}
            onChange={(event) => {
                setData((data) => ({ ...data, [field]: event.target.value }));
                label === "Država" && setUpdateCountryId(event.target.value);
            }}
            disabled={disabled}
        >
            {options.map((option, index) => (
                <option key={option?.id ?? option[0] ?? index} value={option?.id ?? option[0]}>
                    {option?.name ?? option[1]}
                </option>
            ))}
        </select>
    </FormWrapper>
);

export const FormSelectSearch = ({ label, data, setData, field, options = [], required, disabled, columns }) => {
    const [value, setValue] = useState("");
    const change = (evt) => {
        setValue(evt.target.value);
    };

    return (
        <FormWrapper label={label} required={required} columns={columns}>
            <input list={field} disabled={disabled} value={data[field] ?? ""} onChange={change} />
            <datalist
                id={field}
                onChange={(event) => {
                    setData((data) => ({ ...data, [field]: event.target.value }));
                    // label === "Država" && setUpdateCountryId(event.target.value);
                }}
            >
                {options.map((option) => (
                    <option key={option?.id ?? option[0]} value={option?.name ?? option[1]} />
                ))}
            </datalist>
        </FormWrapper>
    );
};

export const AutocompleteInput = ({
    label,
    required,
    disabled,
    error = null,
    name,
    value,
    margin = "dense",
    onChange = () => {},
    description,
    fillFromApi,
    usePropName,
    options,
    queryString = "",
    optionsIsEmpty = () => {},
    styleFormControl,
    columns,
    className,
}) => {
    const api = useAPI();
    const [opt, setOpt] = useState([]);
    const [myValue, setMyValue] = useState(null);
    const filter = createFilterOptions();
    useEffect(() => {
        let isMounted = true;
        let path = usePropName ? `${fillFromApi}/${name}?${queryString}` : `${fillFromApi}?${queryString}`;
        const fillDdl = async () => {
            await api
                .get(path, false)
                .then((response) => {
                    if (isMounted) {
                        const { payload } = response;
                        setOpt(payload);
                    }
                })
                .catch((error) => {
                    console.warn(error);
                });
        };

        if (fillFromApi) {
            fillDdl();
        }

        return () => {
            isMounted = false;
        };
    }, [fillFromApi, queryString]);

    return (
        // <InputWrapper label={label} required={required} disabled={disabled} margin={margin} error={error} styleFormControl={styleFormControl} columns={columns}>
        <FormWrapper label={label} required={required} columns={columns} className={className}>
            <Autocomplete
                disabled={disabled}
                value={value}
                onChange={(event, newInputValue) => {
                    let newIval = newInputValue ? newInputValue : "";
                    setMyValue(newIval);
                    if (opt.length > 0) {
                        let selectedOption = opt.find((o) => o.name === newInputValue);
                        if (selectedOption) {
                            newIval = selectedOption.id;
                        }
                    }
                    onChange(name, newIval);
                }}
                options={options?.length > 0 ? options : opt}
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== "" && !isExisting) {
                        filtered.push({
                            inputValue,
                            name: `Dodaj: "${inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                }}
                sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0, border: 0 },
                    ".MuiInputBase-root": { padding: "0!important" },
                    ".MuiAutocomplete-input": { padding: "0.5rem 1rem!important", fontFamily: "Montserrat" },
                    ".MuiSvgIcon-root": { fill: "inherit" },
                    ".MuiOutlinedInput-notchedOutline": "none",
                    "&.MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--primary-color)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--primary-color)",
                        borderRadius: "0.625rem",
                    },
                }}
                renderInput={(params, param) => <TextField {...params} />}
                freeSolo={true}
            />
            <FormHelperText sx={{ fontFamily: "Montserrat" }}>{error ? error : description}</FormHelperText>
        </FormWrapper>
    );
};

export const InputCheckbox = ({
    label,
    required,
    disabled,
    name,
    value,
    error = null,
    margin = "dense",
    onChange = () => null,
    description,
    labelStyle,
    styleCheckbox,
    columns,
    styleCheckBoxWrapp,
    className,
}) => {
    return (
        // <InputWrapper required={required} disabled={disabled} margin={margin} error={error} styleFormControl={styleCheckBoxWrapp}>
        <FormWrapper required={required} columns={columns} className={className}>
            <FormControlLabel
                control={<Checkbox name={name} checked={value} onChange={onChange} disabled={disabled} sx={styleCheckbox} />}
                label={label}
                sx={{
                    ".MuiTypography-root": { fontFamily: "Montserrat", fontSize: "0.9rem", lineHeight: "normal" },
                    ".MuiSvgIcon-root": { width: "1.1rem", height: "1.1rem", fill: "var(--primary-color)" },
                    ...labelStyle,
                }}
            />
            <FormHelperText>{error ? error : description}</FormHelperText>
        </FormWrapper>
    );
};

export const FilePicker = ({
    label,
    required,
    disabled,
    margin,
    error = null,
    onFilePicked = () => {},
    description,
    selectedFile,
    styleFormControl,
    columns,
    className,
    multipleFileSelection = false,
    handleRemoveFile = () => {},
}) => {
    const ref = useRef();
    const [attachment, setAttachment] = useState(null);

    const handleChange = (event) => {
        const files = Array.from(event.target.files);
        const [file] = files;
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (
            fileExtension !== "mp4" &&
            fileExtension !== "mov" &&
            fileExtension !== "avi" &&
            fileExtension !== "wmv" &&
            fileExtension !== "flv" &&
            fileExtension !== "mkv" &&
            fileExtension !== "webm" &&
            fileExtension !== "txt" &&
            fileExtension !== "pdf" &&
            fileExtension !== "png" &&
            fileExtension !== "jpg" &&
            fileExtension !== "jpeg" &&
            fileExtension !== "gif" &&
            fileExtension !== "doc" &&
            fileExtension !== "docx" &&
            fileExtension !== "xls" &&
            fileExtension !== "xlsx" &&
            fileExtension !== "zip" &&
            fileExtension !== "rar" &&
            fileExtension !== "mp3" &&
            fileExtension !== "ogg"
        ) {
            toast.error("Pogrešan tip fajla.");
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            // 5: This is the size limit you want to set, which is 5 megabytes.
            // 1024: There are 1024 bytes in 1 kilobyte (KB). This is the conversion from megabytes to kilobytes.
            // 1024: There are 1024 kilobytes in 1 megabyte (MB). This is another conversion factor, from kilobytes to megabytes.
            // When you multiply these factors together, you get the maximum file size in bytes
            // 5 (MB) * 1024 (KB/MB) * 1024 (B/KB) = 5,242,880 bytes
            toast.error("Maksimalna dozvoljena veličina fajla je 5 MB.");
            return;
        }

        blobToData(file).then((result) => {
            let obj = {
                base_64: result,
                name: file?.name,
            };
            onFilePicked(obj);
        });

        const reader = new FileReader();
        reader.onload = (e) => {
            const base64 = e.target.result;
            const obj = {
                base_64: base64,
                name: file?.name,
            };
            onFilePicked(obj);
        };
        reader.readAsDataURL(file);
        setAttachment(file);
    };

    const renderSelectedFiles = () => {
        if (multipleFileSelection) {
            return (
                selectedFile && (
                    <span style={{ fontSize: "0.9rem", WebkitTextFillColor: disabled ? "rgba(0, 0, 0, 0.38)" : "initial", display: "flex" }}>
                        Izabrani fajlovi:
                        {selectedFile.length > 0
                            ? selectedFile.map((file, index) => {
                                  return (
                                      <Box key={file.name} sx={{ marginLeft: index === 0 ? "0.3rem" : "0" }}>
                                          {index > 0 && ", "}
                                          {file.name}
                                      </Box>
                                  );
                              })
                            : " Kliknite ovde kako biste odabrali fajl."}
                    </span>
                )
            );
        } else {
            return selectedFile ? (
                <span style={{ fontSize: "0.9rem", WebkitTextFillColor: disabled ? "rgba(0, 0, 0, 0.38)" : "initial" }}>Izabrani fajl: {selectedFile.name}</span>
            ) : (
                <span style={{ fontSize: "0.9rem", WebkitTextFillColor: disabled ? "rgba(0, 0, 0, 0.38)" : "initial" }}>Kliknite ovde kako biste odabrali fajl.</span>
            );
        }
    };

    const renderRemoveFilesList = () => {
        if (multipleFileSelection) {
            return selectedFile?.map((item, i) => (
                <span style={{ fontSize: "0.9rem", WebkitTextFillColor: disabled ? "rgba(0, 0, 0, 0.38)" : "initial", display: "flex", alignItems: "center", marginRight: "0.8rem" }}>
                    {item?.name}
                    <CloseIcon sx={{ fontSize: "0.9rem", cursor: "pointer", marginLeft: "0.2rem" }} onClick={() => handleRemoveFile(item)} />
                </span>
            ));
        }
    };

    return (
        // <InputWrapper label={label} required={required} disabled={disabled} margin={margin} error={error} styleFormControl={styleFormControl} columns={columns}>
        <FormWrapper label={label} required={required} columns={columns} className={className}>
            <ButtonBase
                component="label"
                sx={{
                    height: "2.844rem",
                    borderColor: error ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
                    borderRadius: "0.65rem",
                    justifyContent: "start",
                    paddingLeft: "0.875rem",
                    backgroundColor: disabled ? "rgba(0, 0, 0, 0.12)" : "var(--croonus-lightGray)",
                }}
            >
                <Input type="file" onChange={handleChange} inputRef={ref} disabled={disabled} error={error !== null} sx={{ display: "none" }} multiple />
                {renderSelectedFiles()}
            </ButtonBase>
            <Box sx={{ display: "flex" }}>{renderRemoveFilesList()}</Box>

            <FormHelperText>{error ? error : description}</FormHelperText>
        </FormWrapper>
    );
};

export const InputDateTime = ({ label, required, disabled, error = null, name, value, margin = "dense", onChange = () => null, description, columns, className }) => {
    const handleChange = (newValue) => {
        const ev = {
            target: {
                name: name,
                value: newValue,
            },
        };
        onChange(ev, "date_time");
    };

    return (
        // <InputWrapper label={label} required={required} disabled={disabled} margin={margin} error={error} columns={columns}>
        <FormWrapper label={label} required={required} columns={columns} className={className}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    value={value !== "" ? value : null}
                    onChange={handleChange}
                    ampm={false}
                    showToolbar
                    disabled={disabled}
                    format="dd/MM/yyyy HH:mm"
                    sx={{
                        ".MuiInputBase-root": { fontFamily: "Montserrat" },
                        fieldset: { border: 0, borderColor: error ? "#d32f2f" : "rgba(0, 0, 0, 0.23)", borderRadius: "0.65rem" },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                                ".MuiInputBase-input": { fontSize: "0.9rem", padding: "0.7rem" },
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
            <FormHelperText>{error ? error : description}</FormHelperText>
        </FormWrapper>
    );
};
