import { initStore } from './store';

const filterStore = () => {
    const actions = {
        FILTER: async (curState, newState) => {
            return { filter: newState };
        }
    };

    initStore(actions, {
        filter: {
            slug: "",
            sort: null,
            minPrice: null,
            maxPrice: null,
            productsPerPage: 8,
            activePage: 1,
            categoryIds: null
        }
    });
};

export default filterStore;