import { useNavigate } from "react-router-dom";

import SidebarList from "components/layout/WithFixedSidebar/SidebarList/SidebarList";
import WithFixedSidebar from "components/layout/WithFixedSidebar/WithFixedSidebar";
import icons from "helpers/icons";
import Sitemap from "routes/sitemap";
import __ from "../../config/translations/serbian";

import scss from "../../components/layout/WithFixedSidebar/SidebarList/SidebarList.module.scss";

const ContactPage = ({ children }) => {
  const navigate = useNavigate();

  // List of notifications in the sidebar
  const options = [
    [Sitemap.contact._, __.Contact.Mail],
    [Sitemap.contact.chat, __.Contact.Chat],
  ];

  // Build the sidebar
  const sidebarList = (
    <SidebarList>
      {options.map((option) => (
        <div key={option[0]} className={option[0] === window.location.pathname ? scss.active : ""} onClick={() => navigate(option[0])}>
          {option[1]}
        </div>
      ))}
    </SidebarList>
  );

  return (
    <WithFixedSidebar header={__.Contact.MenuTitle} icon={icons.contact} sidebar={sidebarList}>
      {children}
    </WithFixedSidebar>
  );
};

export default ContactPage;
