import scss from "./Buttons.module.scss"

/**
 * A simple wrapper for the buttons under some form.
 *
 * @param {"left"|"center"|"right"} align Where to align the buttons.
 * @param {string} className The class name to use.
 * @param {JSX.Element} children The buttons to render within.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Buttons = ({ align = "center", className, children }) => (
    <div className={`${scss[align]} ${scss.buttons} ${className}`}>
        {children}
    </div>
)

export default Buttons
