import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams, useLocation, useSearchParams } from "react-router-dom";

import MainCard from "../../components/layout/MainCard/MainCard";
import __ from "../../config/translations/serbian";
import useArray from "../../hooks/useArray";
import CatalogueAside from "../Catalogue/Aside/CatalogueAside";
import Products from "../Catalogue/Products/Products";
import useAPI from "hooks/useAPI";

/**
 * The main page that shows multiple products.
 */
const ProductSearch = () => {
    const { searchTerm } = useParams();
    const { pathname } = useLocation();
    const api = useAPI();
    // The available and active filters
    const [activeFilters, setActiveFilters] = useArray([]);
    const [availableFilters, setAvailableFilters] = useState();
    // The category we are searching for
    const [activeCategory, setActiveCategory] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [changeFilters, setChangeFilters] = useState(false);
    let title = activeCategory?.name ?? "Izdvajamo";
    let categoryId = activeCategory?.id ?? 0;
    let search = "";
    const [sort, setSort] = useState([
        {
            field: "has_image",
            direction: "desc",
        },
        {
            field: "new",
            direction: "desc",
        },
    ]);
    const [limit, setLimit] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);

    const [newQuery, setNewQuery] = useState();
    const url = new URL(window.location.href);
    useEffect(() => {
        const updateQuery = () => {
            const filterQuery = selectedFilters
                .map((item) => {
                    if (item?.column && item?.value?.selected?.length > 0) {
                        const selectedValues = item.value.selected.map((value) => encodeURIComponent(value)).join("_");
                        return `${encodeURIComponent(item.column)}=${selectedValues}`;
                    }
                    return null;
                })
                .filter(Boolean)
                .join("&");

            const sortQuery = sort[0]?.field && sort[0]?.direction ? `${selectedFilters?.length === 0 ? "" : "&"}sort=${sort[0].field}_${sort[0].direction}` : "";
            const pageQuery = currentPage ? `&page=${currentPage}` : "";
            const limitQuery = limit ? `&limit=${limit}` : "";
            setNewQuery(filterQuery || sortQuery || pageQuery || limitQuery ? `?${filterQuery}${sortQuery}${pageQuery}${limitQuery}` : "");
        };

        updateQuery();
    }, [selectedFilters, sort, currentPage, limit]);

    const { data: products, refetch } = useQuery(["CataloguePage.Products"], () => {
        return api.list(`/products/search/list`, {
            filters: selectedFilters,
            search: searchTerm,
            sort: sort,
            page: currentPage,
            limit: limit,
        });
    });

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const extractFiltersFromQuery = async () => {
            const filtersURL = url?.search?.slice(1, url?.search?.length);
            const splittedFiltersURL = filtersURL?.split("&");
            //filter out page= and sort= from filtersURL
            const splittedFiltersURL2 = splittedFiltersURL?.filter((item) => {
                return !item.includes("page=") && !item.includes("sort=") && !item.includes("limit=");
            });

            const sortQuery = searchParams?.get("sort");
            const page = searchParams?.get("page");
            const limitURLQuery = searchParams?.get("limit");
            const sortArray = sortQuery?.split("_") ?? [];
            const sortObject = {
                field: sortArray[0] ?? "",
                direction: sortArray[1] ?? "",
            };
            if (filtersURL) {
                const filterObject = splittedFiltersURL2?.map((filter) => {
                    const [column, value] = (filter ?? "").split("=");
                    const selected = (value ?? "").split("_").map((val) => {
                        return column === "p-r|cena" ? Number(decodeURI(val)) : decodeURIComponent(val);
                    });

                    return {
                        column: decodeURIComponent(column),
                        value: {
                            selected: selected,
                        },
                    };
                });

                setSelectedFilters(filterObject);
                setSort([
                    {
                        field: "has_image",
                        direction: "desc",
                    },
                    {
                        field: sortObject?.field ?? "",
                        direction: sortObject?.direction ?? "",
                    },
                ]);
                setCurrentPage(page);
                setLimit(limitURLQuery ?? 8);

                await products.refetch({
                    filters: filterObject,
                    sort: sortObject,
                    page: page,
                    limit: limitURLQuery,
                });
                setTimeout(async () => {
                    await basic.refetch({
                        filters: filterObject,
                    });
                    setChangeFilters(true);
                }, 1000);
            }

            //ukljuciti kada bude imala paginacija
            // if (page) {
            //   setPage(page);
            // }
        };
        const showButton = document.getElementById("showButton");
        if (showButton) {
            setTimeout(() => {
                showButton.click();
            }, 1000);
        }
        extractFiltersFromQuery();
    }, []);
    useEffect(() => {
        if (newQuery) {
            const path = `/katalog/pretraga/${search}${newQuery}`;

            window.history.replaceState(null, "", path);
        }
    }, [newQuery]);
    const [lastSelectedFilterKey, setLastSelectedFilterKey] = useState("");

    const {
        data: filters,
        isFetched,
        ...basic
    } = useQuery(
        ["CataloguePage.Filters"],
        () => {
            return api
                .post(`/products/search/filters`, {
                    filters: selectedFilters,
                    search: searchTerm,
                    sort: sort,
                    page: currentPage,
                    limit: limit,
                })
                .then((res) => {
                    setAvailableFilters(res);
                    return res;
                });
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        const refetchData = async () => {
            await basic.refetch();
        };

        refetchData();
    }, [pathname]);

    useEffect(() => {
        if (changeFilters) {
            basic
                .refetch({
                    filters: selectedFilters ?? [],
                })
                .then((response) => {
                    const lastSelectedFilterValues = selectedFilters?.find((item) => {
                        return item?.column === lastSelectedFilterKey;
                    });
                    const lastSelectedFilter = availableFilters?.find((item) => {
                        return item?.key === lastSelectedFilterKey;
                    });

                    const filterLastSelectedFromResponse = response?.data?.filter((item) => {
                        return item?.key !== lastSelectedFilterKey;
                    });

                    const indexOfLastSelectedFilter = availableFilters?.findIndex((index) => {
                        return index?.key === lastSelectedFilterKey;
                    });

                    if (lastSelectedFilter && lastSelectedFilterValues?.value?.selected?.length > 0) {
                        setAvailableFilters([
                            ...filterLastSelectedFromResponse.slice(0, indexOfLastSelectedFilter),
                            lastSelectedFilter,
                            ...filterLastSelectedFromResponse.slice(indexOfLastSelectedFilter),
                        ]);
                    } else {
                        setAvailableFilters(response?.data);
                    }
                });
            setChangeFilters(false);
        }
    }, [changeFilters, basic, selectedFilters]);

    // The used search term
    if (searchTerm) {
        title = `${__.Catalogue.Search}: "${searchTerm}"`;
        categoryId = 0;
        search = searchTerm;
    }

    return (
        <MainCard>
            {/* The list of available categories */}

            <CatalogueAside
                // activeCategory={activeCategory}
                // setActiveCategory={setActiveCategory}
                availableFilters={availableFilters}
                setLastSelectedFilterKey={setLastSelectedFilterKey}
                lastSelectedFilterKey={lastSelectedFilterKey}
                setActiveFilters={setActiveFilters}
                isFetched={isFetched}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
                changeFilters={changeFilters}
                setChangeFilters={setChangeFilters}
            />

            <Products
                title={title}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                url={`/products/search/list`}
                banners={false}
                search={search}
                filters={activeFilters}
                availableFilters={availableFilters}
                setActiveFilters={setActiveFilters}
                setAvailableFilters={setAvailableFilters}
                sort={sort}
                setSort={setSort}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setNewQuery={setNewQuery}
                newQuery={newQuery}
                setLimit={setLimit}
                limit={limit}
            />
        </MainCard>
    );
};

export default ProductSearch;
