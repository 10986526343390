import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import Loading from "../Loading/Loading";
import Buttons from "../Buttons/Buttons/Buttons";
import ButtonLoading from "components/Buttons/Button/Button";
import LoadingWrapper from "../Loading/LoadingWrapper";

/**
 * Ask for confirmation when deleting a record.
 *
 * @param {{show: bool, i: int, mutate: *}} openDeleteDialog Additional details for the dialog..
 * @param {string} title The title for the dialog, or null for the default title.
 * @param {string} description The description to show to the user, or null for the default message.
 * @param {function} handleConfirm Callback that will handle confirmation.
 * @param {function} setOpenDeleteDialog Call to change the state of the dialog in the parent.
 *
 * @return {JSX.Element}
 * @constructor
 */

const DeleteDialog = ({
  openDeleteDialog,
  title,
  description,
  handleConfirm,
  setOpenDeleteDialog,
  nameOfButtonDelete,
  deafultIcon = true,
  children,
  setSelectedProducts = () => { },
  selectedProducts = [],
  getAllProducts = () => { },
  products = [],
  loading = false,
  loadingwidth = "",
  showDeleteButton = true,
  nameOfButtonCancel
}) => {
  return (
    <Dialog open={openDeleteDialog.show ?? false} maxWidth={"md"}>
      <DialogTitle sx={{ fontFamily: "Montserrat" }}>{title ?? "Brisanje"}</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ fontFamily: "Montserrat" }}>{description ?? "Da li ste sigurni da želite da obrišete ovaj zapis?"}</DialogContentText>
        {children}
      </DialogContent>

      <DialogActions>
        {/*toggle add all and delete all buttons*/}
        {products && (
          <Button
            sx={{ fontFamily: "Montserrat", fontWeight: "normal" }}
            color="success"
            style={{ marginRight: "auto" }}
            variant="outlined"
            className={`${products?.length === 0 && `d-none`}`}
            onClick={() => {
              if (selectedProducts?.length > 0) {
                setSelectedProducts([]);
              } else {
                getAllProducts(products);
              }
            }}
            data-test-id="btn-cancel"
          >
            {selectedProducts?.length > 0 ? "Izbaci sve proizvode" : "Označi sve proizvode"}
          </Button>
        )}
        <Button
          sx={{ fontFamily: "Montserrat", fontWeight: "normal" }}
          color="success"
          variant="outlined"
          onClick={() => {
            setOpenDeleteDialog({ ...openDeleteDialog, show: false });
            setSelectedProducts([]);
          }}
          data-test-id="btn-cancel"
        >
          {nameOfButtonCancel ?? "Odustani"}
        </Button>
        {showDeleteButton &&
          <Button
            sx={{ fontFamily: "Montserrat", fontWeight: "normal", backgroundColor: "var(--croonus-lightRed)", "&:hover": { backgroundColor: "var(--croonus-darkRed)" } }}
            variant="contained"
            disabled={loading}
            startIcon={deafultIcon ? <Icon>delete</Icon> : null}
            onClick={handleConfirm}
            data-test-id="btn-confirm"
          >
            <LoadingWrapper color={"#FFFFFF"} size={23} className={`p-0 ${loadingwidth}`} isLoading={loading}>
              {nameOfButtonDelete ?? "Obriši"}
            </LoadingWrapper>
          </Button>
        }

      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
