import { Box } from "@mui/material";
import LoadingWrapper from "components/Loading/LoadingWrapper";
import useAPI from "hooks/useAPI";
import useRefreshQuery, { GlobalQueries } from "hooks/useRefreshQuery";
import { useQuery } from "react-query";

import styles from "./NotificationsModal.module.scss";

const NotificationBody = ({ id }) => {
    const api = useAPI();
    // Load all notifications
    const { data: notification, isLoading } = useQuery([`Notification.${id}`], () => api.get(`/notifications/${id}`));

    // Refresh the global notifications
    useRefreshQuery(GlobalQueries.Notifications, notification);

    return (
        <LoadingWrapper isLoading={isLoading} size={50}>
            {notification && (
                <Box className={styles.notificationBody}>
                    <div className={styles.time}>{notification.created_at}</div>

                    {/* The message to show */}
                    <div className={styles.message}>{notification.message}</div>

                    {/* Optional action button */}
                    {notification.button && (
                        <a className={styles.button} target="_blank" rel="noreferrer" href={notification.link}>
                            {notification?.button}
                        </a>
                    )}
                </Box>
            )}
        </LoadingWrapper>
    );
};

export default NotificationBody;
