import { useState, useEffect } from "react";
import { RangeSlider } from "rsuite";
import classes from "./Filter.module.css";
import { Link } from "react-router-dom";
import "./rslider.css";

const Filter = ({
    filter,
    selectedFilters,
    setSelectedFilters,
    categoryData,
    changeFilters,
    setChangeFilters,
    setTempSelectedFilters,
    tempSelectedFilters,
    setCurrentPage = () => {},
    lastSelectedFilterKey,
    setLastSelectedFilterKey,
}) => {
    const changeHandler = (data) => {
        let tmp = [...selectedFilters];
        const filtered = tmp.filter((item) => item.column === data.column);
        if (data.value.selected.length === 0) {
            if (filtered.length > 0) {
                const index = tmp.indexOf(filtered[0]);
                tmp.splice(index, 1);
            }
        } else {
            if (filtered.length > 0) {
                tmp = tmp.map((item) => (item.column === data.column ? data : item));
            } else {
                tmp.push(data);
            }
        }

        setSelectedFilters([...tmp]);
        setChangeFilters(true);
        setLastSelectedFilterKey(data.column);
        setCurrentPage(1);
    };

    let selected = selectedFilters.filter((item) => item.column === filter.key)[0];
    selected = selected ? selected.value.selected : [];

    switch (filter.type) {
        case "range":
            return <FilterRange filter={filter} onChange={changeHandler} selected={selected} />;
        case "in":
            return <FilterIn filter={filter} onChange={changeHandler} selected={selected} />;
        case "within_tree":
            return <FilterWithinTree filter={filter} />;
    }
};

export default Filter;

const FilterIn = ({ filter, onChange = () => {}, selected, changeFilterOptions }) => {
    const [filterNumber, setFilterNumber] = useState(3);
    const numFiltersLoaded = Math.min(filterNumber, filter?.params?.items.length);
    const allFiltersLoaded = numFiltersLoaded === filter?.params?.items.length;
    const handleFilterNumber = () => {
        setFilterNumber(filterNumber + 3);
    };
    const checkedChanged = ({ target }) => {
        if (target.checked) {
            if (!selected.includes(target.value)) {
                const tmp = [...selected, target.value];

                onChange({
                    column: filter?.params?.use_field ? filter[filter?.params?.use_field] : filter.key,
                    value: { selected: tmp },
                });
            }
        } else {
            const tmp = [...selected];
            var index = tmp.indexOf(target.value);
            if (index !== -1) {
                tmp.splice(index, 1);
            }

            onChange({
                column: filter.key,
                value: { selected: tmp },
            });
        }
    };
    return (
        <>
            {(filter?.params?.items ?? []).map((item) => (
                <>
                    <div key={item.name} className="mt-2 flex flex-row items-center gap-2 pl-4 text-[0.775rem]">
                        <input
                            type="checkbox"
                            className="h-4 w-4 rounded-sm bg-croonus-1  text-croonus-3 focus:ring-0"
                            name={item.label}
                            checked={selected.includes(filter?.params?.use_field ? item[filter?.params?.use_field] : item.key)}
                            onChange={checkedChanged}
                            value={filter?.params?.use_field ? item[filter?.params?.use_field] : item.key}
                            id={"chbx-" + item?.id}
                        />
                        <label className="text-[0.875rem] leading-[1.625rem]" htmlFor={"chbx-" + item.id}>
                            {item.label}
                        </label>
                    </div>
                </>
            ))}
        </>
    );
};

const FilterRange = ({ filter, onChange, selected }) => {
    const [selectedValue, setSelectedValue] = useState(selected.length === 2 ? selected : [Number(filter.params.min), Number(filter.params.max)]);
    const onRangeChange = (data) => {
        onChange({
            column: filter?.params?.use_field ? filter[filter?.params?.use_field] : filter.key,
            value: { selected: data },
        });
    };

    useEffect(() => {
        if (selected.length !== 2) setSelectedValue([Number(filter.params.min), Number(filter.params.max)]);
    }, [selected, filter.params]);

    return (
        <>
            <div className={`relative w-75 mx-auto`}>
                <RangeSlider
                    min={Number(filter.params.min)}
                    max={Number(filter.params.max)}
                    value={selectedValue}
                    defaultValue={[Number(filter.params.min), Number(filter.params.max)]}
                    onChange={(value) => {
                        setSelectedValue(value);
                    }}
                    onChangeCommitted={onRangeChange}
                />
            </div>
            <div className={`${classes.valueHolder} max-md:text-center mt-3`}>
                {selectedValue && (
                    <>
                        <span>od: {Number(selectedValue[0])}</span>
                        <span> do: {Number(selectedValue[1])}</span>
                    </>
                )}
            </div>
        </>
    );
};

const FilterWithinTree = ({ filter }) => {
    return (
        <>
            {(filter?.params?.items ?? []).map((item) => (
                <>
                    <div key={item.id} className={`mt-2 flex flex-row items-center gap-2 pl-4 text-[0.775rem] ${classes.link}`}>
                        <Link className={`text-[0.875rem] leading-[1.625rem] text-decoration-none`} htmlFor={"chbx-" + item.id} to={`/katalog/kategorija/${item?.slug}`}>
                            {item.label}
                        </Link>
                    </div>
                </>
            ))}
        </>
    );
};
