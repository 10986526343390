import { Box } from "@mui/system";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import LinkList from "./LinkList";

import styles from "./LinkList.module.scss";

const LinkChildren = ({ link, setOpenModal }) => {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(pathname.includes(link.to));

    return (
        <Box>
            <Box
                onClick={() => {
                    setOpen(!open);
                }}
                className={`${styles.link} ${pathname.includes(link.to) && styles.active}`}
            >
                {link.icon && (
                    <Box className={styles.linkIcon}>
                        <img src={link.icon} alt="" />
                    </Box>
                )}
                <Box className={styles.linkLabel}>{link.label}</Box>
            </Box>
            {open && (
                <Box className={styles.linkChildren}>
                    <LinkList links={link.children} setOpenModal={setOpenModal} />
                </Box>
            )}
        </Box>
    );
};

export default LinkChildren;
