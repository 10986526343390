import { Navigate } from "react-router-dom";
import ContactForm from "pages/Contact/ContactForm/ContactForm";
import ForgotPasswordPage from "pages/FrogotPassword/ForgotPasswordPage";
import AddressPage from "pages/Profile/Addresses/AddressPage";
import DebtPage from "pages/Profile/Debt/DebtPage";
import CartPage from "../pages/Cart/CartPage";
import CataloguePage from "../pages/Catalogue/CataloguePage";
import CheckoutPage from "../pages/Checkout/CheckoutPage";
import LoginPage from "../pages/Login/LoginPage";
import NotificationsPage from "../pages/Notification/NotificationsPage";
import OrdersDetailsPage from "../pages/OrderDetails/OrdersDetailsPage";
import OrdersPage from "../pages/Orders/OrdersPage";
import ProductPage from "../pages/Product/ProductPage";
import AddressesPage from "../pages/Profile/Addresses/AddressesPage";
import CompanyProfilePage from "../pages/Profile/CompanyProfilePage/CompanyProfilePage";
import MyProfilePage from "../pages/Profile/MyProfilePage/MyProfilePage";
import RegistrationPage from "../pages/Registration/RegistrationPage";
import WishlistPage from "../pages/Wishlist/WishlistPage";
import Sitemap from "./sitemap";
import ModuleLocked from "pages/ModuleLocked/ModuleLocked";
import CheckoutSuccess from "pages/CheckoutSuccess/CheckoutSuccess";
import Chat from "pages/Contact/Chat/Chat";
import AnalyticCardPage from "pages/AnanlyticCard/AnalyticCardPage";
import HomePage from "../pages/Homepage/HomePage";
import ProductSearchPage from "../pages/ProductSearch/ProductSearch";
import PromoList from "../pages/PromoList/PromoList";
import PromoDetails from "../pages/PromoDetails/PromoDetails";
import NewPage from "../pages/New/NewPage";
import Pages from "../pages/Pages/Pages";
// import ActionPage from "../pages/Action/ActionPage";
import Reclamations from "pages/Profile/Reclamations/Reclamations";
import ReclamationsDetails from "pages/Profile/Reclamations/ReclamationsDetails/ReclamationsDetails";
import StaticPagesProfile from "pages/Profile/StaticPagesProfile/StaticPagesProfile";
import NewReclamation from "pages/Profile/Reclamations/NewReclamation/NewReclamation";

/** The routes for the user that successfully logged in. */
export const authorizedRoutes = [
    // [Sitemap.profile.edit, <UserProfileChangesPage />],
    [Sitemap.startPage, <HomePage />],
    [Sitemap.home, <HomePage />],

    [Sitemap.profile._, <MyProfilePage />],
    [Sitemap.profile.company_profile, <CompanyProfilePage />],
    [Sitemap.profile.address, <AddressPage />],
    [Sitemap.profile.addresses, <AddressesPage />],
    [Sitemap.profile.debt, <DebtPage />],
    [Sitemap.profile.reclamations, <Reclamations />],
    [Sitemap.profile.reclamationsDetails, <ReclamationsDetails />],
    [Sitemap.profile.reclamationsForm, <NewReclamation />],
    [Sitemap.profile.staticPage + "/:slug", <StaticPagesProfile />],

    [Sitemap.static.manual, <ModuleLocked />],
    [Sitemap.static.analyticCard, <AnalyticCardPage />],
    [Sitemap.static.reportProblem, <ModuleLocked />],
    [Sitemap.static.faq, <ModuleLocked />],
    [Sitemap.static.agreement, <ModuleLocked />],
    [Sitemap.static.pages, <Pages />],
    [Sitemap.orders._, <OrdersPage />],
    [Sitemap.orders.details, <OrdersDetailsPage />],
    //[Sitemap.catalogue._, <CataloguePage/>],
    [Sitemap.catalogue.search + "/:searchTerm", <ProductSearchPage />],
    [Sitemap.catalogue.productDetails + "/:productId", <ProductPage />],
    [Sitemap.catalogue.category + "/*", <CataloguePage />],
    [Sitemap.notifications._, <NotificationsPage />],
    [Sitemap.wishlist._, <WishlistPage />],
    [Sitemap.cart._, <CartPage />],
    [Sitemap.cart.success + "/:orderId", <CheckoutSuccess />],
    [Sitemap.cart.checkout, <CheckoutPage />],
    [Sitemap.contact._, <ContactForm />],
    [Sitemap.contact.chat, <Chat />] /*
    [Sitemap.any, <Navigate replace to={Sitemap.catalogue._} />], */,
    [Sitemap.promo._, <PromoList />],
    [Sitemap.promo.details, <PromoDetails />],
    [Sitemap.new.list, <NewPage />],
    // [Sitemap.action.list, <ActionPage />],
];

/** The routes for the user that is not logged in yet. */
export const unauthorizedRoutes = [
    [Sitemap.auth.login, <LoginPage />],
    [Sitemap.auth.register, <RegistrationPage />],
    [Sitemap.auth.passwordReset, <ForgotPasswordPage />],
    [Sitemap.any, <Navigate replace to={Sitemap.auth.login} />],
];
