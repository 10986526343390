import Box from '@mui/material/Box';

const MediaPrint = ({ data }) => {
  const convertDate = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const dateObj = new Date(date);
    const formattedDate = dateObj
      .toLocaleString("sr-RS", options)
      .replace(/,/g, "")
      .replace(/(\d+)\D+(\d+)\D+(\d+)\D+(\d+):(\d+)/, "$1.$2.$3. $4:$5");
    return formattedDate;
  };
  return (
    <>
      <Box sx={{
        display: "none",
        gridTemplateColumns: "1fr 1fr",
        gap: "1rem",
        "@media print": {
          display: "grid",
          order: "-1 !important",
          marginBottom: "2rem"
        },
      }}>
        <Box>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Porudžbina:</span>
            {data?.order?.slug}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Datum porudžbine:</span>
            {convertDate(data?.order?.created_at)}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Naziv kompanije:</span>
            {data?.billing_address?.company_name}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Adresa kompanije:</span>
            {data?.billing_address?.address}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Grad:</span>
            {data?.billing_address?.town_name}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Poštanski broj:</span>
            {data?.billing_address?.zip_code}
          </p>
        </Box>
        <Box>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Država:</span>
            {data?.billing_address?.country_name}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>PIB:</span>
            {data?.billing_address?.pib}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>MB:</span>
            {data?.billing_address?.maticni_broj}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Robu poručio:</span>
            {data?.billing_address?.first_name + " " + data?.billing_address?.last_name}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Način plaćanja:</span>
            {data?.order?.payment_method_name}
          </p>
          <p style={{ fontSize: "0.8rem", marginBottom: "0.2rem" }}>
            <span style={{ marginRight: "0.3rem", fontWeight: "700" }}>Način dostave:</span>
            {data?.order?.delivery_method_name}
          </p>
        </Box>
      </Box>
    </>
  )
}

export default MediaPrint