import Box from '@mui/material/Box';
import Button from 'components/Buttons/Button/Button';
import Buttons from 'components/Buttons/Buttons/Buttons';
import { AutocompleteInput, FilePicker, FormInput, InputCheckbox, InputDateTime } from 'components/Form/FormInputs/FormInput';
import React, { useState } from 'react'
import scss from '../NewReclamation/NewReclamation.module.scss'
import useAPI from 'hooks/useAPI';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Divider from '@mui/material/Divider';


const AdditionalSection = ({ onInitDataChange = () => { }, index = -1, itemsArray = [] }) => {
  const api = useAPI();

  // const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueProduct, setSelectedValueProduct] = useState("");
  const [isIdNull, setIsIdNull] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [productSku, setProductSku] = useState("");
  const [productName, setProductName] = useState("");
  const [descriptionReclamatins, setDescriptionReclamatins] = useState({});
  const [optionsProducts, setOptionsProducts] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [basicDataOrderNumber, setBasicDataOrderNumber] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const apiPathNew = `/reclamations/new/${selectedValue}`;

  const formattedCheckedValues = isChecked.map((value) => ({
    id: value,
    name: value,
  }));

  const handleValueChange = (newValue) => {
    setSelectedValue(newValue);
  };

  const handleValueChangeProduct = (newValue) => {
    setSelectedValueProduct(newValue);
  }

  const submitHandlerReclamationsNewId = () => {
    api.get(apiPathNew)
      .then((response) => {
        if (response.id === null) {
          setIsIdNull(true);
          setCurrentStep(2);
          setPurchaseDate(null)
          setDescriptionReclamatins({});
          setSelectedValueProduct("");
        } else {
          setBasicDataOrderNumber(response)
          setIsIdNull(false);
          setCurrentStep(2);
          setPurchaseDate(new Date(response.created_at));
          setProductSku("");
          setProductName("");
          setDescriptionReclamatins({});
          setSelectedValueProduct("");
        }
        const { items } = response;
        let arr = [];
        items?.forEach((item, i) => {
          let count = 0;
          itemsArray?.forEach(it => {
            if (it?.id_product === item?.id_product) {
              count++;
            }
          });
          if (count < Number(item?.quantity)) {
            arr.push({ ...item });
          }
        })
        if (response?.id !== null) {
          if (arr.length > 0) {
            setOptionsProducts(arr);
            onInitDataChange(index, 'pretraga', { ...response, fiscal_clip: selectedValue });
          } else {
            onInitDataChange(index, 'nema_proizvoda', null);
          }
        } else {
          setOptionsProducts(arr);
          onInitDataChange(index, 'pretraga', { ...response, fiscal_clip: selectedValue });
        }
      })
      .catch((error) => {
        toast.warn("Morate uneti broj računa!")
      });
  };


  const handleRemoveClick = () => {
    setSelectedValue("");
    setIsIdNull(true);
    setCurrentStep(1);
    setPurchaseDate(null);
    setOptionsProducts([]);
    setBasicDataOrderNumber([]);
    setProductSku("");
    setProductName("");
    setDescriptionReclamatins({});
    setIsChecked([]);
    setSelectedFiles([]);
    onInitDataChange(index, 'ukloni', null);
  };

  const responsiveType = [
    {
      id: 1,
      name: "Otklanjanje nedostatka proizvoda",
    },
    {
      id: 2,
      name: "Zamena proizvoda",
    },
    {
      id: 3,
      name: "Povraćaj novca",
    },
  ]


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let isCheckedTemp = [...isChecked];
    if (checked) {
      isCheckedTemp.push(name);
    } else {
      isCheckedTemp = isCheckedTemp.filter((item) => item !== name);
    }
    setIsChecked(isCheckedTemp);
    onInitDataChange(index, "responsive_type", isCheckedTemp);
  };

  useEffect(() => {
    onInitDataChange(index, "product_sku", productSku?.product_sku === undefined ? null : productSku?.product_sku)
  }, [productSku]);

  useEffect(() => {
    onInitDataChange(index, "product_name", productName?.product_name === undefined ? null : productName?.product_name);
  }, [productName]);

  useEffect(() => {
    onInitDataChange(index, "description", descriptionReclamatins?.description === undefined ? "" : descriptionReclamatins?.description);
  }, [descriptionReclamatins]);

  return (
    <>
      <>
        <AutocompleteInput
          label={"Broj računa"}
          fillFromApi={"reclamations/new/ddl/order_numbers"}
          usePropName={false}
          columns={10}
          description={`Unesite broj računa i pritisnite dugme "Pretraga"`}
          required={true}
          value={selectedValue}
          disabled={currentStep === 2 ? true : false}
          onChange={(e, selectedValue) => {
            //Case 1: {id: 'B2B-2023-000002', name: 'B2B-2023-000002'}
            //Case 2: 123
            //Case 3: {inputValue: '123', name: 'Dodaj: "123"'}
            if (selectedValue === "" || selectedValue === null) {
              handleRemoveClick();
            } else {
              if (typeof selectedValue === "object") {
                if (selectedValue?.id === undefined) {
                  handleValueChange(selectedValue?.inputValue);
                } else {
                  handleValueChange(selectedValue?.name);
                }
              } else {
                handleValueChange(selectedValue)
              }
            }
          }}
        />
        <Buttons className={`${scss.buttonInfoAboutRec} col-sm-2`}>
          <Button
            label="Pretraga"
            onClick={submitHandlerReclamationsNewId}
            className={scss.buttonSearch}
            buttonStyleBasic={{ display: currentStep === 2 ? "none" : "flex" }}
          />
          {currentStep === 2 &&
            <Button label="Ukloni" onClick={handleRemoveClick} className={scss.buttonRemove} />
          }
        </Buttons>
      </>
      {currentStep === 2 && (
        <>
          <InputDateTime
            label={"Datum kupovine"}
            required={true}
            columns={4}
            className={scss.formInputInfoAboutRec}
            value={purchaseDate}
            disabled={!isIdNull}
            onChange={(e) => {
              setPurchaseDate(e.target.value);
              onInitDataChange(index, 'date', e.target.value);
            }}
          />

          {isIdNull ? (
            <>
              <FormInput
                label={"Šifra proizvoda"}
                field="product_sku"
                data={productSku}
                required={true}
                setData={setProductSku}
                className={scss.formInputInfoAboutRec}
                columns={4}
              />
              <FormInput
                label={"Naziv proizvoda"}
                field="product_name"
                data={productName}
                required={true}
                setData={setProductName}
                className={scss.formInputInfoAboutRec}
                columns={4}
              />
            </>
          ) : (
            <>
              <AutocompleteInput
                label={"Proizvod"}
                usePropName={false}
                columns={8}
                required
                onChange={(e, selectedValue) => {
                  handleValueChangeProduct(selectedValue);
                  onInitDataChange(index, "product", selectedValue);
                }}
                options={optionsProducts}
                className={scss.formInputInfoAboutRec}
              />
            </>
          )}
          <FormInput
            label={"Opis reklamacije"}
            data={descriptionReclamatins}
            setData={setDescriptionReclamatins}
            type="textarea"
            field={"description"}
            required={true}
            rows={4}
            className={scss.formInputInfoAboutRec}
            columns={12}
          />

          <Box sx={{ margin: "0.5rem 0 0.25rem 0" }}>
            <p style={{ marginBottom: "0" }}>
              U slučaju da Prodavac uvaži reklamaciju, Kupac ima pravo da bira između sledećih načina rešavanja reklamacije:
              {responsiveType && <span style={{ color: "var(--croonus-alertRed)", paddingLeft: "0.25em" }}>*</span>}
            </p>
            {responsiveType.map((item) => {
              return (
                <InputCheckbox
                  key={item.id}
                  label={item.name}
                  name={item.name}
                  value={isChecked.includes(item.name)}
                  onChange={handleCheckboxChange}
                  required={true}
                />
              )

            })}
          </Box>

          <FilePicker
            label="Odabir fajla"
            className={scss.formInputInfoAboutRec}
            selectedFile={selectedFiles}
            onFilePicked={(file) => {
              setSelectedFiles([...selectedFiles, file]);
              onInitDataChange(index, "files", [...selectedFiles, file]);
            }}
            multipleFileSelection={true}
            handleRemoveFile={(file) => {
              const updatedFiles = selectedFiles.filter(item => item.name !== file.name);
              setSelectedFiles(updatedFiles);
              onInitDataChange(index, "files", updatedFiles);
            }}
          />

          <Divider sx={{ display: itemsArray.length > 1 ? "block" : "none", margin: "1rem 0", borderColor: "var(--croonus-darkGray)", opacity: "1" }} />
        </>
      )}
    </>
  )
}

export default AdditionalSection