import { useState } from "react";

import Filter from "../../../../components/Filter/Filter";
import scss from "./styles.module.scss";

const Filters = ({
    selectedFilters,
    setSelectedFilters,
    availableFilters,
    changeFilters,
    setTempSelectedFilters,
    setChangeFilters,
    tempSelectedFilters,
    sort,
    setSort,
    setNewQuery,
    newQuery,
    categoryid,
    setCurrentPage,
    lastSelectedFilterKey,
    setLastSelectedFilterKey,
}) => {
    const [openIndex, setOpenIndex] = useState({ key: null });

    return (
        <>
            <div className="h-100 overflow-auto">
                {availableFilters?.map((filter, index) => {
                    const isOpen = openIndex.key === filter.key;
                    return (
                        <div
                            className="d-flex flex-column cursor-pointer py-3 select-none border-bottom border-light d-flex align-items-start p-lg-3 justify-content-between"
                            onClick={() =>
                                setOpenIndex({
                                    key: openIndex?.key === filter?.key ? null : filter?.key,
                                })
                            }
                            key={filter?.key}
                        >
                            <h1 className="h6 font-weight-bold">{filter?.name}</h1>

                            <div className="overflow-hidden w-100">
                                <div className={isOpen ? `mt-0 py-2 transition-all duration-750ms` : `-mt-52 py-2 transition-all duration-750ms`}>
                                    <Filter
                                        filter={filter}
                                        selectedFilters={selectedFilters}
                                        setTempSelectedFilters={setTempSelectedFilters}
                                        changeFilters={changeFilters}
                                        setChangeFilters={setChangeFilters}
                                        setSelectedFilters={setSelectedFilters}
                                        tempSelectedFilters={tempSelectedFilters}
                                        setCurrentPage={setCurrentPage}
                                        setLastSelectedFilterKey={setLastSelectedFilterKey}
                                        lastSelectedFilterKey={lastSelectedFilterKey}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div style={{ zIndex: 100, position: "sticky", bottom: "-20px", width: "100%" }}>
                <button
                    className={scss.button}
                    onClick={(event) => {
                        event?.preventDefault();
                        setSelectedFilters([]);
                        setChangeFilters(true);
                        setNewQuery();
                        if (newQuery) {
                            window.history.replaceState(null, "", `/katalog/kategorija/${categoryid}`);
                        }
                    }}
                >
                    {" "}
                    Poništi filtere
                </button>
            </div>
        </>
    );
};

export default Filters;
