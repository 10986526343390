const Input = (props) => {
    const inputElement = () => {
        const inputId = props.id ? props.id : Math.random();
        const errorVisible = props.inputErrorVisible !== undefined ? props.inputErrorVisible : true;

        const onChangeRound = (e) => {
            let value = e;
            value.target.value = value.target.value.replace(/[^\d]/, "");

            props.onInputChange(value);
        };

        switch (props.inputType) {
            case "input":
                return (
                    <>
                        <input
                            placeholder={props.placeHolder ? props.placeHolder : ""}
                            autoComplete={props.offAutoComplete ? "new-password" : ""}
                            value={props.inputValue}
                            onChange={props.onInputChange}
                            onBlur={props.onInputBlur}
                            id={inputId}
                            type={props.type ? props.type : "text"}
                            className={props.class ? props.class : ""}
                            disabled={props.disabled}
                        />
                        {errorVisible && <p className="error-text"> {props.hasInputError ? props.inputErrorText : ""} </p>}
                        {!errorVisible && <p className="error-text"></p>}
                    </>
                );
            case "input-number":
                return (
                    <>
                        <input
                            placeholder={props.placeHolder ? props.placeHolder : ""}
                            value={props.inputValue}
                            onChange={(e) => onChangeRound(e)}
                            onBlur={props.onInputBlur}
                            id={inputId}
                            type="number"
                            className={props.class ? props.class : ""}
                            disabled={props.disabled}
                            step={props.step}
                            min={props.min}
                        />
                    </>
                );
            default:
                return <h1>No input type match!</h1>;
        }
    };

    return inputElement();
};

export default Input;
