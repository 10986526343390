import { useQuery } from "react-query";
// import scss from "./StaticPagesProfile.module.scss";
import ProfilePage from "pages/Profile/ProfilePage";
import { useParams } from "react-router-dom";
import useAPI from "hooks/useAPI";
// import StaticPage from "components/StaticPage/StaticPage";
import StaticPageContent from "components/StaticPageContent/StaticPageContent";

const StaticPagesProfile = () => {
  const { slug } = useParams();
  const api = useAPI();
  const { data } = useQuery([`StaticPagesProfile.${slug}`], () => api.get(`static-pages/content/${slug}`));

  return (
    <ProfilePage>
      <h4 className="h-underline">{data?.basic_data?.name}</h4>
      {/* {data.content.length > 0 ? <StaticPageContent staticData={data?.content} /> : <StaticPage slug={"uslovi-koriscenja"} />} */}
      {data?.content.length > 0 && <StaticPageContent staticData={data?.content} />}
    </ProfilePage>
  );
};

export default StaticPagesProfile;
