import { useQuery, useQueryClient } from "react-query";
import LoadingWrapper from "../../../components/Loading/LoadingWrapper";
import __ from "../../../config/translations/serbian";
import useAPI from "../../../hooks/useAPI"
import useRefreshQuery, { GlobalQueries } from "../../../hooks/useRefreshQuery"
import scss from "./NotificationDetails.module.scss"

/**
 * Show the details of a single notification.
 *
 * @param {?int} notificationId The id of the notification to show.
 *
 * @return {JSX.Element}
 * @constructor
 */
const NotificationDetails = ({ notificationId }) => {
  const queryClient = useQueryClient()
  const api = useAPI()

  // Load all notifications
  const { data: notification, isLoading } = useQuery(
    [`Notification.${notificationId}`],
    () => api.get(`/notifications/${notificationId}`)
  )

  // Refresh the global notifications
  useRefreshQuery(GlobalQueries.Notifications, notification)

  const convertDate = (date) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    };

    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleString("sr-RS", options)
      .replace(/,/g, '')
      .replace(/(\d+)\D+(\d+)\D+(\d+)\D+(\d+):(\d+)/, '$1.$2.$3, $4:$5');
    return formattedDate;
  };


  return (
    <div className={scss.wrapper}>
      <LoadingWrapper isLoading={isLoading}>
        {notification && (
          <>
            <h2 onClick={() => {
              queryClient.invalidateQueries([GlobalQueries.NotificationCounter, GlobalQueries.Notifications])
                .then(refresh => console.log(refresh))
            }}>{notification.title}</h2>
            <time>{convertDate(notification.created_at)}</time>

            {/* The message to show */}
            <div className={scss.message}>{notification.message}</div>

            {/* Optional action button */}
            {notification.button && (
              <a className={scss.button} target="_blank" href={notification.link}>
                {notification?.button}
              </a>
            )}
          </>
        )}
      </LoadingWrapper>
    </div>
  )
}

export default NotificationDetails
