import styles from "./SearchBar.module.scss";
import searchImg from "../../assets/images/icons/search-icon.png";

const SearchBar = ({ search = "", setSearch = () => {}, onSubmit, page, setPage }) => {
    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <input
                type="text"
                placeholder="Unesite pojam za pretragu"
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                }}
            />
            {/*<button type="submit" className={styles.button}>*/}
            {/*    <img src={searchImg} alt="search" />*/}
            {/*</button>*/}
        </form>
    );
};

export default SearchBar;
