import { Icon } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import classes from "./PaginationUI.module.scss";
import { useNavigate } from "react-router-dom";

/**
 * UI component for pagination
 *
 * @author Aleksandar Ječmenić <aleksandar.jecmenic@croonus.com>
 */

const PaginationUI = ({ numberOfDisplayed, numberOfProducts, changePage, activePage, setPage = () => {}, page1, setCurrentPage1 }) => {
    // Total number of pages
    const numberOfPages = Math.ceil(numberOfProducts / numberOfDisplayed);

    // Currently displayed pages for checking the change of displayed products number
    const [currentlyDisplayed, setCurrentlyDisplayed] = useState(1);

    // Number of currently selected page
    const [currentPage, setCurrentPage] = useState(activePage);

    // Number of the first page
    const firstPage = 1;

    // Number of the last page
    const lastPage = numberOfPages;

    // List containing all pages
    let listOfPages = Array.from({ length: numberOfPages }, (_, i) => i + 1);

    // First visible page button number
    const [firstVisiblePage, setFirstVisiblePage] = useState(0);

    // Last visible page button number
    const [lastVisiblePage, setLastVisiblePage] = useState(0);

    const buttonRef = useRef();

    // Change current page to clicked page button
    const onClickChangePage = (page) => {
        buttonRef.current.blur();
        setCurrentPage(page);
        // Set the page number to parent
        changePage(page);
        setPage(page);
        setCurrentPage1(page);
    };

    // Get category products when filter or page change
    useEffect(() => {
        if (activePage) {
            setCurrentPage(activePage);
        }
    }, [activePage]);

    useEffect(() => {
        // Check if number of displayed products has changed and reset the current page to page 1
        if (numberOfDisplayed !== currentlyDisplayed) {
            if (currentlyDisplayed > 0) {
                setCurrentPage(activePage);
            }
            setCurrentlyDisplayed(numberOfDisplayed);
        }
        if (numberOfPages < 5) {
            setFirstVisiblePage(1);
            setLastVisiblePage(5);
        } else if (currentPage === firstPage || currentPage === firstPage + 1) {
            setFirstVisiblePage(firstPage);
            setLastVisiblePage(firstPage + 4);
        } else if (currentPage === lastPage || currentPage === lastPage - 1) {
            setFirstVisiblePage(lastPage - 4);
            setLastVisiblePage(lastPage);
        } else {
            setFirstVisiblePage(currentPage - 2);
            setLastVisiblePage(currentPage + 2);
        }
    }, [currentPage, numberOfPages]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 32 && buttonRef.current !== null) {
                const currentButtonIndex = listOfPages.indexOf(currentPage);
                if (currentButtonIndex < listOfPages.length - 1) {
                    const nextButtonIndex = currentButtonIndex + 1;
                    const nextButtonPage = listOfPages[nextButtonIndex];
                    onClickChangePage(nextButtonPage);
                    buttonRef.current.blur();
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentPage, listOfPages, onClickChangePage]);

    return (
        <>
            {numberOfProducts > 0 && (
                <div className={classes["pagination-container"] + " row"}>
                    <p className={classes["pagination-items-count"]}>
                        {"Prikazano " +
                            (numberOfDisplayed - (numberOfDisplayed - 1) + numberOfDisplayed * (currentPage - 1)) +
                            " - " +
                            Math.min(numberOfDisplayed * currentPage, numberOfProducts) +
                            " od " +
                            numberOfProducts}
                    </p>
                    <div className={classes["pagination-number-container"]}>
                        {currentPage !== firstPage && (currentPage !== firstPage) + 1 && lastPage > 5 && currentPage > 3 && currentPage !== firstPage + 2 && (
                            <button className={classes["pagination-navigate"]} type="button" onClick={() => onClickChangePage(firstPage)}>
                                <Icon>skip_previous</Icon>
                            </button>
                        )}

                        {currentPage !== firstPage && (
                            <button className={classes["pagination-navigate"]} type="button" onClick={() => onClickChangePage(Math.max(activePage - 1))}>
                                <Icon>chevron_left</Icon>
                            </button>
                        )}

                        {listOfPages.slice(firstVisiblePage - 1, lastVisiblePage).map((page) => (
                            <button
                                ref={buttonRef}
                                className={currentPage === page ? classes["pagination-button-selected"] : classes["pagination-button"]}
                                onClick={() => {
                                    onClickChangePage(page);
                                    setCurrentPage1(page);
                                }}
                                type="button"
                                key={page}
                            >
                                {page}
                            </button>
                        ))}

                        {currentPage !== lastPage && numberOfProducts > 0 && (
                            <button className={classes["pagination-navigate"]} type="button" onClick={() => onClickChangePage(activePage + 1)}>
                                <Icon>chevron_right</Icon>
                            </button>
                        )}

                        {currentPage !== lastPage && numberOfProducts > 0 && lastPage > 5 && currentPage !== lastPage - 1 && currentPage !== lastPage - 2 && (
                            <button className={classes["pagination-navigate"]} type="button" onClick={() => onClickChangePage(lastPage)}>
                                <Icon>skip_next</Icon>
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PaginationUI;
