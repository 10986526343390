/**
 * Convert string to number.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {string} text
 * @returns {number}
 */
import { isNotEmpty } from "./data"

export const convertStringToNumber = (text) => {
    return +text
}

/**
 * If supplied value is a whole number, discard the decimals.
 *
 * @param {number} value The original value.
 *
 * @return {string} The resulting value, as a string.
 */
export const simpleFloat = (value: number): string => {

    // If the float is the same as int, round it
    if (value - Math.floor(value) === 0) {
        return Math.floor(value) + ""
    }

    return value + ""
}

/**
 * Format number in currency.
 *
 * @param {number} amount The value to format as a price.
 * @param {string|false|null} currency The currency to show or false to skip.
 * @param {boolean} showFractions True to show fraction digits, false othwerwise.
 *
 * @returns {string} The formatted price, with currency suffix.
 */
export const currencyFormat = (amount, currency = "rsd", showFractions = true) => {

    // Guard: no amount
    if (amount == null || isNaN(amount)) {
        return "-"
    }

    // Number of digits to show after the decimal places
    const decimalDigits = showFractions ? 2 : 0

    // Summarize options
    const options = {
        minimumFractionDigits: decimalDigits,
        maximumFractionDigits: decimalDigits
    }

    // Format to two decimal places
    const price = new Intl.NumberFormat("de-DE", options).format(amount)
    const currencyLabel = currency?.toLocaleUpperCase() ?? ""

    return `${price} ${currencyLabel}`.trim()
}

/**
 * Format quantity.
 *
 *  @param {number} quantity The value to format as a quantity.
 *
 * @returns {string} The formatted quantity.
 */
export const quantityFormat = (quantity: number): string | number => {

    // Remove decimals is not needed
    // noinspection EqualityComparisonWithCoercionJS
    if (quantity == Math.floor(quantity)) {
        quantity = Math.round(quantity)
    }

    return quantity
}

/**
 * Create path for category filter.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {Object} data - data for filters
 * @param {String} currentSlug - category slug
 * @returns {String}
 */
export const createSlugForCategoryFilter = (data, currentSlug) => {
    const dataKeys = ["activePage", "items_per_page", "order_by_price", "min_price", "max_price", "category_ids"]
    let navigateUrl = ""
    navigateUrl = "/kategorija/" + currentSlug + "?filter_submited"
    for (let item of dataKeys) {
        if (data[item] || data[item] === 0) {
            navigateUrl = navigateUrl + "&" + item + "=" + data[item]
        }
    }

    return navigateUrl
}

/**
 * Get data from slug.
 *
 * @author Predrag Lević <predrag.levic@croonus.com>
 * @param {String} currentSlug - url for filters
 * @returns {Object}
 */
export const getDataFromSlug = (currentSlug) => {
    const dataKeys = ["activePage", "items_per_page", "order_by_price", "min_price", "max_price", "category_ids"]
    const data = {}
    const sp = new URLSearchParams(currentSlug.search)
    if (currentSlug.search.length > 0) {
        for (let item of dataKeys) {
            if (sp.has(item)) {
                data[item] = sp.get(item)
            }
        }
        data["slug"] = currentSlug.pathname
    } else {
        data["slug"] = currentSlug.pathname
    }

    return data
}

/**
 * Make sure that an action is performed only once, when the calls for debounce stop.
 *
 * @param {string} key A key that uniquely represents the
 * @param {number} timeout The timeout to wait for the user input before invoking the callback.
 * @param {function} callback The action to invoke.
 */
export const debounce = (key: string, timeout: number, callback: function) => {

    // Make sure the debounce is globally accessible
    window.debounceStorage = window.debounceStorage ?? {}

    // Cancel the previous callback
    window.debounceStorage[key] && clearTimeout(window.debounceStorage[key])

    // Invoke the callback in the future
    window.debounceStorage[key] = setTimeout(callback, timeout)
}

/**
 * Join an array of strings into a single one, but ignore empty ones.
 *
 * @param {string[]} items The list of items to join.
 * @param {string} glue The glue to use when joining items.
 *
 * @return {string} The resulting string.
 */
export const join = (items: [], glue: string = " "): string =>
    items.filter(isNotEmpty).join(glue)
