import Button from "components/Buttons/Button/Button";
import { regax } from "helpers/const";
import useAPI from "hooks/useAPI";
import { useEffect } from "react";
import { useState } from "react";

import styles from "./ForgotPassword.module.scss";

import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Loading from "components/Loading/Loading";

const FrogotPasword = ({ openDialog, setOpenDialog, enteredEmail }) => {
  const api = useAPI();
  const [emailValue, setEmailValue] = useState(enteredEmail);
  const [message, setMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (openDialog) {
      setEmailValue(enteredEmail);
    } else {
      setIsSent(false);
      setIsLoading(false);
      setMessage(null);
    }
  }, [openDialog, enteredEmail]);

  const submitHandler = () => {
    let mail = emailValue.trim();
    setIsLoading(true);
    if (mail !== "" && regax.test(mail)) {
      api.post("customer/forgot-password", { email: mail })
        .then((response) => {
          setIsLoading(false);
          setMessage(response.message);
          setIsSent(true);
        })
        .catch((error) => {
          console.warn(error);
          setMessage("Došlo je do greške molimo pokušajte ponovo!");
          setIsLoading(false);
        });
    } else {
      setMessage("Email nije validan.");
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
      fullWidth
    >
      <DialogTitle>Zaboravili ste lozinku?</DialogTitle>
      <DialogContent>
        <div className={styles.contentContainer}>
          <p>Ukoliko ste zaboravili Vašu lozinku, potrebno je da unesete Vaš E-mail, na koji ćemo Vam proslediti link sa daljim uputstvom za promenu lozinke.</p>

          {!isLoading ? (
            <>
              {!isSent && (
                <>
                  <span>E-mail:</span>
                  <input
                    type="text"
                    value={emailValue}
                    onChange={(e) => {
                      setEmailValue(e.currentTarget.value);
                    }}
                    className="form-control"
                  />
                </>
              )}
              {message && <span>{message}</span>}
            </>
          ) : (
            <Loading size={50} />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {!isSent && <Button label="Pošalji zahtev" onClick={submitHandler} />}

        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
          label="Zatvori"
        />
      </DialogActions>
    </Dialog>
  );
};

export default FrogotPasword;
