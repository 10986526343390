import ModuleLocked from "pages/ModuleLocked/ModuleLocked";
import ContactPage from "../ContactPage";

const Chat = () => {
  return (
    <ContactPage>
      <ModuleLocked />
    </ContactPage>
  );
};

export default Chat;
