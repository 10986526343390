import Sticker from "./Sticker";
import styles from "./Stickers.module.scss";

const Stickers = ({ stickers }) => {
    return (
        <div className={styles.stickers}>
            {stickers?.map((sticker) => (
                <Sticker key={Math.random()} sticker={sticker} />
            ))}
        </div>
    );
};

export default Stickers;
