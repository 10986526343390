import CheckoutItem from "./CheckoutItem";

const MobileCheckout = ({ items, cost, showImage = true }) => {
  return (
    <div>
      {items.map((item) => (
        <CheckoutItem key={item.product.id} item={item} showImage={showImage} />
      ))}
    </div>
  );
};

export default MobileCheckout;
