import user_profile from "../assets/images/icons/profile.png";
import order from "../assets/images/icons/shopping-list.png";
import analytics from "../assets/images/icons/financial-card.png";
import reclamations from "../assets/images/icons/complaints.png";
import privacy_policy from "../assets/images/icons/privacy.png";
import report_problem from "../assets/images/icons/service.png";
import manual from "../assets/images/icons/manual.png";
import contact from "../assets/images/icons/contact.png";
import faq from "../assets/images/icons/question-and-answer.png";
import agreement from "../assets/images/icons/agreement.png";
import notifications from "../assets/images/icons/notification.png";
import catalogue from "../assets/images/icons/category.png";
import wish_list from "../assets/images/icons/wishlist.png";
import cart from "../assets/images/icons/shopping-cart2.png";
import sort from "../assets/images/icons/arrows_up_down.png";
import view from "../assets/images/icons/label.png";
import search from "../assets/images/icons/search-icon.png";
import checked from "../assets/images/icons/checklist.png";
import remove from "../assets/images/icons/remove.png";
import logout from "../assets/images/icons/off_on.png";
import suitcase from "../assets/images/icons/suitcase.png";
const icons = {
    user_profile,
    order,
    analytics,
    reclamations,
    privacy_policy,
    report_problem,
    manual,
    contact,
    faq,
    agreement,
    notifications,
    catalogue,
    wish_list,
    cart,
    sort,
    view,
    search,
    checked,
    remove,
    logout,
    suitcase,
};

export default icons;
