import scss from "./SidebarHeader.module.scss";

/**
 * Show a header in the sidebar.
 *
 * @param {string} title Tht title to show.
 * @param {?[icon: string, tooltip: string, trigger: function()]} action Additional action to add in the header.
 *
 * @return {JSX.Element}
 * @constructor
 */
const SidebarHeader = ({ title, action = null, icon }) => (
    <div className={scss.wrapper}>
        <h5 className={scss.title}>
            {title}
            {action && <i className={`fa fa-${action[0]} ${scss.action}`} title={action[1]} onClick={action[2]} />}
        </h5>
        {icon && (
            <div className={scss.imgHolder}>
                <img src={icon} alt="" />
            </div>
        )}
    </div>
);

export default SidebarHeader;
