import { useNavigate } from "react-router-dom";

import SidebarList from "../../components/layout/WithFixedSidebar/SidebarList/SidebarList";
import WithFixedSidebar from "../../components/layout/WithFixedSidebar/WithFixedSidebar";
import __ from "../../config/translations/serbian";
import icons from "helpers/icons";

import scss from "../../components/layout/WithFixedSidebar/SidebarList/SidebarList.module.scss";
import useAPI from "hooks/useAPI";
import { useQuery } from "react-query";

/**
 * Show the options regarding the profile.
 *
 * @return {JSX.Element}
 * @constructor
 */
const ProfilePage = ({ children }) => {
    const navigate = useNavigate();
    const api = useAPI();
    const { data: profilePageList } = useQuery(["ProfilePage"], () => api.get(`/profile/menu/left-menu`));
    // Build the sidebar
    const sidebarList = (
        <SidebarList>
            {profilePageList &&
                profilePageList.map((option) => (
                    <div key={option.route} className={option.route === window.location.pathname ? scss.active : ""} onClick={() => navigate(option.route)}>
                        {option.label}
                    </div>
                ))}
        </SidebarList>
    );

    return (
        <WithFixedSidebar header={__.Profile.MenuTitle} icon={icons.user_profile} sidebar={sidebarList}>
            {children}
        </WithFixedSidebar>
    );
};

export default ProfilePage;
