import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MainCard from "components/layout/MainCard/MainCard";
import __ from "../../config/translations/serbian";
import CardBlock from "./CardBlock/CardBlock";
import CatalogueAside from "../Catalogue/Aside/CatalogueAside";

import Box from "@mui/material/Box";

const Pages = () => {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeCategory, setActiveCategory] = useState(null);
  const navigateToCategory = (category) => {
    navigate(pathname === "/pocetna" ? "/pocetna" : "/katalog/kategorija" + pathname === "/pocetna" ? "" : "/" + category.slug);
    setActiveCategory(category);
  };

  // const api = useAPI();
  //
  // // Load the products to promote
  // const { data: products, isLoading } = useQuery(["Terms"], () => api.list("/products/section/list/recommendation", { limit: 5, order_price: "desc" }));
  return (

    <MainCard>
      <CatalogueAside
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        onActivateCategory={navigateToCategory}
      />
      <div className="page-container" style={{ paddingLeft: "21rem" }}>
        <h4 className="h-underline">{__.Pages.Title}</h4>
        <Box style={{ display: "grid", gridTemplateColumns: "repeat(6, 1fr)", gap: "1rem" }}>
          <CardBlock />
          <CardBlock />
          <CardBlock />
          <CardBlock />
        </Box>
      </div>
    </MainCard>

  );
};

export default Pages;
