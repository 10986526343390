import Button from "components/Buttons/Button/Button";
import MainCard from "components/layout/MainCard/MainCard";
import Sitemap from "routes/sitemap";
import background from "../../assets/images/backgrounds/module_locked.png";

import scss from "./ModuleLocked.module.scss";

const ModuleLocked = () => {
  return (
    <MainCard>
      <div className="page-container">
        <div className={`p page-content ${scss.container}`} style={{ backgroundImage: `url(${background})` }}>
          <h3 className="h-underline">Modul zaključan</h3>
          <p>
            Ulogovani korisnik nema dozvolu da koristi ovaj modul.
            <br />
            Za upotrebu ovog modula potrebno je da kontakirate Vašeg komercijalistu.
          </p>

          <p>Ukoliko vam je potrebna pomoć u svakom trenutku nas možete kontaktirati pozivom na broj call centra 0700/330-330 </p>
          <Button to={Sitemap.contact._} label="Pošalji poruku" />
        </div>
      </div>
    </MainCard>
  );
};

export default ModuleLocked;
