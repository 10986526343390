import { useEffect, useState } from "react";
import CreateForm from "components/Form/CreateForm/CreateForm";
import formFields from "./formFields.json";
import ProfilePage from "pages/Profile/ProfilePage";
import Buttons from "components/Buttons/Buttons/Buttons";
import Button from "components/Buttons/Button/Button";
import useAPI from "hooks/useAPI";
import { useQuery } from "react-query";
import { GlobalQueries } from "hooks/useRefreshQuery";
import Box from "@mui/material/Box";
import scss from "./NewReclamation.module.scss";
import { toast } from "react-toastify";
import AdditionalSection from "./AdditionalSection";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const NewReclamation = ({ reclamations }) => {
  const api = useAPI();
  const apiBasic = "reclamations/new/customer-data";

  const [loading, setLoading] = useState(false);

  const initItems =
  {
    id_order: null,
    order_slug: null,
    order_date: null,

    id_order_item: null,
    id_product: null,
    product_sinhro_id: null,

    fiscal_clip: null,
    fiscal_clip_date: null,

    product_sku: null,
    product_name: null,

    description: null,
    responsive_type: null,
    files: [],
  };

  const [itemsArray, setItemsArray] = useState([initItems]);

  const { data: companyDataBasic, isLoading: isCompanyDataBasicLoading } = useQuery([GlobalQueries.Profile.Reclamations], () => api.get(`${apiBasic}`));
  // Local state for the company
  const [company, setCompany] = useState();

  useEffect(() => {
    setCompany(companyDataBasic);
  }, [setCompany, companyDataBasic]);

  const submitHandlerValues = () => {
    // setLoading(true);
    api.post(`/reclamations/new`, { items: itemsArray, customer: company })
      .then((response) => {
        toast.success("Uspešno sačuvani podaci!");
        // setLoading(false);
      })
      .catch((error) => toast.warn("Greška"));
  }

  // order_date, product_name, product_sku, description -> id postoji
  // fiscal_clip, fiscal_clip_date, product_name, product_sku, description -> id ne postoji
  const addAdditionalSection = () => {
    let lastItem = itemsArray[itemsArray.length - 1];
    if (lastItem?.product_name && lastItem?.product_sku && lastItem?.description !== "" && lastItem?.responsive_type?.length > 0) {
      if (lastItem?.id_order) {
        if (lastItem?.order_date) {
          setItemsArray((prevSections) =>
            [...prevSections,
              initItems
            ]);
        } else {
          toast.warn("Molimo popunite sva obavezna polja.");
        }
      } else {
        if (lastItem?.fiscal_clip && lastItem?.fiscal_clip_date) {
          setItemsArray((prevSections) =>
            [...prevSections,
              initItems
            ]);
        } else {
          toast.warn("Molimo popunite sva obavezna polja.");
        }
      }
    } else {
      toast.warn("Molimo popunite sva obavezna polja.");
    }
  }

  return (
    <>
      <ProfilePage>
        <h4 className="h-underline">Unos nove reklamacije</h4>
        <Buttons align="right">
          <Button label="Nazad" to={"/reklamacije"} icon={<KeyboardBackspaceIcon />} />
        </Buttons>
        {
          company && (
            <CreateForm
              title="Podaci o kupcu"
              loading={loading}
              data={company}
              setData={setCompany}
              formFields={formFields}
              formButtons={false}
            />
          )
        }

        <Box sx={{ marginTop: "2rem" }} className={`row ${scss.wrapper}`}>
          <h5>Podaci o reklamaciji</h5>

          {itemsArray?.map((item, index) => {
            return <AdditionalSection
              index={index}
              item={item}
              itemsArray={itemsArray}
              onInitDataChange={(index, type, value) => {
                //type sta menjas
                // index koji element u nizu menjas
                // value vrednost elemnta koji menjas

                let arrOfState = [...itemsArray];
                let itemToChange = arrOfState[index];
                switch (type) {
                  case 'pretraga':
                    if (value?.id) {
                      itemToChange.id_order = value?.id;
                      itemToChange.order_slug = value?.slug;
                      itemToChange.order_date = value?.created_at;
                      itemToChange.fiscal_clip = null;
                      itemToChange.fiscal_clip_date = null;
                      itemToChange.product_sku = null;
                      itemToChange.product_name = null;
                    } else {
                      itemToChange.id_order = null;
                      itemToChange.order_slug = null;
                      itemToChange.order_date = null;
                      itemToChange.id_order_item = null;
                      itemToChange.id_product = null;
                      itemToChange.product_sinhro_id = null;
                      itemToChange.product_name = null;
                      itemToChange.product_sku = null;
                      itemToChange.fiscal_clip = value.fiscal_clip;
                    }
                    arrOfState[index] = itemToChange;
                    setItemsArray(arrOfState);
                    break;
                  case 'date':
                    itemToChange.fiscal_clip_date = value;
                    arrOfState[index] = itemToChange;
                    setItemsArray(arrOfState);
                    break;
                  case 'product_sku':
                    itemToChange.product_sku = value;
                    arrOfState[index] = itemToChange;
                    setItemsArray(arrOfState);
                    break;
                  case 'product_name':
                    itemToChange.product_name = value;
                    arrOfState[index] = itemToChange;
                    setItemsArray(arrOfState);
                    break;
                  case 'description':
                    itemToChange.description = value;
                    arrOfState[index] = itemToChange;
                    setItemsArray(arrOfState);
                    break;
                  case 'product':
                    const { id, id_product, sinhro_id, name, sku } = value;
                    itemToChange.id_order_item = id;
                    itemToChange.id_product = id_product;
                    itemToChange.product_sinhro_id = sinhro_id;
                    itemToChange.product_name = name;
                    itemToChange.product_sku = sku;
                    arrOfState[index] = itemToChange;
                    setItemsArray(arrOfState);
                    break;
                  case 'nema_proizvoda':
                    let arr = [...itemsArray];
                    setItemsArray(arr.slice(0, index));
                    toast.warn("Ne postoji proizvod za reklamaciju.");
                    break;
                  case 'responsive_type':
                    let mapped = value.map((item, i) => { return { id: item, name: item } })
                    itemToChange.responsive_type = mapped;
                    setItemsArray(arrOfState);
                    break;
                  case 'files':
                    let mappedFiles = value.map((item, i) => { return item?.base_64 });
                    itemToChange.files = [...mappedFiles];
                    setItemsArray(arrOfState);
                    break
                  case 'ukloni':
                    if (index === 0) {
                      let arr = [{ ...initItems }];
                      setItemsArray(arr);
                    } else {
                      let tempArr = [...itemsArray];
                      setItemsArray(tempArr.slice(0, index));
                    }
                    break;
                  default:
                    break;
                }
              }}
            />

          })}


          <Button label="Dodatna stavka reklamacije" onClick={addAdditionalSection} buttonStyleBasic={{ width: "100%", justifyContent: "center", marginTop: "2rem" }} />
          <Button label="Sačuvaj" onClick={submitHandlerValues} buttonStyleBasic={{ margin: "1rem 0 0 auto" }} />
        </Box>
      </ProfilePage >
    </>
  )
}

export default NewReclamation