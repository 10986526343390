import { Box } from "@mui/material";
import icons from "helpers/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sitemap from "routes/sitemap";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import styles from "./SearchModal.module.scss";
import { useQuery } from "react-query";
import useAPI from "../../../../hooks/useAPI";
import ProductListItem from "../../../../pages/Catalogue/Products/ProductItems/ProductListItem/ProductListItem";
import ProductGridItem from "../../../../pages/Catalogue/Products/ProductItems/ProductGridItem/ProductGridItem";
import { toast } from "react-toastify";
import Loading from "../../../Loading/Loading";

const SearchModal = ({ openModal, setOpenModal }) => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const api = useAPI();
    const submitHandler = (event) => {
        event.preventDefault();
        if (search.length >= 3) {
            navigate(Sitemap.catalogue.search + "/" + search);
            setSearch("");
            setOpenModal(false);
        } else {
            toast.error("Uneti najmanje 3 karaktera");
        }
    };

    const { data, isFetching } = useQuery(
        ["search", search],
        async () => {
            if (search?.length >= 3) {
                return await api
                    .list(`/products/search/list`, {
                        search: search,
                    })
                    .then((res) => res);
            }
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    return (
        <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Pretraga">
            <form
                onSubmit={submitHandler}
                className={styles.search}
                style={{
                    position: "relative",
                }}
            >
                <input type="text" placeholder="Pronađite proizvod" className={styles.searchInput} value={search} onChange={(e) => setSearch(e.target.value)} />
                <button type="submit" className={styles.searchButton} onClick={submitHandler}>
                    <img src={icons.search} alt="search" />
                </button>
            </form>
            <div>
                {search?.length >= 1 && search?.length < 3 && (
                    <span
                        style={{
                            color: "red",
                        }}
                    >
                        Uneti najmanje 3 karaktera
                    </span>
                )}
                {isFetching && <Loading size={`2rem`} />}
                {data?.items?.length === 0 && <p>Nema rezultata</p>}
                {data?.items?.map((product) => {
                    return <ProductGridItem product={product} />;
                })}
            </div>
        </ModalWrapper>
    );
};

export default SearchModal;
