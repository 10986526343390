import Button from "components/Buttons/Button/Button";
import Buttons from "components/Buttons/Buttons/Buttons";
import useAPI from "hooks/useAPI";
import { useEffect, useState } from "react";
import { FormInput, FormSelect, FormSelectSearch } from "../FormInputs/FormInput";
import FormWrapper from "../FormWrapper";
import LoadingWrapper from "../../Loading/LoadingWrapper";

export const CreateInput = ({ field, data, setData, setUpdateCountryId, updateCountryId, disabled }) => {
    const api = useAPI();
    const [opt, setOpt] = useState([]);
    useEffect(() => {
        if (field?.options?.fillFromApi) {
            const getOptions = async () => {
                let res = await api.get(`${field.options.fillFromApi}${field.options.fillFromApi?.includes("id_town") && updateCountryId ? `?id_country=${updateCountryId}` : ""}`);
                setOpt(res);
            };
            getOptions();
        }
    }, [updateCountryId]);

    // useEffect(() => {
    //     if (opt?.length <= 1) {
    //         setData((prev) => {
    //             return {
    //                 ...prev,
    //                 [field.field]: opt[0]?.value,
    //             };
    //         });
    //     }
    // }, []);

    switch (field.type) {
        case "select":
            return (
                <FormSelect
                    label={field.label}
                    data={data}
                    setData={setData}
                    field={field.field}
                    options={opt}
                    rows={field.rows}
                    required={field.required}
                    disabled={field.disabled}
                    columns={field.columns}
                    updateCountryId={updateCountryId}
                    setUpdateCountryId={setUpdateCountryId}
                />
            );
        case "search_select":
            return (
                <FormSelectSearch
                    label={field.label}
                    data={data}
                    setData={setData}
                    field={field.field}
                    options={opt}
                    rows={field.rows}
                    required={field.required}
                    disabledco={field.disabled}
                    columns={field.columns}
                />
            );
        default:
            return (
                <FormInput
                    label={field.label}
                    data={data}
                    setData={setData}
                    field={field.field}
                    placeholder={field.placeholder}
                    type={field.type}
                    rows={field.rows}
                    required={field.required}
                    disabled={field.disabled}
                    columns={field.columns}
                />
            );
    }
};

/**
 * A simple form created from array of fields
 *
 * @param {Array} data Data to display in form
 * @param {function} setData Function that sets data on form input change
 * @param {Array} fields Array of fields to display in form
 * @param {{}} submit Object that contains submit label and submit action
 * @param {string} className Additional class name to be applied on FormWrapper
 * @param {string} title Title of the form
 *
 * @return {JSX.Element}
 * @constructor
 */

const CreateForm = ({ data, setData, formFields = [], error, submit, className, title, updateCountryId, setUpdateCountryId, loading, formButtons }) => {
    return (
        <FormWrapper className={`row ${className}`} onSubmit={submit?.action}>
            {title && <h5>{title}:</h5>}
            {formFields.map((field) => (
                <CreateInput
                    key={field.label}
                    disabled={field?.field === "name" || "pib" || "maticni_broj"}
                    field={field}
                    data={data}
                    setData={setData}
                    updateCountryId={updateCountryId}
                    setUpdateCountryId={setUpdateCountryId}
                />
            ))}
            {error && <p>{error}</p>}
            <div className={``}>
                {formButtons &&
                    (submit && !loading ? (
                        <Buttons align="right">
                            <Button type="submit" label={submit.label} />
                        </Buttons>
                    ) : (
                        <Buttons align="right">
                            <Button type="submit" label={submit.label} loading={loading} />
                        </Buttons>
                    ))}
            </div>
        </FormWrapper>
    );
};

export default CreateForm;
