import { useQuery } from "react-query";

import MainCard from "components/layout/MainCard/MainCard";
import Button from "../../components/Buttons/Button/Button";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import __ from "../../config/translations/serbian";
import Sitemap from "../../routes/sitemap";
import useAPI from "../../hooks/useAPI";
import ProductItems from "../Catalogue/Products/ProductItems/ProductItems";
import StaticPage from "../../components/StaticPage/StaticPage";

import scss from "./EmptyPage.module.scss";

const EmptyPage = ({ message, children, className, slug = null, setOpenDialog }) => {
  const api = useAPI();

  // Load the products to promote
  const { data: products, isLoading } = useQuery(["EmptyPage.PromotedProducts"], () => api.list("/products/section/list/recommendation", { limit: 5, order_price: "desc" }));

  // Default message to show
  if (!message && !children) {
    message = __.EmptyPage.DefaultMessage;
  }
  return (
    <MainCard className={className}>
      <div className={`${scss["container"]}`}>
        {/* Just show the message */}
        {message && <h4 className={scss["heading"]}>{message}</h4>}

        {/* Render the supplied children */}
        {!message && children}

        {/* Back to home button */}
        <Button label={__.Messages.GoBackToHome} to={Sitemap.home} onClick={() => setOpenDialog(false)} />

        {/* Customer assistance */}
        {/*<h5 className={scss["sub-heading"]}>{__.EmptyPage.Assistance.Title}:</h5>*/}
        {/*<ul className={scss["help-list"]}>*/}
        {/*    <li>{__.EmptyPage.Assistance.Line1}</li>*/}
        {/*    <li>*/}
        {/*        {__.EmptyPage.Assistance.Line2.TakeALookAt}{" "}*/}
        {/*        <Link to={Sitemap.static.manual} className={scss["shopping-manual"]}>*/}
        {/*            {__.EmptyPage.Assistance.Line2.Manual}*/}
        {/*        </Link>*/}
        {/*        .*/}
        {/*    </li>*/}
        {/*</ul>*/}
        <StaticPage slug={slug ?? ""} />
        {/* Show the promoted products */}
        <h5 className={scss["sub-heading"]}>{__.EmptyPage.PromotedProducts.Title}:</h5>
        <LoadingWrapper isLoading={isLoading}>
          <ProductItems products={products?.items} />
        </LoadingWrapper>
      </div>
    </MainCard>
  );
};

export default EmptyPage;
